import { SHOW_SIDEBAR } from "../actionTypes";

export function activeSidebar(show) {
  return async function registerThunk(dispatch, getState) {
    dispatch({
      type: SHOW_SIDEBAR,
      payload: show,
    });
  };
}
