import React from "react";
import Layouts from "./layouts";
import { useProvideAuth } from "components/route/ProvideAuth";

import WithAuth from "components/hocs/withAuth";

function Main(props) {
  const auth = useProvideAuth();
  return (
    <WithAuth>
      <Layouts params={{ auth: auth.isAuthenticated }} />
    </WithAuth>
  );
}

export default Main;
