import React from "react";
import { Button } from "primereact/button";

function CancelBtn(props) {
  const confirm = () => props.cancel();

  return (
    <div className={props.className}>
      <Button label="Нет" className="с-button-danger " onClick={confirm} />
    </div>
  );
}

export default CancelBtn;
