import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setProp } from "domain/actions/equipment/bid";
import { Select } from "components/forms/fields";

function FnCashbox(props) {
  const { setProp, item } = props;
  const [updateKey, forceUpdate] = useState(1);
  const [value, setValue] = useState(null);

  useEffect(() => {
    const index = props.fnType.map((i) => i.id).indexOf(3);
    const itemFilter = props.fnType.filter((i) => +i.id === +item.fnTypeId);

    if (itemFilter.length) setValue(itemFilter[0]);
    else {
      const defaultValue = props.fnType[index < 0 ? 0 : index];
      setValue(defaultValue);
      setProp("fnTypeId", +defaultValue.id, item.id);
    }
  }, [props.fnType, item.fnTypeId, item.id, setValue, setProp]);

  const isActive = props.selected ? props.selected.status !== 11 : false;

  return (
    <Select
      key={updateKey}
      label="Срок действия ФН"
      options={props.fnType}
      className={props.className}
      value={value}
      onChange={(e) => {
        setProp("fnTypeId", +e.id, item.id);
        forceUpdate(updateKey + 1);
      }}
      disabled={isActive}
      // required
    />
  );
}

const mapStateToProps = (state) => ({
  fnType: state.requisites.fnType,
  selected: state.bidList.select,
});
export default connect(mapStateToProps, { setProp })(FnCashbox);
