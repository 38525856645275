import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setProp, setIsValidate } from "domain/actions/organizations/created";
import { getOrganizationsByInn } from "domain/actions/organizations";
import { TextField } from "components/forms/fields";
import { isINN } from "utils/rus.tax.codes.util";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import { showError } from "domain/actions/error.actions";

function Inn(props) {
  const [value, setValue] = useState("");
  const [updateKey, forceUpdate] = useState(1);
  const { lengthInn, showError } = props;

  useEffect(() => setValue(props.value), [props.value]);

  const getByInn = (inn) => {
    if (lengthInn === inn.length) {
      props.getOrganizationsByInn(inn);
    } else {
      showError(`Длинна ИНН должна соответствовать ${lengthInn} символов`);
    }
  };

  return (
    <div className={props.className + " p-d-flex p-jc-between p-ai-center"}>
      <TextField
        className="p-col-11 p-pr-1 p-pl-0 p-pt-0 p-pb-0"
        key={updateKey}
        label="ИНН"
        value={value}
        onChange={(e) => {
          let number = e.replace(/[\D]+/g, "");
          if (number.length <= 12) setValue(number);
        }}
        onBlur={(e) => {
          props.setProp("inn", e.target.value);
          forceUpdate(updateKey + 1);
        }}
        keyfilter="int"
        required={props.required}
        onValidate={props.setIsValidate}
        validate={{
          onValid: (val) => isINN(val),
          message: "ИНН не прошел проверку",
        }}
      />

      <FindInPageIcon className="p-mt-4" onClick={() => getByInn(value)} />
    </div>
  );
}

const mapStateToProps = (state) => ({
  value: state.orgCreate.item.inn,
  required: state.orgCreate.required,
});
export default connect(mapStateToProps, {
  setProp,
  setIsValidate,
  getOrganizationsByInn,
  showError,
})(Inn);
