export const SET_RECEIPTS = "SET_RECEIPTS";
export const SELECT_RECEIPTS = "SELECT_RECEIPTS";
export const REFRESH_RECEIPTS = "REFRESH_RECEIPTS";

export const SET_RECEIPTS_TOTAL_AMOUNT = "SET_RECEIPTS_TOTAL_AMOUNT";

export const SETSORTS_RECEIPTS = "SETSORTS_RECEIPTS";
export const SETRANGE_RECEIPTS = "SETRANGE_RECEIPTS";
export const SETFILTRES_RECEIPTS = "SETFILTRES_RECEIPTS";

export const SHOW_FILTERS_RECEIPTS = "SHOW_FILTERS_RECEIPTS";
export const SHOW_RECEIPTS = "SHOW_RECEIPTS";
export const SHOW_SEND_PAY_LINK = "SHOW_SEND_PAY_LINK";
export const SHOW_SEND_LINK_RECEIPT = "SHOW_SEND_LINK_RECEIPT";

export const SET_PAGINATION_RECEIPT = "SET_PAGINATION_RECEIPT";
export const SET_PAGINATION_RECEIPT_TOTAL = "SET_PAGINATION_RECEIPT_TOTAL";
