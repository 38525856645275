export const getDataOnEdit = (bid, getState) => {
  const cashboxes = bid.cashboxes.slice();

  const data = {
    id: bid.id,
    isLocal: bid.isLocal
      ? { id: 1, name: "У клиента" }
      : { id: 2, name: "У поставщика услуг" },
    organization: bid.cashboxes[0].organizationId,
    userId: bid.userId,
    cashboxes: formationList(cashboxes, bid.isLocal, bid.amount, getState),
    status: bid.status,
    billingBidInfo: bid.billingBidInfo,
  };

  return data;
};

function formationList(data, isLocal, amount, getState) {
  const requisites = getState().requisites;
  if (isLocal) return listClients(data, requisites);

  return listProviders(data, amount, requisites);
}

function listProviders(data, amount, requisites) {
  const result = [];
  data.forEach((i) => {
    let ofd = { name: "", inn: "", url: "" };
    if (+i.ofdId === 2) {
      try {
        const json = i.jsonData;
        ofd = json.ofd;
      } catch (e) {
        console.log(i);
        ofd = { name: "", inn: "", url: "" };
      }
    }
    const snoListIds = i.snoCashboxes.map((i) => +i.snoId);
    const agentsListIds = i.agentTypesId ? i.agentTypesId.map((i) => +i) : [];

    result.push({
      id: +i.id,
      tradePlace: i.tradePlace,
      needPrintOnPaper: i.needPrintOnPaper,
      fnTypeId: +i.fnTypeId,
      ffdId: +i.ffdId,
      defaultSnoId: +i.defaultSnoId,
      snoListId: snoListIds,
      agentTypesId: agentsListIds,
      ofdId: +i.ofdId,
      amount: amount,
      jsonData: i.jsonData,
      comment: i.comment,
      ofd: ofd,
    });
  });

  return result.slice(0, 1);
}

function listClients(data) {
  const result = [];
  data.forEach((i) => {
    result.push({
      id: +i.id,
      needPrintOnPaper: i.needPrintOnPaper,
      cashboxModelId: +i.cashboxModelId,
      factoryNumber: i.factoryNumber,
      fnTypeId: +i.fnTypeId,
      tradePlace: i.tradePlace,
      connect: {
        os: i.connectInterface.os,
        interface: i.connectInterface.interface,
        address: i.address,
      },
      location: i.location,
      comment: i.comment,
      addressActual: i.addressActual,
      workerId: i.workerId,
    });
  });

  return result;
}

// function getListFromRequisites(requisites, array, field){
//   if(array === null) return [];

//   let result = [];
//   array.forEach( i => {

//     let obj = requisites[field].filter( item => item.id === +i)[0];
//     result.push(obj);
//   })
//   return result;
// }

// function getObjFromRequisites(requisites, id, field){
//   return requisites[field].filter( item => +item.id === +id)[0];
// }
