import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { activeSidebar } from "domain/actions/sidebar";
import { Tooltip } from "primereact/tooltip";
import styles from "./style.module.css";

function Sidebar(props) {
  let { pathname } = useLocation();
  const templates = useSelector((state) => state.receiptTemplates.data);

  const [indActive, setIndActive] = useState(null);

  useEffect(() => {
    let indexActive = props.listMenu.map((i) => i.link).indexOf(pathname);
    setIndActive(indexActive);
  }, [pathname, props.listMenu]);

  const renderItems = props.listMenu.map((item, index) => {
    if (!templates.length && item.name === "Шаблоны чеков") return <></>;
    return (
      <div
        key={item.id}
        className={`${styles.item} ${
          indActive === index ? styles.itemActive : ""
        }`}
      >
        {!props.active && (
          <Tooltip target={`.links-${index}`} content={item.name} />
        )}

        <Link
          to={item.link}
          className={`links-${index}`}
          onClick={() => props.activeSidebar(false)}
        >
          {item.icon}
          <span>{item.name}</span>
        </Link>
      </div>
    );
  });

  return (
    <React.Fragment>
      <div
        className={`${styles.sidebar} ${props.active && styles.sidebarActive}`}
      >
        <div className={styles.itemMenu}>
          <div style={{ marginTop: "20px" }}>
            <span className={styles.sidebarLabel}>Меню</span>
          </div>
        </div>
        {renderItems}
      </div>
      <div
        className={`${styles.hide} ${props.active && styles.hideActive}`}
      ></div>
    </React.Fragment>
  );
}

export default connect(null, { activeSidebar })(Sidebar);
