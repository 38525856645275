import React from "react";
import { connect } from "react-redux";
import {
  selectedAcquiring,
  handleShowAcquiring,
} from "domain/actions/organizations/acquiring";

import { EditBtn } from "components/btns/iconBtn";

function EditButton(props) {
  const handleSelected = () => {
    props.selectedAcquiring(props.item);
    props.handleShowAcquiring(true);
  };
  return (
    <div className={props.className}>
      <EditBtn onClick={handleSelected} />
    </div>
  );
}

export default connect(null, { selectedAcquiring, handleShowAcquiring })(
  EditButton
);
