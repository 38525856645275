import React, { useEffect } from "react";
import { connect } from "react-redux";
import { showSendPayLink } from "domain/actions/receipt";
import Content from "./layouts/content";
import Window from "components/windows";

function PayLinkWindow(props) {
  const setShow = props.showSendPayLink;
  const show = props.show;

  useEffect(() => {
    return () => setShow(false);
  }, [setShow]);

  const header = <p className="p-m-0">Отправить ссылку оплаты</p>;

  return (
    <Window
      className={``}
      header={header}
      visible={show}
      onHide={() => setShow(false)}
      modal={false}
      draggable={false}
    >
      <Content />
    </Window>
  );
}

const mapStateToProps = (state) => ({
  show: state.receipts.showSendPayLink,
});
export default connect(mapStateToProps, { showSendPayLink })(PayLinkWindow);
