import React from "react";
import { connect } from "react-redux";
import { putOrg, changeValidate } from "domain/actions/organizations/created";
import { Button } from "primereact/button";

function ConfirmButton(props) {
  const confirm = () => {
    props.changeValidate(true);

    return props.putOrg(props.selected.id);
  };

  return (
    <div className={props.className}>
      <Button
        label="Применить"
        className="с-button-success"
        onClick={confirm}
        // disabled={props.isLocal === null}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  isValid: state.orgCreate.isValid,
  selected: state.orgCreate.select,
});
export default connect(mapStateToProps, { putOrg, changeValidate })(
  ConfirmButton
);
