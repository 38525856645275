import React from "react";
import { ConfirmDialog } from "primereact/confirmdialog";

export function WarningNewToken(props) {
  return (
    <ConfirmDialog
      className="p-col-12 p-md-6 p-xl-4 p-p-0 c-confirm-dialog"
      visible={props.visible}
      onHide={() => props.setVisible(false)}
      message="Вы уверены, что хотите сгенерировать новый токен подключения?"
      header="Предупреждение"
      icon="pi pi-exclamation-triangle"
      accept={props.accept}
      reject={() => props.setVisible(false)}
    />
  );
}

export default WarningNewToken;
