import React from "react";

import { ActiveSphere } from "components/based";
import { COLORS } from "./chart";

const styleArithmetic = {
  display: "inline-block",
  width: "10px",
  textAlign: "center",
};

function Info(props) {
  const data = props.data.length > 0 ? props.data : [];
  const topData = data.slice();

  const renderItems = topData.map((i, index) => {
    return (
      <div key={index} className="p-col-6 p-p-1 p-d-flex">
        <ActiveSphere
          isActive={true}
          position="center"
          color={`${COLORS[index]}`}
        />

        <div className="p-pl-1">
          <span className="dashboard-label">{i.name}</span>
          <br />
          <span className="">
            <span style={styleArithmetic}>+</span> {i.moneyIn} руб.
          </span>
          <br />
          <span className="">
            <span style={styleArithmetic}>-</span> {i.moneyOut} руб.
          </span>
        </div>
      </div>
    );
  });

  return <div className="p-col-12 p-d-flex p-flex-wrap">{renderItems}</div>;
}

export default Info;
