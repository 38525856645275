import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setProp } from "domain/actions/equipment/bid";
import { MultiSelectField } from "components/forms/fields";
import getItemsFromList from "controllers/getItemsFromList";

function AgentsCashbox(props) {
  const [value, setValue] = useState(props.item.agentTypesId);

  useEffect(() => {
    const item = getItemsFromList(props.agents, props.item.agentTypesId);
    setValue(item);
  }, [props.agents, props.item.agentTypesId, setValue]);

  const getAgentIds = (data) => {
    setValue(data);
    props.setProp(
      "agentTypesId",
      data.map((i) => +i.id),
      props.item.id
    );
  };

  const isActive = props.selected ? props.selected.status !== 11 : false;

  return (
    <MultiSelectField
      label="Агентская схема"
      options={props.agents}
      className={props.className}
      value={value}
      onChange={getAgentIds}
      disabled={isActive}
    />
  );
}

const mapStateToProps = (state) => ({
  agents: state.requisites.agentType,
  selected: state.bidList.select,
});
export default connect(mapStateToProps, { setProp })(AgentsCashbox);
