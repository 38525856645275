import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setProp } from "domain/actions/equipment/bid";
import { TextField } from "components/forms/fields";

function RegNumberCashbox(props) {
  const [value, setValue] = useState(props.item.factoryNumber);
  const [updateKey, forceUpdate] = useState(1);

  useEffect(() => {
    setValue(props.item.factoryNumber);
  }, [props.refresh]);

  const isActive = props.selected ? props.selected.status !== 11 : false;

  return (
    <TextField
      key={updateKey}
      label="Регистрационный номер"
      className={props.className}
      value={value}
      onChange={(e) => {
        if (e.length <= 16) setValue(e);
      }}
      onBlur={(e) => {
        props.setProp("regNumber", e.target.value, props.item.id);
        forceUpdate(updateKey + 1);
      }}
      disabled={isActive}
      // reqStar
    />
  );
}

const mapStateToProps = (state) => ({
  selected: state.bidList.select,
});
export default connect(mapStateToProps, { setProp })(RegNumberCashbox);
