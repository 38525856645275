import React from "react";
import Content from "./layouts";
import RightsPage from "components/rightsControl/RightsPage";

function Analytics(props) {
  return (
    <RightsPage right="ANALITICS">
      <Content history={props.history} />
    </RightsPage>
  );
}

export default Analytics;
