import React from "react";
import { useAuth } from "./hooks";
import { FormComponent, AuthButtonComponent } from "./components";
import styles from "./styles.module.css";

export function AuthComponent(props) {
  const { isAuthenticated, onAuth, onLogout } = useAuth();

  const handleAuth = async () => await onAuth();
  const handleLogout = async () => await onLogout();

  return (
    <React.Fragment>
      {isAuthenticated ? (
        <div
          className="p-col-12 p-d-flex p-flex-wrap p-jc-center"
          style={{
            backgroundColor: "white",
            boxShadow: "0px 0px 30px -6px",
            maxWidth: "425px",
            borderRadius: "8px",
            padding: "1.25rem",
          }}
        >
          <FormComponent />

          <div className={styles.button} onClick={handleLogout}>
            Выйти
          </div>
        </div>
      ) : (
        <AuthButtonComponent handleAuth={handleAuth} />
      )}
    </React.Fragment>
  );
}
