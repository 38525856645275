import React, { useState } from "react";

import { Comment, ConfirmBtn, CancelBtn } from "./components";

function Content(props) {
  const [comment, setComment] = useState("");

  const isFreeze = props.isFreeze;
  const text = isFreeze
    ? "Вы действительно хотите возобновить работу оборудования?"
    : "Вы действительно хотите приостановить работу оборудования?";

  return (
    <div className="p-col-12">
      <div className="p-col-12 p-pr-0 p-pl-0 p-d-flex p-text-center">
        <i
          className="pi pi-exclamation-triangle"
          style={{ fontSize: "32px", marginRight: ".5rem" }}
        ></i>
        <span className="p-confirm-dialog-message p-as-center">{text}</span>
      </div>

      <Comment
        className="p-col-12 p-pr-0 p-pl-0"
        isFreeze={isFreeze}
        value={comment}
        setValue={setComment}
      />

      <div className="p-col-12 p-p-0 p-d-flex p-jc-between p-flex-wrap">
        <CancelBtn
          className="p-col-12 p-md-5 p-p-1"
          cancel={() => props.onShow(false)}
        />
        <ConfirmBtn
          className="p-col-12 p-md-5 p-p-1"
          confirm={() => props.onConfirm(comment)}
        />
      </div>
    </div>
  );
}

export default Content;
