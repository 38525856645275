import React, { useState, useEffect } from "react";
import styles from "../index.module.css";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import { isUrl } from "utils/url.util";

import { InputText } from "primereact/inputtext";

function UrlField({
  className = "",
  name = "",
  label = "",
  value = "",
  onChange = () => console.log("onChange"),
  onBlur = () => console.log("onBlur"),
  isValid = false,
}) {
  const [valid, setValid] = useState(true);
  if (value === null) value = "";

  useEffect(() => {
    if (!isValid) return setValid(true);

    setValid(isUrl(value));
  }, [isValid, value]);

  return (
    <div className={className}>
      <label className={`${styles.label} p-pb-1`}>{label}</label>
      <InputText
        name={name}
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
          // inValid(e.target.value);
        }}
        onBlur={onBlur}
        className={`${styles.borderBottom}`}
      />
      {!valid && !!value.length && (
        <small className="p-error p-d-block">
          Введенный url-адрес не прошел проверку.
        </small>
      )}
      {/* {!valid && !value.length && (
        <small className="p-error p-d-block">Обязательно для заполнения!</small>
      )} */}
    </div>
  );
}

function UrlFieldControl({
  control,
  errors,
  className = "",
  name = "",
  label = "",
  defaultValue = "",
  required = false,
}) {
  let rules = {
    validate: (value) => {
      if (!value.length && !required) return true;

      if (!isUrl(value)) {
        return "Введенный url-адрес не прошел проверку.";
      }

      return isUrl(value);
    },
  };

  if (required) {
    rules.required = "Обязательно для заполнения!";
  }

  return (
    <div className={className}>
      <label className={`${styles.label} p-pb-1`}>{label}</label>
      <Controller
        name={name}
        control={control}
        render={(props) => (
          <InputText
            value={props.value}
            onChange={(e) => props.onChange(e)}
            className={`${styles.borderBottom}`}
          />
        )}
        rules={rules}
        defaultValue={defaultValue}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        as={<p className="errorText">message</p>}
      />
    </div>
  );
}

export { UrlField, UrlFieldControl };
