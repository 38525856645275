import React, { useState } from "react";
import Menu from "./components/menu";
import TabView from "./components/tabView";
import BidWindow from "./components/bidWindow";
import CashboxWindow from "./components/cashboxWindow";
import SettingsPanel from "./components/settingsPanel";
import { Heading } from "components/static";

export { Menu, TabView, BidWindow, CashboxWindow, SettingsPanel };

function Content(props) {
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <div className="p-pt-3 p-pr-3 p-pl-3 ">
      <div className="p-col-12 p-d-flex p-flex-wrap p-jc-between">
        <div className="p-col-12 p-md-4 p-lg-3 p-xl-2 p-p-0">
          <Heading className="p-col-12 p-pb-0" title="Оборудование" />
          <Menu className="p-p-0 p-mr-2 " />
        </div>

        <SettingsPanel
          className="p-col-12 p-md-8 p-lg-9 p-xl-10 p-p-0 p-p-md-2 p-d-flex p-flex-wrap"
          tabIndex={tabIndex}
        />
      </div>

      <TabView
        history={props.history}
        className="p-col-12 p-p-0"
        getTabIndex={setTabIndex}
      />

      <BidWindow className="c-window-page" />

      <CashboxWindow className="p-col-12 p-sm-10 p-md-10 p-lg-9 p-xl-8 p-p-0 " />
    </div>
  );
}

export default Content;
