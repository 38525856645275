import React, { useEffect, useState } from "react";
import { Switch, useLocation, useHistory } from "react-router-dom";
import RoutesSwitch from "../../../components/route/Routes.switch";
import Sidebar from "components/sidebar";
import listMenu from "routes/urlParameters/navigation.menu";

import { socket } from "domain/controllers/socket";

import { useProvideAuth } from "components/route/ProvideAuth";
import WithAuth from "components/hocs/withAuth";

import Header from "../components/Header.component";
import { connect } from "react-redux";
import {
  getAllRequisites,
  getRolesTranslateAMS,
} from "domain/actions/requisites.actions";
import { activeSidebar } from "domain/actions/sidebar";
import { getOrganizations } from "domain/actions/organizations";
import { getTemplates } from "domain/actions/receiptTemplates.actions";
import { getUsersRoles } from "domain/actions/users";

function Main(props) {
  const [isLoad, setLoad] = useState(false);
  const auth = useProvideAuth();
  const location = useLocation();
  const history = useHistory();
  const {
    getOrganizations,
    getAllRequisites,
    getUsersRoles,
    getRolesTranslateAMS,
    getTemplates,
  } = props;

  useEffect(() => {
    function onConnect() {
      console.log("onConnect");
    }

    function onDisconnect() {
      console.log("onDisconnect");
    }

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("connect_error", (error) => {
      console.log(error.message);
      try {
        if (error.status === 401) {
          window.location.href = `/api/auth/login`;
        }
      } catch (e) {
        console.log(e.message);
      }
    });
  }, []);

  useEffect(() => {
    if (!auth.isAuthenticated) return;
    if (!isLoad) return;

    getAllRequisites()
      .then(() => getRolesTranslateAMS())
      .then(() => getUsersRoles())
      .then(() => getOrganizations())
      .then(() => getTemplates());
  }, [
    getAllRequisites,
    getOrganizations,
    getUsersRoles,
    getRolesTranslateAMS,
    getTemplates,
    auth.isAuthenticated,
    isLoad,
  ]);

  useEffect(() => {
    if (location.pathname === "/main") history.push("/main/analytics");
  }, [location, history]);

  useEffect(() => setTimeout(() => setLoad(true), 400), [setLoad]);

  return (
    <WithAuth>
      <React.Fragment>
        <Header onSidebar={() => props.activeSidebar(!props.showSidebar)} />

        <div className="p-d-flex">
          <Sidebar active={props.showSidebar} listMenu={listMenu} />

          <div
            className="body-main"
            onClick={() =>
              props.showSidebar && props.activeSidebar(!props.showSidebar)
            }
          >
            <Switch>
              {props.routes.map((route, i) => (
                <RoutesSwitch key={i} {...route} />
              ))}
            </Switch>
          </div>
        </div>
      </React.Fragment>
    </WithAuth>
  );
}

const mapStateToProps = (state) => ({
  requisites: state.requisites,
  showSidebar: state.sidebar.show,
});
export default connect(mapStateToProps, {
  getAllRequisites,
  getRolesTranslateAMS,
  getOrganizations,
  activeSidebar,
  getUsersRoles,
  getTemplates,
})(Main);
