import React from "react";
import { Label } from "components/based";
import { EmptyData } from "components/static";
import AllListBtn from "./components/allListBtn";
import CardInvoice from "./components/cardInvoice";

import styles from "../../../../style.module.css";
import { connect } from "react-redux";

function LastInvoice(props) {
  return (
    <div className={props.className}>
      <Label value="Последний выставленный счет" style={{ color: "#4CAF50" }} />

      <div className={styles.blockInvoice}>
        {!!props.invoice ? (
          <CardInvoice invoice={props.invoice} />
        ) : (
          <EmptyData />
        )}
      </div>

      <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap">
        <div className="p-col-12 p-md-6 p-pr-0"></div>
        <AllListBtn className="p-col-12 p-md-6 p-pr-0" />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const invoice = !!state.billing.invoice.length
    ? state.billing.invoice.reverse()[0]
    : null;

  return {
    invoice: invoice,
  };
};
export default connect(mapStateToProps)(LastInvoice);
