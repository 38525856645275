import React from "react";
import styles from "../index.module.css";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Checkbox } from "primereact/checkbox";

function CheckboxField({
  className = "",
  name = "checkbox",
  label = "",
  checked = false,
  onChange = () => console.log("onChange"),
  disabled = false,
  required = false,
}) {
  return (
    <div className={className}>
      <label htmlFor={name} className={`${styles.labelCheckbox} `}>
        {label}
      </label>
      <Checkbox
        name={name}
        value={label}
        onChange={onChange}
        className={`p-mr-2`}
        inputId={name}
        checked={checked}
        disabled={disabled}
        required={required}
      />
    </div>
  );
}

function CheckboxFieldControl({
  control,
  errors,
  className = "",
  name = "checkbox",
  label = "",
  defaultValue = false,
  disabled = false,
  required = false,
}) {
  let rules = {
    required: "Обязательный параметр!",
  };

  return (
    <div className={className}>
      <label htmlFor={name} className={`${styles.label} p-pb-1`}>
        {label}
      </label>
      <Controller
        name={name}
        control={control}
        render={(props) => (
          <Checkbox
            value={props.value}
            onChange={(e) => props.onChange(e.target.checked)}
            className={`p-mr-2`}
            inputId={name}
            checked={props.value}
            disabled={disabled}
          />
        )}
        rules={required ? rules : false}
        defaultValue={defaultValue}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        as={<p className="errorText">message</p>}
      />
    </div>
  );
}

export { CheckboxField, CheckboxFieldControl };
