import React from "react";
import styles from "../style.module.css";

let gradient_1 = "rgba(52, 52, 52, 0.75) 14.43%";
let gradient_2 = "rgba(71, 71, 71, 0.5) 28.35%";
let gradient_3 = "rgba(149, 150, 149, 0.5) 52.79%";
let gradient_4 = "rgba(251, 251, 251, 0.25) 86.24%";

function ActiveSphere(props) {
  const position = props.position ? props.position : "inherit";
  const color = props.color ? props.color : "#fff";
  return (
    <div style={{ width: "20px", height: "20px", alignSelf: position }}>
      <div
        className={styles.sphere}
        style={{
          background: `linear-gradient(217.03deg, ${gradient_1}, ${gradient_2}, ${gradient_3}, ${gradient_4}), ${color}`,
        }}
      ></div>
    </div>
  );
}

export default ActiveSphere;
