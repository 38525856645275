import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Card from "./card";
import { SpinnerWrapper } from "components/wrappers";

import { getServices } from "domain/actions/route.action";

function Layouts(props) {
  const { getServices } = props;
  const [services, setServices] = useState([]);

  useEffect(() => {
    getServices().then((r) => setServices(r));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelect = (i) => {
    window.open(`${i.link}/api/auth/login`, "_blank");
  };

  const renderedListItems = services.map((i, index) => {
    return <Card key={index} service={i} onSelect={() => handleSelect(i)} />;
  });

  return (
    <SpinnerWrapper className="c-pagination-table">
      {services.length ? (
        <div className="p-d-flex p-flex-wrap">{renderedListItems}</div>
      ) : (
        <div className="p-col-12 p-text-center empty-list">
          Список сервисов пуст ...
        </div>
      )}
    </SpinnerWrapper>
  );
}

export default connect(null, { getServices })(Layouts);
