import React from "react";
import Layouts from "./layouts";
import Header from "./layouts/header";

function Services(props) {
  return (
    <>
      <Header className="p-d-flex p-flex-wrap p-col-12 p-p-0 p-jc-between" />
      
      <Layouts history={props.history} />
    </>
  );
}

export default Services;
