import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setProp } from "domain/actions/organizations/created";
import { Select } from "components/forms/fields";

function EntityId(props) {
  const [updateKey, forceUpdate] = useState(1);
  const [value, setValue] = useState(null);

  useEffect(() => {
    const item = props.listEntity.filter((i) => +i.id === +props.value);
    setValue(item.length ? item[0] : null);
    forceUpdate((prev) => prev + 1);
  }, [props.listEntity, props.value]);

  return (
    <Select
      key={updateKey}
      label="Тип юр. лица"
      options={props.listEntity && props.listEntity}
      className={props.className}
      value={value}
      onChange={(e) => {
        props.setProp("entityId", +e.id);
        forceUpdate(updateKey + 1);
      }}
    />
  );
}

const mapStateToProps = (state) => ({
  listEntity: state.requisites.entity,
  value: state.orgCreate.item.entityId,
});
export default connect(mapStateToProps, { setProp })(EntityId);
