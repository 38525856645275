// dispatch({ type: TOGGLE_PROGRESS, payload: true });
import {
  TOGGLE_PROGRESS,
  SET_RESPONSE_FEEDBACK,
  ERROR_FEEDBACK,
} from "domain/actionTypes";
import ClientController from "utils/Client";

const Client = new ClientController();

export function postOrderACall(data) {
  return async function thunk(dispatch, getState) {
    dispatch({ type: TOGGLE_PROGRESS, payload: true });

    if (data.phone) data.phone = data.phone.replace(/[()-]/gm, "");

    await Client.sleep(500);

    await Client.post("/feedback", data)
      .then((r) => dispatch({ type: SET_RESPONSE_FEEDBACK, payload: r }))
      .catch((e) => dispatch({ type: ERROR_FEEDBACK, payload: e.message }));

    dispatch({ type: TOGGLE_PROGRESS, payload: false });
  };
}
