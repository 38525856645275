import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { isAuth, isReAuth, setVisible } from "domain/actions/auth.actions";
import { showError } from "domain/actions/error.actions";
import { ProgressBar } from "primereact/progressbar";
import WarningAccessAccount from "./warning.account.window";

function LoadingAuth({ isAuth, ...props }) {
  const { isReAuth, showError, visible, setVisible } = props;
  const [loading, setLoad] = useState(true);

  useEffect(() => {
    isReAuth()
      .then(() => isAuth())
      .then(() => setTimeout(() => setLoad(false), 400))
      .catch((err) => {
        if (err?.response?.status === 401) setVisible(true);

        setTimeout(() => setLoad(false), 400);
      });
  }, [isAuth, isReAuth, showError, setVisible]);

  return (
    <React.Fragment>
      {loading ? (
        <div style={{ height: "100vh" }}>
          <ProgressBar
            mode="indeterminate"
            style={{ height: "6px" }}
          ></ProgressBar>
        </div>
      ) : (
        props.children
      )}

      <WarningAccessAccount visible={visible} setVisible={setVisible} />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  visible: state.auth.window.unauthorized,
});

export default connect(mapStateToProps, {
  isAuth,
  isReAuth,
  showError,
  setVisible,
})(LoadingAuth);
