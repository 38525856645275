import React, { useState } from "react";
import { connect } from "react-redux";
import { setSupplier } from "domain/actions/createReceipt/editItem";
import SupplierWindow from "../../../../../../../../../windows/supplier.window";
import { Button } from "primereact/button";

function Supplier(props) {
  const [display, setDisplay] = useState(false);

  const show = (e) => {
    e.preventDefault();
    setDisplay(true);
  };
  const close = (e) => setDisplay(false);

  return (
    <div className={props.className}>
      <SupplierWindow
        show={display}
        close={close}
        onConfirms={props.setSupplier}
        supplier={props.info}
      />
      <Button label="Поставщик" onClick={show} className="c-btns--gradient" />
    </div>
  );
}

const mapStateToProps = (state) => ({
  info: state.createReceiptEdit.supplier,
});
export default connect(mapStateToProps, { setSupplier })(Supplier);
