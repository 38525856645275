import React, { useEffect, useState } from "react";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import styles from "../style.module.css";

export const COLORS = ["#40af03d4", "#dc8919d4", "#ccc"];

function Chart(props) {
  const [sum, setSum] = useState(0);
  const [data, setData] = useState([]);
  const typeSort = props.isComing ? "moneyIn" : "moneyOut";

  useEffect(() => {
    try {
      const total = Object.keys(props.data).length
        ? +props.data[typeSort].total
        : 0;
      setSum(total.toFixed(2));
    } catch (error) {
      console.log(error);
      setSum(0);
    }
  }, [setSum, props.data, props.isComing]);

  useEffect(() => {
    if (Object.keys(props.data).length > 0) {
      try {
        const obj = props.data[typeSort];
        // delete obj.total;

        setData(() =>
          Object.entries(obj).map(([key, value]) => ({
            name: key,
            value: key === "total" ? +0 : +value,
          }))
        );
      } catch (error) {
        console.log(0);
      }
    }
  }, [props.data, setData, props.isComing]);

  const renderActiveShape = (props) => {
    const {
      cx,
      cy,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
    } = props;
    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill="#000">
          <tspan className={styles.topOrgTspan} x={cx} y={cy - 40}>
            Общая
          </tspan>
          <tspan className={styles.topOrgTspanAmount} x={cx} y={cy + 5}>
            {sum}
          </tspan>
          <tspan x={cx} y={cy + 25}>
            руб.
          </tspan>
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    );
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const name = payload[0].payload.name;
      const typeName =
        name === "electronically"
          ? "Электронными"
          : name === "cash"
          ? "Наличными"
          : "Прочее";

      return (
        <div className="custom-tooltip p-p-2">
          <p className="dashboard-label">{`Тип оплаты: ${typeName}`}</p>
          <p className="dashboard-label-other">{`Сумма: ${payload[0].payload.value} руб.`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer width="100%">
      <PieChart>
        <Pie
          data={data}
          activeIndex={0}
          activeShape={renderActiveShape}
          dataKey="value"
          cx="50%"
          cy="50%"
          innerRadius={70}
          outerRadius={100}
          labelLine={false}
        >
          {data.map((entry, index) => {
            return <Cell key={`cell-${index - 1}`} fill={COLORS[index - 1]} />;
          })}
        </Pie>

        <Tooltip content={<CustomTooltip />} />
      </PieChart>
    </ResponsiveContainer>
  );
}

export default Chart;
