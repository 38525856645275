import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { ListUser } from "../../components";
import Pagination from "components/static/pagination";
import { SpinnerWrapper } from "components/wrappers";
import { getUsers } from "domain/actions/users";

function Body(props) {
  const [page, setPage] = useState(1);
  const getUsers = props.getUsers;
  const pagination = props.pagination;

  useEffect(() => getUsers(page), [getUsers, page, props.refresh]);

  return (
    <div className={props.className}>
      <Pagination callback={setPage} pagination={pagination} />

      <SpinnerWrapper className="c-pagination-table">
        <ListUser className="p-col-12 p-p-1" />
      </SpinnerWrapper>

      <Pagination callback={setPage} pagination={pagination} />
    </div>
  );
}

const mapStateToProps = (state) => ({
  users: state.users.users,
  refresh: state.users.refresh,
  pagination: state.userPagination,
});
export default connect(mapStateToProps, { getUsers })(Body);
