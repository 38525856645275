import React from "react";
import { connect } from "react-redux";

import AddContent from "./add";
import EditContent from "./edit";

function Content(props) {
  return (
    <React.Fragment>
      {props.select ? (
        <EditContent className={props.className} />
      ) : (
        <AddContent className={props.className} />
      )}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  select: state.orgCreate.select,
});
export default connect(mapStateToProps)(Content);
