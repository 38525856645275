import React from "react";
import { Button } from "primereact/button";

export function AddAcquiringLocal(props) {
  const { onShow, className } = props;

  return (
    <div className={className}>
      <Button
        label="Добавить эквайринг"
        className="c-btns--gradient"
        onClick={() => onShow(true)}
      />
    </div>
  );
}

export default AddAcquiringLocal;
