import React from "react";
import { connect } from "react-redux";

export const WarnMessage = (props) => {
  let { warn } = props;

  if (warn <= 0) return <></>;

  return (
    <div
      className="p-col-12 p-text-center"
      style={{
        fontSize: "14px",
        backgroundColor: "#ff4747c2",
        color: "white",
        fontWeight: "700",
        position: "sticky",
        top: "70px",
        zIndex: "900"
      }}
    >
      У вас есть не прочитанное важное уведомление!
    </div>
  );
};

const mapStateToProps = (state) => ({
  warn: state.notifications.warn,
});

export default connect(mapStateToProps)(WarnMessage);
