import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getLazyOrganizations } from "domain/actions/organizations";
import { getDistribution, getCodeActive } from "domain/actions/equipment/bid";
import { getWorkers } from "domain/actions/organizations/index";
import Window from "components/windows";
import generateUUID from "utils/generateUUID";
import { Button } from "primereact/button";
import { TextField } from "components/forms/fields";

import InstallWorkerWindow from "./install.worker.window ";

const createWorker = (name) => {
  return {
    name: name,
    host: "localhost",
    workerUuid: generateUUID(),
    password: generateUUID().slice(-6),
    isActive: true,
  };
};

function AddWorkerWindow(props) {
  const {
    show,
    setShow,
    organization = null,
    getWorkers,
    getCodeActive,
  } = props;
  const [code, setCode] = useState("");
  const [name, setName] = useState(`Worker(${new Date()})`);
  const [install, setShowInstall] = useState(false);

  useEffect(() => {
    if (organization) setName(`Worker(${organization.name})`);
  }, [organization]);

  return (
    <>
      <Window
        className={`${props.className} ${
          props.activeSidebar
            ? "window-sidebar-active"
            : "window-sidebar-inActive"
        }`}
        style={{ maxWidth: "648px" }}
        header='Добавление нового "Рабочего места"'
        visible={show}
        onHide={() => setShow(false)}
        modal={false}
        draggable={false}
      >
        <div className="p-col-12 p-d-flex p-flex-wrap">
          <div className="p-col-12 p-d-flex p-p-0">
            <TextField
              label="Наименование"
              className="p-col-12"
              value={name}
              onChange={(e) => setName(e)}
            />
          </div>

          <div className="p-col-12 ">
            <Button
              className="p-col-12 p-text-center c-button"
              label="Создать"
              onClick={() => {
                getCodeActive(createWorker(name)).then((r) => {
                  getWorkers();
                  setCode(r.bindCode);
                  setShowInstall(true);
                });
              }}
            />
          </div>
        </div>
      </Window>

      <InstallWorkerWindow
        show={install}
        setShow={(show) => {
          setShowInstall(show);
          setShow(show);
        }}
        code={code}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  orgs: state.organizations.organizations,
  activeSidebar: state.sidebar.show,
});

export default connect(mapStateToProps, {
  getLazyOrganizations,
  getDistribution,
  getCodeActive,
  getWorkers,
})(AddWorkerWindow);
