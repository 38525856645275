import React, { useState } from "react";
import { connect } from "react-redux";
import { Column } from "components/dataView";

import { InputSwitch } from "primereact/inputswitch";

import { activeVirtualCashbox } from "domain/actions/organizations";

function VirtualCashbox(props) {
  const [update, setUpdate] = useState(1);

  const actionBodyTemplate = (value) => {
    const virtualCashbox = value.cashbox.filter((i) =>
      i.cashboxModel ? i.cashboxModel.model === "Эмулятор" : false
    );
    const active = virtualCashbox.length
      ? +virtualCashbox[0].status !== 2
        ? !virtualCashbox[0].status
        : false
      : false;

    return (
      <InputSwitch
        checked={active}
        onChange={(e) => {
          props
            .activeVirtualCashbox(value.id, e.value)
            .then(() => setUpdate(update + 1));
        }}
      />
    );
  };

  return (
    <Column
      update={update}
      body={actionBodyTemplate}
      title="Виртуальная касса"
      value={props.item}
      className={props.className}
    />
  );
}

export default connect(null, { activeVirtualCashbox })(VirtualCashbox);
