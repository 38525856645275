import React from "react";
import { LikeFilter } from "components/static";
import { connect } from "react-redux";
import { setLike } from "domain/actions/users/filters";

function Like(props) {
  return (
    <LikeFilter
      className={props.className}
      value={props.value}
      setValue={props.setLike}
    />
  );
}

const mapStateToProps = (state) => ({
  value: state.userFilter.like,
});
export default connect(mapStateToProps, { setLike })(Like);
