import React from "react";
import Layouts from "./layouts";
import Header from "./layouts/header";
import RightsPage from "components/rightsControl/RightsPage";

function Templates(props) {
  return (
    <>
      <Header className="p-d-flex p-flex-wrap p-col-12 p-p-0 p-jc-between" />
      <RightsPage right="CHECK">
        <Layouts history={props.history} />
      </RightsPage>
    </>
  );
}

export default Templates;
