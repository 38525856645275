import React from "react";

import Window from "components/windows";
import Content from "./layouts";

function TopUpWindow(props) {
  return (
    <Window
      className={props.className}
      header="Создание счета: Пополнение баланса "
      visible={props.show}
      onHide={props.close}
      style={{ minWidth: "340px" }}
    >
      <Content className="p-col-12 c-body-full" />
    </Window>
  );
}

export default TopUpWindow;
