import { toDate } from "./typeToString";

class GenerationStaticData {
  #firstNames = [
    "Денис",
    "Сергей",
    "Валерий",
    "Анастасия",
    "Родион",
    "Иван",
    "Анна",
    "Даниил",
    "Анатолий",
    "Леонид",
  ];
  #lastNames = [
    "Федосов",
    "Тимошенко",
    "Зацепин",
    "Ильина",
    "Шаталов",
    "Очкасов",
    "Власова",
    "Приходько",
    "Марухленко",
    "Якубович",
  ];
  #MiddleNames = [
    "Валерьевич",
    "Иванович",
    "Константинович",
    "Сергеевна",
    "Борисович",
    "Дмитриевич",
    "Генадьевна",
    "Валерьевич",
    "Анатольевич",
    "Григорьевич",
  ];
  #typeReceipt = ["sell", "returnSell"];
  #orgNames = [
    "Вти-сервис",
    "Вти-комбайн",
    "Sasus",
    "Pony",
    "Эдик & Э",
    "ИП Конь И.И.",
    "ИП Заяц Е.В.",
  ];
  #models = ["Atol", "Batol", "Atoromis", "Datoln'yans"];
  #factoryNumber = [
    "656263592595",
    "5623335335856",
    "45663653-655",
    "6554657531387966216235456",
  ];
  #comments = [
    "Временно - ",
    "Что-то с ней не то - ",
    "Исправлена и готова к труду и обороне - ",
    "",
    "Клиент что-то там прям на кассу - ",
    "",
    "",
  ];

  getUsers(count) {
    const result = [];
    for (let i = 0; i < count; i++) {
      const item = {
        id: i,
        firstName: this.#firstNames[i],
        lastName: this.#lastNames[i],
        middleName: this.#MiddleNames[i],
        comingSum: this.getRandomInt(10000),
        expenditureSum: this.getRandomInt(1000),
        comingCount: this.getRandomInt(100),
        expenditureCount: this.getRandomInt(50),
      };

      result.push(item);
    }

    return result;
  }
  getOrganizations(count) {
    const result = [];
    for (let i = 0; i < count; i++) {
      const item = {
        id: i,
        name: this.#orgNames[i % 4],
        moneyIn: this.getRandomInt(10000),
        receiptsIn: this.getRandomInt(1000),
        moneyOut: this.getRandomInt(1000),
        receiptsOut: this.getRandomInt(100),
      };

      result.push(item);
    }

    return result;
  }
  getCashbox(count) {
    const result = [];
    for (let i = 0; i < count; i++) {
      let active = this.getRandomInt(3);
      const item = {
        id: i,
        model: this.#models[i % 4],
        factoryNumber: this.#factoryNumber[i % 4],
        organizationName: this.#orgNames[this.getRandomInt(7)],
        comment:
          this.#comments[i % 7] + (active === 2 ? "заблокирована" : "работает"), // ""
        active: active,
      };

      result.push(item);
    }

    return result;
  }
  getReceipts(count) {
    const result = [];

    for (let i = 0; i < count; i++) {
      const cash = this.getRandomInt(10000);
      const electronically = this.getRandomInt(10000);

      const item = {
        id: 1,
        typeReceipt: this.#typeReceipt[Number(i % 5 === 0)],
        typePayments: [
          { id: 1, type: "cash", sum: cash },
          { id: 2, type: "electronically", sum: electronically },
        ],
        sum: cash + electronically,
        createdAt: toDate(
          this.randomDate(new Date(2021, 4, 12), new Date(), 0, 24)
        ),
      };

      result.push(item);
    }

    return result;
  }

  getReceiptOverRange(ranges) {
    const result = [];

    for (let i = 0; i < ranges.length; i++) {
      let schema = {
        moneyIn: this.getRandomInt(100000),
        moneyOut: this.getRandomInt(50000),
        receiptsIn: this.getRandomInt(1000),
        receiptsOut: this.getRandomInt(1000),
        typePayments: {
          cash: { count: this.getRandomInt(100), sum: this.getRandomInt(1000) },
          electronically: {
            count: this.getRandomInt(100),
            sum: this.getRandomInt(1000),
          },
          other: {
            count: this.getRandomInt(100),
            sum: this.getRandomInt(10000),
          },
        },
      };

      const item = Object.assign(ranges[i], schema);
      result.push(item);
    }

    return result;
  }

  getReceiptOverWeekend() {
    const ranges = [
      {
        fromTimeDate: "2021.06.04 00:00",
        toTimeDate: "2021.06.04 08:59",
      },
      {
        fromTimeDate: "2021.06.04 09:00",
        toTimeDate: "2021.06.04 11:59",
      },
      {
        fromTimeDate: "2021.06.04 12:00",
        toTimeDate: "2021.06.04 14:59",
      },
      {
        fromTimeDate: "2021.06.04 15:00",
        toTimeDate: "2021.06.04 17:59",
      },
      {
        fromTimeDate: "2021.06.04 18:00",
        toTimeDate: "2021.06.04 23:59",
      },
    ];
    const data = this.getReceiptOverRange(ranges);
    return data;
  }

  getReceiptOverMonth() {
    const ranges = [];

    for (let i = 1; i <= 30; i++) {
      const number = i < 10 ? `0${i}` : i;

      const result = {
        fromTimeDate: `2021.06.${number} 00:00`,
        toTimeDate: `2021.06.${number} 23:59`,
      };
      ranges.push(result);
    }

    return this.getReceiptOverRange(ranges);
  }

  getReceiptOverYear() {
    const ranges = [];

    for (let i = 1; i <= 12; i++) {
      const number = i < 10 ? `0${i}` : i;

      const result = {
        fromTimeDate: `2021.${number}.01 00:00`,
        toTimeDate: `2021.${number}.30 23:59`,
      };
      ranges.push(result);
    }

    return this.getReceiptOverRange(ranges);
  }

  getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }
  randomDate(start, end, startHour, endHour) {
    var date = new Date(+start + Math.random() * (end - start));
    var hour = (startHour + Math.random() * (endHour - startHour)) | 0;
    date.setHours(hour);
    return date;
  }
}

export default GenerationStaticData;
