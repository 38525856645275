import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import styles from "./styles.module.css";

function NavigationPage({
  className = "",
  list = [],
  attr = "NavigationPage",
}) {
  const inNumberWithZero = (number) => {
    if (String(number).length === 1) return `0${number}`;

    return number;
  };
  const rerender = list.map((i, index) => {
    let number = inNumberWithZero(index + 1);

    return (
      <li key={index}>
        <span>{number}</span>
        <Link to={`${i.link}`}>{i.name}</Link>
      </li>
    );
  });
  return (
    <ul attr={attr} className={`${className} ${styles.list}`}>
      {rerender}
    </ul>
  );
}

export default NavigationPage;
