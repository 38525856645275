import React from "react";
import Logotype from "components/logotype";
import Phone from "components/contacts/phone.contacts";
import EntityAuth from "./components/EntityAuth";
import { OrderACall } from "components/static";

function Header(params) {
  return (
    <div className={params.className}>
      <div className="p-d-flex p-jc-between">
        <div className="p-col-3 p-md-2 p-p-0 p-d-flex">
          <OrderACall icon className="p-as-center p-d-md-none p-mr-2" />
          <a href="/">
            <Logotype className="customer-logotype" />
          </a>
        </div>

        <div className="p-col-9 p-md-10 p-lg-8 p-xl-6 p-d-flex p-jc-end p-p-0">
          <Phone
            className="p-col-4 p-d-none p-d-md-flex p-ai-center"
            fontWeight="800"
            phone="8 (4712) 400-900"
          />

          <OrderACall className="p-col-4 p-p-1 p-d-none p-d-md-flex p-ai-center" />

          <EntityAuth
            auth={params.auth}
            className="p-col-6 p-md-4 p-d-flex p-jc-end p-p-1"
          />
        </div>
      </div>
    </div>
  );
}

export default Header;
