import React from "react";
import { Error } from "components/static";
import styles from "components/static/style.module.css";

function ErrorIcon(props) {
  return (
    <div className="c-position-relative">
      <img src={process.env.PUBLIC_URL + "/img/VD.png"} alt="VDCloud" />
      <div className={styles.ErrorIcon}>
        <Error />
      </div>
    </div>
  );
}

export default ErrorIcon;
