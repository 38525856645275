import React from "react";
import { CardComponent } from "components/static";
import { connect } from "react-redux";

function Organization(props) {
  const organization = props.item ? props.item.organization.name : false;

  const label = (
    <span className="block-label-title title-bold p-pl-2 p-as-center">
      Организация
    </span>
  );

  const content = (
    <span className="p-col-12 p-d-flex p-pl-3 p-pb-3 p-pt-1 p-text-uppercase">
      {!!organization ? `${organization}` : "-"}
    </span>
  );
  return (
    <CardComponent label={label} className={props.className}>
      {content}
    </CardComponent>
  );
}

const mapStateToProps = (state) => ({
  item: state.receipts.select,
});
export default connect(mapStateToProps)(Organization);
