import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  setPaySystem,
  setOnlinePay,
} from "domain/actions/createReceipt.actions";
import { updateType } from "domain/actions/createReceipt/items";
import { CheckboxField, Select } from "components/forms/fields";

function Acquiring(props) {
  const [system, setSystem] = useState(null);
  const [listAcquiring, setListAcquiring] = useState([]);

  useEffect(() => {
    if (props.type === "payment") props.setOnlinePay(true);
  }, [props.type]);

  useEffect(() => {
    if (!props.organization || !props.organization.organizationAcquiring)
      return;

    let acquiring = props.organization.organizationAcquiring.map((i) => {
      let acquiring = { ...i.acquiring };
      acquiring.acquiringId = i.id;
      acquiring.name =
        acquiring.name +
        " " +
        (i.comment ? i.comment : "( Комментарий отсутствует )");
      return acquiring;
    });

    setListAcquiring(acquiring);
  }, [props.organization]);

  useEffect(() => {
    const item = listAcquiring.filter(
      (i) => +i.acquiringId === props.organizationAcquiringId
    );

    setSystem(item.length ? item[0] : null);
  }, [props.organizationAcquiringId, listAcquiring]);

  useEffect(() => {
    props.setPaySystem(null);
    if (!listAcquiring.length) return;

    const org = props.organization;

    if (org && org.quickReceiptParams && org.quickReceiptParams?.active) {
      const params = org.quickReceiptParams;

      if (params.organizationAcquiringId) {
        props.setOnlinePay(true);
        const item = listAcquiring.filter(
          (i) => +i.acquiringId === params.organizationAcquiringId
        );
        setSystem(item.length ? item[0] : null);

        if (item.length) props.setPaySystem(item[0].acquiringId);
      }
    }
  }, [props.organization, listAcquiring]);

  return (
    <div className="p-col-12 p-md-6">
      <div className={`${props.className} p-p-3`}>
        <CheckboxField
          label="Сформировать url-ссылку для оплаты"
          className={props.classCheckbox}
          checked={props.active}
          onChange={(e) => {
            props.setOnlinePay(e.checked);
            props.updateType(e.checked ? "payment" : "receipt");
          }}
          disabled={props.type === "payment"}
        />
        {props.active && (
          <Select
            label="Тип платежной системы"
            options={listAcquiring}
            className="p-col-12 p-md-6 p-xl-7 "
            value={system}
            onChange={(e) => {
              props.setPaySystem(e.acquiringId);
            }}
          />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  active: state.createReceipt.isOnlinePayment,
  organizationAcquiringId: state.createReceipt.organizationAcquiringId,
  requisites: state.requisites,
  organization: state.createReceipt.org,
  type: state.createReceiptItems.type,
});
export default connect(mapStateToProps, {
  setPaySystem,
  setOnlinePay,
  updateType,
})(Acquiring);
