export default function validate(data) {
  let result = {
    check: false,
    error: "",
  };
  console.log(data);
  if (data.quantity <= 0 || data.quantity === null) {
    result.error = "Кол-во товара не введено";
    return result;
  }

  if (data.price <= 0 || data.price === null) {
    result.error = "Стоимость товара не введено";
    return result;
  }

  if (data.amount <= 0) {
    result.error = "Сумма товара не должна равняться или быть меньше нуля";
    return result;
  }

  if (data.nomenclatureCode && data.nomenclatureCode.type) {
    if (data.quantity > 1) {
      result.error =
        "Кол-во промаркированного товара не должно превышать 1 единицы";
      return result;
    }
  }

  if (data.markingCode && data.markingCode.type) {
    if (data.quantity > 1) {
      result.error =
        "Кол-во промаркированного товара не должно превышать 1 единицы";
      return result;
    }
    if (data.paymentMethod.eng === "advance") {
      result.error =
        "Промаркированный товар нельзя пробить со способом расчета 'Аванс'";
      return result;
    }
  }

  result.check = true;
  return result;
}
