import React from "react";
import { connect } from "react-redux";
import { TextFieldControl } from "components/forms/fields";

function LastNameField(props) {
  const { errors, disabled } = props;

  return (
    <TextFieldControl
      className={props.className}
      name="lastName"
      control={props.formHook.control}
      errors={props.formHook.errors}
      label="Фамилия:"
      required={true}
      responseError={errors?.lastName?.messages[0]}
      disabled={disabled}
    />
  );
}
const mapStateToProps = (state) => ({
  errors: state.users.responseError,
});
export default connect(mapStateToProps)(LastNameField);
