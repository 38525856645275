import React from "react";
import { connect } from "react-redux";
import { getEquipments } from "domain/actions/analytics";

import Line from "components/Line";
import { LargeList } from "components/static";

function Chart(props) {
  const { emptyText } = props;
  const renderItem = (item, index) => {
    const statusClass =
      item.status === 2
        ? "text-color-freeze"
        : item.status === 1
        ? "text-color-danger"
        : "text-color-success";

    return (
      <div className="p-col-12 p-p-0">
        <div
          className="p-col-12 p-p-0 p-d-flex analytics-table-cashboxes"
          data-pr-tooltip={`Организация: ${item.organizationName}`}
        >
          <div className={`p-col-3 p-p-1 ${statusClass}`}>{item.model}</div>
          <div className="p-col-4 p-pl-1 p-pt-1 p-pb-1 p-pr-3">
            {item.factoryNumber}
          </div>
          <div className="p-col-5 p-p-1 analytics-column-org ">
            {item.organization?.name}
          </div>
        </div>

        <Line />
      </div>
    );
  };

  return (
    <div
      className={props.className}
      style={{ height: "250px", overflow: "auto" }}
    >
      <LargeList
        renderRow={renderItem}
        getDataFromServer={props.getEquipments}
        limit={10}
        style={{ height: "232px" }}
        itemSize={40}
        emptyText={emptyText}
      />
    </div>
  );
}

export default connect(null, { getEquipments })(Chart);
