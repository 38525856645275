import React from "react";
import Title from "../titleBlock";

function IntegrationAPI(props) {
  return (
    <div className={props.className}>
      <Title name={props.params.name} id={props.params.id} />

      <div className="p-col-12 p-pl-5">
        <strong style={{ cursor: "pointer", color: "#000" }}>
          <a
            href="https://api24.vdpaybox.ru/swagger#/"
            target="_blank"
            rel="noreferrer"
          >
            Документация API-сервиса
          </a>
        </strong>
      </div>
    </div>
  );
}

export default IntegrationAPI;
