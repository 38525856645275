import React from "react";
import { RadioButton } from "primereact/radiobutton";

function YourContact(props) {
  return (
    <div className="p-d-flex">
      <div className="p-field-radiobutton p-col-6">
        <RadioButton
          inputId="city1"
          name="city"
          value="phone"
          onChange={(e) => props.setResponse(e.value)}
          checked={props.response === "phone"}
        />
        <label htmlFor="city1">По номеру телефона</label>
      </div>
      <div className="p-field-radiobutton p-col-6">
        <RadioButton
          inputId="city2"
          name="city"
          value="email"
          onChange={(e) => props.setResponse(e.value)}
          checked={props.response === "email"}
        />
        <label htmlFor="city2">По e-mail</label>
      </div>
    </div>
  );
}

export default YourContact;
