import {
  SET_BID_EQUIPMENTS,
  REFRESH_BID_EQUIPMENTS,
  SELECT_BID_EQUIPMENTS,
  EDIT_BID_EQUIPMENTS,
} from "../../../types/equipment";

const initialState = {
  equipments: [],
  select: null,
  refresh: false,
};

export default function bidListReduce(state = initialState, action) {
  switch (action.type) {
    case SET_BID_EQUIPMENTS:
      return {
        ...state,
        equipments: action.payload,
      };
    case REFRESH_BID_EQUIPMENTS:
      return {
        ...state,
        refresh: !state.refresh,
      };
    case SELECT_BID_EQUIPMENTS:
      return {
        ...state,
        select: action.payload,
      };
    case EDIT_BID_EQUIPMENTS:
      return {
        ...state,
        select: action.payload,
      };
    default:
      return state;
  }
}
