import React, { useEffect, useRef, useCallback } from "react";
import { connect } from "react-redux";

import { clearError } from "../domain/actions/error.actions";

import { Toast } from "primereact/toast";

function ToastError({ show, message, clearError }) {
  const toast = useRef(null);

  const showToast = useCallback(() => {
    toast.current.show({
      severity: "error",
      summary: "Сообщение об ошибке",
      // detail: message,
      content: (
        <div className="p-col-12 p-d-flex p-flex-column">
          <b>Сообщение об ошибке</b>
          {message}
        </div>
      ),
      life: 5000,
    });

    setTimeout(clearError, 5300);
  }, [toast, message, clearError]);

  useEffect(() => {
    if (show) return showToast();
  }, [show, showToast]);

  return <Toast ref={toast}></Toast>;
}

const mapStateToProps = (state) => ({
  show: state.errors.show,
  message: state.errors.message,
});

export default connect(mapStateToProps, { clearError })(ToastError);
