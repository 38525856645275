import {
  SET_RECEIPT_TEMPLATES,
  SET_SELECT_RECEIPT_TEMPLATES,
  SET_CLEAR_RECEIPT_TEMPLATES,
} from "../types/createReceipt/templates";

import { ApiController } from "domain/controllers";

const Api = new ApiController();

export function getTemplates(page) {
  return async function thunk(dispatch, getState) {
    return await Api.get(`/receiptTemplates?page=${page}`)
      .then((response) => {
        const data = response?.data?.data;

        const templates = data.length > 0 ? data.map((i) => i.receiptJson) : [];

        dispatch({ type: SET_RECEIPT_TEMPLATES, payload: templates });
      })
      .catch((e) => {
        console.log(e);
      });
  };
}

export function selectedTemplates(template) {
  return async function thunk(dispatch, getState) {
    dispatch({ type: SET_SELECT_RECEIPT_TEMPLATES, payload: template });
  };
}

export function clearTemplates() {
  return async function thunk(dispatch, getState) {
    dispatch({ type: SET_CLEAR_RECEIPT_TEMPLATES });
  };
}
