import React, { useEffect, useState } from "react";
import styles from "../index.module.css";

import { ColorPicker } from "primereact/colorpicker";

function ColorField({
  className = "",
  name = "",
  label = "",
  value = "",
  placeholder = "",
  disabled = false,
  tooltip = null,
  required = false,
  onValidate = () => null,
  onChange = () => console.log("onChange"),
  onBlur = () => console.log("onBlur"),
  validate = null,
  reqStar = false,
}) {
  const [valid, setValid] = useState(true);
  if (value === null) value = "";

  useEffect(() => {
    if (required && !value.length) return;
    onValidate(false);
  }, [required, value, onValidate]);

  useEffect(() => {
    if (validate && required) setValid(validate.onValid(value));
  }, [validate, value, required]);

  return (
    <div className={className}>
      <label className={`${styles.label} p-pb-1`}>
        {label} {reqStar && <span style={{ color: "red" }}>*</span>}
      </label>
      <ColorPicker
        name={name}
        value={value}
        onChange={(e) => onChange(e.value)}
        onBlur={onBlur}
        className={`${styles.borderBottom}`}
        placeholder={placeholder}
        disabled={disabled}
        tooltip={tooltip}
      />
      {required && !value.length && (
        <small className="p-error p-d-block">Обязательно для заполнения!</small>
      )}
      {required && !valid && !!value.length && validate && (
        <small className="p-error p-d-block">{validate.message}</small>
      )}
    </div>
  );
}

export { ColorField };
