import React from "react";
import { DeleteOutline } from "@material-ui/icons";

function Delete(props) {
  return (
    <button
      className="c-btn-outline c-btn-danger p-ml-md-0"
      onClick={props.onClick}
    >
      <DeleteOutline />
    </button>
  );
}

export default Delete;
