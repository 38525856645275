import React from "react";

import TypeItem from "./typeItem";
import CodeField from "./codeField";
import Confirm from "./confirmBtn";

function Layout(props) {
  const defVal = { id: 1, eng: "other", name: "Другой" };

  return (
    <form className={props.className}>
      <TypeItem formHook={props.formHook} defaultValue={defVal} />
      <CodeField formHook={props.formHook} />

      <Confirm handleSubmit={props.formHook.handleSubmit} close={props.close} />
    </form>
  );
}

export default Layout;
