import React from "react";
import { Shop, Representation, QuickReceiptParams } from "../index";

function AddingData(props) {
  return (
    <div className={props.className}>
      <div className="p-col-12 p-md-6 p-pl-md-0 p-pt-3">
        <Shop
          className="p-p-3 c-box-shadow p-d-flex p-flex-wrap"
          disabled={!props.isRights}
        />

        <QuickReceiptParams
          className="p-p-3 c-box-shadow p-d-flex p-flex-wrap"
          disabled={!props.isRights}
        />
      </div>

      <div className="p-col-12 p-md-6 p-pr-md-0">
        <div className="p-col-12 ">
          <Representation
            className="p-p-3 c-box-shadow p-d-flex p-flex-wrap"
            disabled={!props.isRights}
          />
        </div>
      </div>
    </div>
  );
}

export default AddingData;
