import React from "react";
import { connect } from "react-redux";
import { Heading } from "components/static";

import mapDispatchToProps from "domain/actions/createReceipt.actions";

import { ToggleButton } from "primereact/togglebutton";
import CreateReceipt from "./create.receipt";
import CreatePayment from "../../payments/create";

import { SpinnerWrapper } from "components/wrappers";

function Layouts({ type, methods, isLoading }) {
  // console.log(type)
  return (
    <React.Fragment>
      <div className="p-pt-3 p-pr-3 p-pl-3 p-d-flex p-flex-wrap">
        <Heading className="p-col-12 p-md-3" title="Создание чека" />
      </div>

      <SpinnerWrapper
        className="c-pagination-table"
        position="center"
        progress={isLoading}
      >
        <CreateReceipt />
      </SpinnerWrapper>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.createReceipt.isLoading,
    type: state.createReceiptItems.type,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Layouts);
