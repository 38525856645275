import React from "react";
import { Link } from "react-router-dom";

import Line from "components/Line";
import Window from "components/windows";

function NotFoundINN(props) {
  return (
    <Window
      header="Предупреждение!"
      visible={props.show}
      modal
      style={{ maxWidth: "450px" }}
      onHide={() => props.onHide()}
      draggable={false}
    >
      <div className="confirmation-content">
        <i
          className="pi pi-exclamation-triangle p-mr-3"
          style={{ fontSize: "2rem" }}
        />
        <span>У авторизованного пользователя не заполнен ИНН.</span>
        <br />
        <div className="p-pt-2 p-pb-2">
          <Line />
        </div>
        <span>
          Инн оператора не будет передано. Введите Инн оператора на странице{" "}
          <Link to="/main/users">"ПОЛЬЗОВАТЕЛИ"</Link>, если это необходимо.
        </span>
      </div>
    </Window>
  );
}

export default NotFoundINN;
