import React from "react";
import { connect } from "react-redux";
import PreviewOrg from "../components/previewOrg";
import {
  EditOrg,
  Connections,
  AddingData,
  AcquiringData,
  AcquiringLocalData,
  EditButton,
  // DeleteButton,
} from "../components";
import CancelButton from "components/btns/cancelBtn";
import { TabView } from "components/based";
import { handleShowWindow } from "domain/actions/organizations.actions";

function EditContent(props) {
  const { handleShowWindow, rights } = props;
  const isRights = rights.includes("ORGANIZATIONS_UPDATE");

  return (
    <div className={props.className}>
      <PreviewOrg className="p-col-12" />

      <React.Fragment>
        <TabView>
          <EditOrg
            label="Данные организации"
            className="p-col-12 p-p-0"
            isRights={isRights}
          />
          <AcquiringData
            label="Эквайринг"
            className="p-col-12 p-p-0 p-d-flex p-flex-wrap"
            isRights={isRights}
          />
          <AcquiringLocalData
            label="Эквайринг(Локальный)"
            className="p-col-12 p-p-0 p-d-flex p-flex-wrap"
            isRights={isRights}
          />
          <AddingData
            label="Дополнительные параметры"
            className="p-col-12 p-p-0 p-d-flex p-flex-wrap"
            isRights={isRights}
          />

          <Connections
            label="Подключения"
            className="p-col-12 p-p-0 p-d-flex p-flex-wrap"
            isRights={isRights}
          />
        </TabView>

        <div className="p-col-12 p-pl-0 p-pr-0 p-d-flex p-jc-between p-flex-wrap c-pB-mobile-100">
          {/* <DeleteButton className="p-col-12 p-md-4 p-xl-3" /> */}
          <CancelButton
            className="p-col-12 p-md-4 p-xl-3"
            onShow={() => handleShowWindow(false)}
          />
          {isRights && <EditButton className="p-col-12 p-md-4 p-xl-3" />}
        </div>
      </React.Fragment>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLegalEntity: state.orgCreate.item.legalEntity,
  rights: state.users.rights,
});

export default connect(mapStateToProps, { handleShowWindow })(EditContent);
