import React, { useState } from "react";
import { connect } from "react-redux";

import spaceInNumbers from "controllers/spaceInNumbers";

import { Label } from "components/based";
import TopUp from "./components/topUpBtn";
import HistoryBtn from "./components/historyBtn";

import { TopUpWindow } from "routes/pages/settings/windows";

import styles from "../../../../style.module.css";

function Balance(props) {
  const [show, setShow] = useState(false);
  const sum = spaceInNumbers(props.balance);

  return (
    <React.Fragment>
      <div className={props.className}>
        <Label value="Мой баланс" style={{ color: "#4CAF50" }} />

        <div className={styles.balance}>{sum} &#8381;</div>

        <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap">
          <TopUp
            className="p-col-12 p-md-6 p-pl-0"
            callback={() => setShow(true)}
          />
          <HistoryBtn className="p-col-12 p-md-6 p-pr-0" />
        </div>
      </div>

      <TopUpWindow
        show={show}
        close={setShow}
        className="p-col-12 p-md-8 p-xl-4 p-p-0"
      />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  balance: state.billing.balance,
});
export default connect(mapStateToProps)(Balance);
