import React from "react";
import { Checkbox } from "primereact/checkbox";

import { Column } from "components/dataView";

export function CashboxCard(props) {
  const { item, isSelect, setSelect, update, setUpdate } = props;

  const actionBodyTemplate = (value) => {
    return (
      <Checkbox
        checked={isSelect}
        onChange={(e) => {
          setSelect(value);
          setUpdate(update + 1);
        }}
      />
    );
  };

  return (
    <div
      className="p-d-flex p-flex-wrap p-pt-4 p-pb-4 c-card-bb"
      update={update}
    >
      <Column
        body={actionBodyTemplate}
        title=""
        value={item}
        className="p-col-3 p-md-2 p-lg-2 p-p-1 p-ai-sm-center"
      />

      <Column
        className="p-col-7 p-md-3 p-order-0 p-p-1 p-as-center"
        classTitle="p-d-none"
        classBody="c-color-green p-text-left"
        title="Название"
        value={
          item.cashboxModel ? item.cashboxModel.model : "Нет названия кассы"
        }
      />

      <Column
        className="p-col-6 p-md-3 p-p-1 p-as-center p-order-3 p-order-md-2"
        classTitle="p-d-block p-d-md-none"
        title="Заводской номер"
        value={item.factoryNumber}
      />

      <Column
        className="p-col-6 p-md-4 p-p-1 p-as-center p-order-5 p-order-md-4"
        classTitle="p-d-block p-d-md-none"
        title="Организация"
        value={item.organization ? item.organization.name : ""}
      />
    </div>
  );
}

export default CashboxCard;
