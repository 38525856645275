import React from "react";
import { Button } from "primereact/button";

function AllListBtn(props) {
  return (
    <div className={props.className}>
      <Button
        label="Показать весь список"
        onClick={() => console.log("Показать весь список")}
        className="c-btns--gradient"
      />
    </div>
  );
}

export default AllListBtn;
