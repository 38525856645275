import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setRangeReceipts } from "domain/actions/receipt";
import Ranges from "components/static/ranges";
import RangeComponent from "components/static/ranges/components/Ranges.component";

function RangesPanel(props) {
  const [sum, setSum] = useState([null, null]);

  useEffect(() => {
    props.setRangeReceipts("sumDoc", sum);
  }, [sum, props]);

  return (
    <Ranges className={props.className}>
      <RangeComponent
        label="Сумма"
        value={sum}
        onChange={setSum}
        className="p-d-flex p-col-12 p-p-0 p-jc-between"
      />
    </Ranges>
  );
}

export default connect(null, { setRangeReceipts })(RangesPanel);
