import React from "react";

const style = {
  width: "100%",
  minWidth: "75px",
};

function QrCode() {
  return (
    <div style={style}>
      <img
        src={process.env.PUBLIC_URL + "/img/qrCodeVTI.png"}
        style={style}
        alt="ВТИ-Сервис"
      />
    </div>
  );
}

export default QrCode;
