import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import Body from "./body";
import Pagination from "components/static/pagination";
import { getOrganizations } from "domain/actions/organizations";
import { SpinnerWrapper } from "components/wrappers";

function DataView(props) {
  const [page, setPage] = useState(1);
  const [load, setLoad] = useState(false);
  const getOrganizations = props.getOrganizations;

  useEffect(() => load && getOrganizations(page), [
    getOrganizations,
    page,
    load,
    props.refresh,
  ]);

  useEffect(() => setLoad(true), [setLoad]);

  return (
    <div className={props.className}>
      <Pagination callback={setPage} pagination={props.pagination} />

      <SpinnerWrapper className="c-pagination-table">
        <Body className="p-col-12 p-p-1" />
      </SpinnerWrapper>

      <Pagination callback={setPage} pagination={props.pagination} />
    </div>
  );
}

const mapStateToProps = (state) => ({
  refresh: state.organizations.refresh,
  pagination: state.orgPagination,
});

export default connect(mapStateToProps, { getOrganizations })(DataView);
