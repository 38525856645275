import React, { useState } from "react";
import { connect } from "react-redux";
import { PrintOnPaper, TypeOperation, ConfirmButton } from "../../components";

function Layouts(props) {
  const [type, setType] = useState(1);
  const [print, setPrint] = useState(false);

  return (
    <div className={props.className}>
      <TypeOperation
        isReport={true}
        value={type}
        setValue={setType}
        className="p-pt-2 p-pb-2"
      />
      <PrintOnPaper
        value={print}
        setValue={setPrint}
        className="p-pt-3 p-pb-2 p-d-flex"
      />
      <ConfirmButton
        className="p-pt-3"
        data={{
          cashboxId: props.item.id,
          needPrintOnPaper: print,
          closeOpenX: type,
        }}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  item: state.equipments.select,
});
export default connect(mapStateToProps)(Layouts);
