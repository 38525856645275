import React from "react";
import { connect } from "react-redux";
import EditData from "./dataForm";

function EditOrg(props) {
  const { isRights = true } = props;

  const isLegalEntity = props.select && props.select.isLegalEntity;
  return (
    <div className={props.className}>
      <EditData
        className="p-col-12 p-p-0 p-d-flex p-flex-wrap"
        isLegalEntity={isLegalEntity}
        isRights={isRights}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  select: state.orgCreate.select,
});
export default connect(mapStateToProps)(EditOrg);
