import React, { useState, useEffect } from "react";
import { MultiSelect } from "primereact/multiselect";

import { Skeleton } from "primereact/skeleton";
import styles from "../../style.module.css";

function LazyMultiFilterComponent(props) {
  const [itemsCash, setItemsCash] = useState([]);
  const [pageCash, setPageCash] = useState({});

  useEffect(() => {
    if (!props.options) return;

    setItemsCash((prev) => {
      let arr_1 = [...new Set([...prev, ...props.options])];

      return arr_1;
    });

    // setPageCash({});
  }, [props.options]);

  const onLazyLoad = async (event) => {
    const { last } = event;

    const page = Math.ceil((last + 1) / props.limit);

    if (pageCash[page]) return;
    setPageCash((prev) => Object.assign(prev, (prev[page] = page)));

    await props
      .getData(page, props.limit)
      .then((response) => response.data.result)
      .then((response) => {
        response.data.forEach((i) =>
          setItemsCash((prev) => {
            if (prev.map((i) => i.id).indexOf(i.id) >= 0) return prev;
            prev.forEach((i, index) => {
              if (Object.keys(i).length === 0) prev.slice(index, 1);
            });
            return [...prev, i];
          })
        );
      })
      .catch((error) => console.log(error));
  };

  const onFilterLike = (like) => {
    props.setFilter(like);
    setPageCash({});

    props
      .getData(1, 50)
      .then((response) => response.data.result)
      .then((response) => {
        // setItemsCash(response.data);
        response.data.forEach((i) =>
          setItemsCash((prev) => {
            if (prev.map((i) => i.id).indexOf(i.id) >= 0) return prev;
            prev.forEach((i, index) => {
              if (Object.keys(i).length === 0) prev.slice(index, 1);
            });
            return [...prev, i];
          })
        );
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className={props.className}>
      <span className={styles.multiComponent__label}>{props.label}</span>

      <div className={styles.multiComponent}>
        <MultiSelect
          value={props.value}
          options={itemsCash ? itemsCash : []}
          onChange={(e) => props.setValue(e.value)}
          optionLabel={props.optionLabel ? props.optionLabel : "name"}
          placeholder="Не выбрано..."
          filter
          onFilter={(e) => {
            if (props?.setFilter) {
              onFilterLike(e.filter);
            }
          }}
          className={styles.multiComponent__selects}
          selectedItemsLabel="Выбрано {0} элементов"
          showClear={true}
          showSelectAll={false}
          resetFilterOnHide={true}
          emptyFilterMessage="Совпадений не найдено"
          virtualScrollerOptions={{
            lazy: true,
            onLazyLoad: onLazyLoad,
            itemSize: 31,
            showLoader: false,
            // loading: lazyLoading,
            delay: 0,
            loadingTemplate: (options) => {
              return (
                <div
                  className="p-d-flex p-ai-center p-p-2"
                  style={{ height: "31px" }}
                >
                  <Skeleton
                    width={options.even ? "60%" : "50%"}
                    height="1rem"
                  />
                </div>
              );
            },
          }}
        />
      </div>
    </div>
  );
}

export default LazyMultiFilterComponent;
