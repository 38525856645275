import React from "react";

import LogotypeVTI from "components/VTI/logotype";
import QrCodeVTI from "components/VTI/qrCode";
import ContactsVTI from "components/VTI/contacts";
import Navigation from "components/NavigationPage/NavigationPage";
import { OrderACall } from "components/static";

import { Signature } from "components/static";

import Line from "components/Line";

import { NavigationList } from "../../index";

function Footer() {
  return (
    <div className="p-pt-3 p-pb-3 p-d-flex p-flex-column">
      <div className="p-col-12 p-pr-0 p-pl-0">
        <Line />
      </div>

      <div className="p-col-12 p-pl-3 p-pr-0 p-d-flex p-flex-wrap">
        <div className="p-col-12 p-md-2">
          <div className="p-col-7 p-sm-4 p-md-12 p-p-0">
            <LogotypeVTI />
          </div>
          <div className=" p-d-none p-col-7 p-md-4 p-p-0 p-d-md-block ">
            <QrCodeVTI />
          </div>
        </div>

        <div className="p-col-4 p-md-order-2 p-md-2">
          <div className=" p-col-7 p-sm-4 p-md-2 p-p-0 p-d-md-none">
            <QrCodeVTI />
          </div>
        </div>

        <div className="p-col-8 p-md-order-1 p-md-7 ">
          <ContactsVTI className="p-d-md-flex" />
          <OrderACall className="p-col-12 p-ai-start" />
        </div>

        <div className="p-col-12 p-md-order-0 p-md-3">
          <Navigation list={NavigationList} />
        </div>
      </div>

      <Signature className="p-text-left p-p-2" />
    </div>
  );
}

export default Footer;
