import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";

import Agent from "../components/Agent.item";
import AgentPayment from "../components/AgentPayment";
import AgentOperator from "../components/AgentOperator";
import AgentTranslate from "../components/AgnetTranslate";

// import { setAgents } from "../../../../domain/actions/createReceipt.actions";
import { showError } from "../../../../domain/actions/error.actions";

import Window from "components/windows";
import { Button } from "primereact/button";

import styles from "../styles.module.css";
import { isArray } from "lodash";

function AgentsWindow({
  agentInfo,
  // setAgents,
  show,
  close,
  requisite,
  showError,
  onConfirms,
}) {
  const { register, control, errors, handleSubmit } = useForm();

  const [selectedAgents, setSelectedAgents] = useState([]);
  const [agentPay, setAgentPay] = useState(null);
  const [agentOper, setAgentOper] = useState(null);
  const [agentTran, setAgentTran] = useState(null);

  useEffect(() => {
    if (agentInfo) {
      setSelectedAgents(agentInfo.agents);
      setAgentPay(agentInfo.payingAgent);
      setAgentOper(agentInfo.receivePaymentsOperator);
      setAgentTran(agentInfo.moneyTransferOperator);
    }
  }, [agentInfo]);

  const cleareValueNull = (data) => {
    let info = data;
    for (let key in info) {
      if (key !== "agents") {
        for (let key2 in info[key]) {
          if (info[key][key2] === null || info[key][key2] === "")
            delete info[key][key2];
        }
        if (!Object.keys(info[key]).length) info[key] = null;
      }
    }

    for (let key in info) {
      if (info[key] === null) delete info[key];
    }

    return info;
  };

  const validAgents = (info) => {
    let data = Object.assign({}, info);
    delete data.agents;

    if (Object.keys(data).length) {
      if (!(info.agents && info.agents.length)) {
        showError("Список агентов пуст");
        return false;
      }
    }

    return true;
  };

  const createArrayPhones = (info) => {
    for (let key in info) {
      if (!!info[key].phones && !isArray(info[key].phones)) {
        info[key].phones = info[key].phones.split(/[\s,]+/);
      }
    }

    return info;
  };

  const onConfirm = (data) => {
    let agentInfo = cleareValueNull(data.agentInfo);

    if (!validAgents(agentInfo)) return;

    onConfirms(createArrayPhones(agentInfo));

    close();
  };

  const footer = (
    <div className="p-col-12 p-d-flex p-jc-end p-mt-3">
      <div className={`p-col-12 p-md-6 ${styles.buttons}`}>
        <Button label="Добавить" className="p-button-success" type="confirm" />
      </div>
    </div>
  );
  return (
    <Window
      header="Агент"
      visible={show}
      className={`${styles.dialog} p-col-12 p-md-8 p-xl-7 p-p-0 dialog-header`}
      onHide={close}
      draggable={false}
    >
      <form
        onSubmit={handleSubmit(onConfirm)}
        className="p-d-flex p-flex-wrap p-jc-between"
      >
        <div className="p-col-12 p-md-6 p-p-0">
          <div className="p-p-1">
            <span className="block-label-title p-pl-2">Признак агента</span>
            <Agent
              register={register}
              requisite={requisite}
              errors={errors}
              control={control}
              title={false}
              defaultValue={selectedAgents}
              className="p-col-12 off-shadow window-multiselect p-p-0"
            />
          </div>

          <div className="p-p-1">
            <span className="block-label-title p-pl-2">Платёжный агент</span>
            <AgentPayment
              register={register}
              errors={errors}
              control={control}
              defaultValue={agentPay}
              className="p-col-12 off-shadow window-multiselect p-p-0"
            />
          </div>
        </div>
        <div className="p-col-12 p-md-6 p-p-0">
          <div className="p-p-1">
            <span className="block-label-title p-pl-2">
              Оператор по приёму платежей
            </span>
            <AgentOperator
              register={register}
              errors={errors}
              control={control}
              defaultValue={agentOper}
              className="p-col-12 off-shadow window-multiselect p-p-0"
            />
          </div>
          <div className="p-p-1">
            <span className="block-label-title p-pl-2">Оператор перевода</span>
            <AgentTranslate
              register={register}
              errors={errors}
              control={control}
              defaultValue={agentTran}
              className="p-col-12 off-shadow window-multiselect p-p-0"
            />
          </div>
        </div>

        {footer}
      </form>
    </Window>
  );
}

const mapStateToProps = (state) => ({
  requisite: state.requisites,
});

export default connect(mapStateToProps, { showError })(AgentsWindow);
