import React from "react";
import { connect } from "react-redux";
import { Button } from "primereact/button";
import { setCodes } from "domain/actions/createReceipt/editItem";
import styles from "../../../../../../../styles.module.css";

function ConfirmButton(props) {
  const onConfirm = (data) => {
    if (data.markingCode.mark === "") {
      props.setCodes({ markingCode: null });
    } else {
      props.setCodes(data);
    }

    props.close();
  };

  return (
    <div className="p-col-12 p-d-flex p-jc-end p-mt-3">
      <div className={`p-col-12 p-md-6 ${styles.buttons}`}>
        <Button
          label="Добавить"
          className="p-button-success"
          type="confirm"
          onClick={props.handleSubmit(onConfirm)}
        />
      </div>
    </div>
  );
}

export default connect(null, { setCodes })(ConfirmButton);
