import React from "react";
import { Name, EntityId, Ogrn, Inn, Kpp } from "../../../../components/fields";

function BasicLegalEntity(props) {
  return (
    <div className={props.className}>
      <span className="block-label-title p-pb-4 title-silver p-col-12">
        Основные сведения юридического лица
      </span>

      <Inn className="p-col-12" lengthInn={10} />
      <Name className="p-col-12" />
      <EntityId className="p-col-12 p-md-6" />
      <Ogrn className="p-col-12 p-md-6" />
      <Kpp className="p-col-12 p-md-6" />
    </div>
  );
}

export default BasicLegalEntity;
