import React from "react";
import {
  ModelCashbox,
  FactoryNumberCashbox,
  RegNumberCashbox,
  PrintOnPaper,
  FNCashbox,
} from "routes/pages/equipments/components";

function DCashbox(props) {
  const item = props.item;

  return (
    <div className={props.className}>
      <span className="block-label-title p-pl-2">Данные по кассе</span>
      <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap">
        <ModelCashbox
          item={item}
          className="p-col-12 p-md-6 p-lg-4  p-d-block p-d-md-none"
          filter={false}
          refresh={props.refresh}
        />
        <ModelCashbox
          item={item}
          className="p-col-12 p-md-6 p-lg-4  p-d-none p-d-md-block"
          filter={true}
          refresh={props.refresh}
        />

        <FactoryNumberCashbox
          item={item}
          className="p-col-12 p-md-6 p-lg-4 "
          refresh={props.refresh}
        />

        <RegNumberCashbox
          item={item}
          className="p-col-12 p-md-6 p-lg-4 "
          refresh={props.refresh}
        />

        <FNCashbox
          className="p-col-12 p-md-6 p-lg-4  p-d-none"
          item={props.item}
        />
        <PrintOnPaper
          item={item}
          className="p-col-12 p-md-6 p-lg-4  p-as-center p-d-flex"
        />
      </div>
    </div>
  );
}

export default DCashbox;
