import React from "react";
import {
  Ogrn,
  Ogrnip,
  Kpp,
  Phone,
  Email,
  AddressActual,
  AddressLegal,
} from "../../../../components/fields";

function EditData(props) {
  const { disabled = false } = props;

  return (
    <div className={props.className}>
      <span className="block-label-title p-pb-4 title-silver p-col-12">
        Редактируемые основные сведения
      </span>

      {props.isLegalEntity ? (
        <React.Fragment>
          <Kpp className="p-col-12 p-md-6" disabled={disabled} />
          <Ogrn className="p-col-12 p-md-6" disabled={disabled} />
        </React.Fragment>
      ) : (
        <Ogrnip className="p-col-12 p-md-6" disabled={disabled} />
      )}

      <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap">
        <Phone className="p-col-12 p-md-6" disabled={disabled} />
        <Email className="p-col-12 p-md-6" disabled={disabled} />
      </div>

      <AddressLegal className="p-col-12" disabled={disabled} />
      <AddressActual className="p-col-12" disabled={disabled} />
    </div>
  );
}

export default EditData;
