import { SET_REGISTER, CLEAR_REGISTER } from "../actionTypes";

const initialState = {
  mobile: "",
  password: "",
  domain: "",
  checkHash: null,
};

export default function registerReducer(state = initialState, action) {
  switch (action.type) {
    case SET_REGISTER:
      let { mobile, password } = action.payload;

      return {
        ...state,
        mobile: mobile,
        password: password,
      };
    case CLEAR_REGISTER:
      return {
        mobile: "",
        password: "",
        domain: "",
        checkHash: null,
      };
    default:
      return state;
  }
}
