import React, { useState } from "react";
import { connect } from "react-redux";
import { setProp } from "domain/actions/equipment/bid";
import {
  QuantityCashbox,
  FFDCashbox,
  FNCashbox,
  DefaultSno,
  ListSno,
  AgentsCashbox,
} from "routes/pages/equipments/components";

function DParams(props) {
  const [taxes, setTaxes] = useState([]);
  const getTaxes = (taxes) => setTaxes(taxes);

  return (
    <div className={props.className}>
      <span className="block-label-title p-pl-2">Данные по кассам</span>
      <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap">
        <QuantityCashbox
          className="p-col-12 p-md-6 p-lg-4 p-xl-3"
          item={props.item}
        />

        <FFDCashbox
          className="p-col-12 p-md-6 p-lg-4 p-xl-3"
          item={props.item}
        />

        <FNCashbox
          className="p-col-12 p-md-6 p-lg-4 p-xl-3"
          item={props.item}
        />

        <AgentsCashbox
          className="p-col-12 p-md-6 p-lg-4 p-xl-3"
          item={props.item}
        />

        <ListSno
          className="p-col-12 p-md-6 p-lg-4 p-xl-3"
          item={props.item}
          getValue={getTaxes}
        />

        <DefaultSno
          className="p-col-12 p-md-6 p-lg-4 p-xl-3"
          item={props.item}
          defaultTaxes={taxes}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  manufacture: state.requisites.provider,
  models: state.requisites.nameModel,
});
export default connect(mapStateToProps, { setProp })(DParams);
