import React from "react";
import { connect } from "react-redux";
import { selectOrg } from "domain/actions/organizations";
import { Card } from "./components";

function Body(props) {
  const onEdit = (item) => props.selectOrg(item.id);

  const renderedListItems = props.orgs.map((i, index) => {
    return (
      <Card
        key={index}
        item={i}
        onPreview={() => onEdit(i)}
        rights={props.rights}
      />
    );
  });

  return <div className={props.className}>{renderedListItems}</div>;
}

const mapStateToProps = (state) => ({
  orgs: state.organizations.organizations,
  rights: state.users.rights,
});
export default connect(mapStateToProps, { selectOrg })(Body);
