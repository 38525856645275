import React from "react";
import Layouts from "./layouts";
import RightsPage from "components/rightsControl/RightsPage";

function Organizations(props) {
  return (
    <RightsPage right="ORGANIZATIONS">
      <Layouts history={props.history} />
    </RightsPage>
  );
}

export default Organizations;
