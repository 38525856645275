import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setProp, setIsValidate } from "domain/actions/organizations/created";
import { TextField } from "components/forms/fields";

function Name(props) {
  const [value, setValue] = useState("");
  const [updateKey, forceUpdate] = useState(1);

  useEffect(() => setValue(props.value), [props.value]);

  return (
    <TextField
      className={props.className}
      key={updateKey}
      label="Наименование"
      value={value}
      onChange={(e) => {
        setValue(e);
        props.setProp("name", e);
      }}
      onBlur={(e) => {
        props.setProp("name", e.target.value);
        forceUpdate(updateKey + 1);
      }}
      required={props.required}
      onValidate={props.setIsValidate}
    />
  );
}

const mapStateToProps = (state) => ({
  value: state.orgCreate.item.name,
  required: state.orgCreate.required,
});
export default connect(mapStateToProps, { setProp, setIsValidate })(Name);
