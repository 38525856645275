import React, { useState, useEffect } from "react";

import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import styles from "../styles.module.css";

import { SelectControl } from "components/forms/fields";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";

import {
  setUnitFunc,
  setTypeFunc,
  setTaxFunc,
  setPayObjectFunc,
  setPaymentMethodFunc,
} from "../components/windows/products/controllers/setValues";
import RequisitesController from "controllers/Requisites";

const Requisites = new RequisitesController();

function ParametersItemComponent({
  register,
  control,
  errors,
  setValue,
  requisite,
  item,
}) {
  const [count, setCount] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [sumReceipt, setSumReceipt] = useState(0);

  const [unit, setUnit] = useState(null);
  const [type, setType] = useState(null);
  const [paymentObject, setPayObject] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [tax, setTax] = useState(null);

  const [priceItem, setPriceItem] = useState(0);

  const recalculation = (c, p, d) => {
    let summa = +c * +p - +d;
    setSumReceipt(summa);
  };

  useEffect(() => {
    if (item) {
      setTimeout(() => {
        setUnitFunc(item.measurementUnit, setUnit, Requisites);
        setTypeFunc(item.type, setType, Requisites);
        setTaxFunc(item.tax, setTax, Requisites);
        setPayObjectFunc(item.paymentObject, setPayObject, Requisites);
        setPaymentMethodFunc(item.paymentMethod, setPaymentMethod, Requisites);

        setCount(item.quantity);
        setDiscount(item.infoDiscountAmount);
        setPriceItem(item.price);

        let summa = item.quantity * item.price - item.infoDiscountAmount;
        setSumReceipt(summa);
      }, 300);
    }
  }, [item]);

  useEffect(() => {
    if (unit) setValue("measurementUnit", unit);
  }, [unit, setValue]);
  useEffect(() => {
    if (type) setValue("type", type);
  }, [type, setValue]);
  useEffect(() => {
    if (paymentObject) setValue("paymentObject", paymentObject);
  }, [paymentObject, setValue]);
  useEffect(() => {
    if (paymentMethod) setValue("paymentMethod", paymentMethod);
  }, [paymentMethod, setValue]);
  useEffect(() => {
    if (tax) setValue("tax", tax);
  }, [tax, setValue]);

  return (
    <div className="p-col-12 p-d-flex p-flex-wrap">
      <div className="p-col-12 p-md-6 p-d-flex p-flex-wrap">
        <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap">
          <div className="p-p-0 p-col-12 p-fluid p-d-flex p-flex-wrap">
            <input ref={register()} name="id" type="text" hidden />

            <div className="p-col-12 p-xl-6 p-d-flex p-flex-column">
              <label className={`${styles.font} p-pb-1`}>Товар</label>
              <input
                ref={register({ required: "Обязательно для заполнения!" })}
                name="name"
                type="text"
                className={`${styles.borderBottom} ${styles.inputOpacity1} form-control p-inputtext p-component`}
                // style={{marginTop: "4px"}}
              />
            </div>

            <div className="p-p-0 p-col-12 p-xl-6 p-fluid p-d-flex">
              <SelectControl
                className="p-col-12 p-d-flex p-flex-column"
                name="measurementUnit"
                control={control}
                errors={errors}
                label="Ед. измерения"
                options={requisite ? requisite.unit : []}
                showClear={true}
                defaultValue={unit}
              />
            </div>

            <div className="p-col-12 p-xl-6 p-d-none p-flex-column">
              <label htmlFor="type-receipt" className={`${styles.font} p-pb-1`}>
                Тип предмета расчета
              </label>
              <Controller
                id="type"
                name="type"
                control={control}
                render={(props) => (
                  <Dropdown
                    value={props.value}
                    options={requisite ? requisite.itemType : []}
                    optionLabel="name"
                    onChange={(e) => props.onChange(e.value)}
                    onBlur={(e) => props.onBlur(e)}
                    className={`${styles.borderBottom} ${styles.inputOpacity1}`}
                  />
                )}
                rules={{ required: "Обязательно для заполнения!" }}
                defaultValue={requisite && !type ? requisite.itemType[0] : type}
              />
              <ErrorMessage
                errors={errors}
                name="type"
                as={<p className="errorText">message</p>}
              />
            </div>
          </div>

          <div className="p-p-0 p-col-12 p-fluid p-d-flex p-flex-wrap">
            <div className="p-col-12 p-xl-6 p-d-flex p-flex-column">
              <label htmlFor="type-receipt" className={`${styles.font} p-pb-1`}>
                Предмет расчета
              </label>
              <Controller
                name="paymentObject"
                control={control}
                render={(props) => (
                  <Dropdown
                    value={props.value}
                    options={requisite ? requisite.paymentObject : []}
                    optionLabel="name"
                    onChange={(e) => props.onChange(e.value)}
                    onBlur={(e) => props.onBlur(e)}
                    className={`${styles.borderBottom} ${styles.inputOpacity1}`}
                  />
                )}
                rules={{ required: "Обязательно для заполнения!" }}
                defaultValue={
                  requisite && !paymentObject
                    ? requisite.paymentObject[0]
                    : paymentObject
                }
              />
              <ErrorMessage
                errors={errors}
                name="type"
                as={<p className="errorText">message</p>}
              />
            </div>

            <div className="p-col-12 p-xl-6 p-d-flex p-flex-column">
              <label htmlFor="type-receipt" className={`${styles.font} p-pb-1`}>
                Способ расчета
              </label>
              <Controller
                name="paymentMethod"
                control={control}
                render={(props) => (
                  <Dropdown
                    value={props.value}
                    options={requisite ? requisite.paymentMethod : []}
                    optionLabel="name"
                    onChange={(e) => props.onChange(e.value)}
                    onBlur={(e) => props.onBlur(e)}
                    className={`${styles.borderBottom} ${styles.inputOpacity1}`}
                  />
                )}
                rules={{ required: "Обязательно для заполнения!" }}
                defaultValue={
                  requisite && !paymentMethod
                    ? requisite.paymentMethod[0]
                    : paymentMethod
                }
              />
              <ErrorMessage
                errors={errors}
                name="type"
                as={<p className="errorText">message</p>}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="p-col-12 p-md-6 p-d-flex p-flex-wrap">
        <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap">
          <div className="p-p-0 p-col-12 p-xl-6 p-fluid p-d-flex">
            <div className="p-col-6 p-d-flex p-flex-column">
              <label htmlFor="type-receipt" className={`${styles.font} p-pb-1`}>
                Кол-во
              </label>
              <Controller
                name="quantity"
                control={control}
                render={(props) => (
                  <InputNumber
                    value={props.value}
                    min={0}
                    // max={999999999.99}
                    mode="decimal"
                    minFractionDigits={2}
                    maxFractionDigits={3}
                    placeholder="00.000"
                    onChange={(e) => {
                      props.onChange(e.value);
                      setCount(e.value);
                      recalculation(e.value, priceItem, discount);
                    }}
                    onValueChange={(e) => {
                      props.onChange(e.value);
                      setCount(e.value);
                      recalculation(e.value, priceItem, discount);
                    }}
                    className={`${styles.borderBottom} `}
                  />
                )}
                defaultValue={null}
              />
            </div>

            <div className="p-col-6 p-d-flex p-flex-column">
              <label htmlFor="type-receipt" className={`${styles.font} p-pb-1`}>
                Цена
              </label>
              <Controller
                name="price"
                control={control}
                render={(props) => (
                  <InputNumber
                    value={props.value}
                    mode="decimal"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    // max={999999.99}
                    placeholder="00.00"
                    onChange={(e) => {
                      props.onChange(e.value);
                      setPriceItem(e.value);
                      recalculation(count, e.value, discount);
                    }}
                    onValueChange={(e) => {
                      props.onChange(e.value);
                      setPriceItem(e.value);
                      recalculation(count, e.value, discount);
                    }}
                    className={`${styles.borderBottom}`}
                  />
                )}
                defaultValue={null}
              />
            </div>
          </div>

          <div className="p-p-0 p-col-12 p-xl-6 p-fluid p-d-flex">
            <div className="p-col-6 p-d-flex p-flex-column">
              <label htmlFor="type-receipt" className={`${styles.font} p-pb-1`}>
                Налог. ставка
              </label>
              <Controller
                name="tax"
                control={control}
                render={(props) => (
                  <Dropdown
                    value={props.value}
                    options={requisite ? requisite.tax : []}
                    onChange={(e) => {
                      props.onChange(e.value);
                    }}
                    optionLabel="name"
                    className={`${styles.borderBottom}`}
                  />
                )}
                rules={{ required: "Обязательно для заполнения!" }}
                defaultValue={requisite && !tax ? requisite.tax[0] : tax}
              />
            </div>

            <div className="p-col-6 p-d-flex p-flex-column">
              <label htmlFor="type-receipt" className={`${styles.font} p-pb-1`}>
                Сумма
              </label>
              <Controller
                name="amount"
                control={control}
                render={(props) => (
                  <InputNumber
                    value={sumReceipt}
                    mode="decimal"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    className={`${styles.borderBottom}`}
                    disabled
                  />
                )}
                defaultValue={0}
              />
            </div>
          </div>
        </div>

        <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap">
          <div className="p-p-0 p-col-12 p-xl-6 p-fluid p-d-flex ">
            <div className="p-col-6 p-d-flex p-flex-column">
              <label htmlFor="type-receipt" className={`${styles.font} p-pb-1`}>
                Скидка,руб
              </label>
              <Controller
                name="infoDiscountAmount"
                control={control}
                render={(props) => (
                  <InputNumber
                    value={props.value}
                    mode="decimal"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    placeholder="00.00"
                    onChange={(e) => {
                      props.onChange(e.value);
                      setDiscount(e.value);
                      recalculation(count, priceItem, e.value);
                    }}
                    className={`${styles.borderBottom} `}
                  />
                )}
                defaultValue={null}
              />
            </div>

            <div className="p-col-6 p-d-flex p-flex-column">
              <label htmlFor="type-receipt" className={`${styles.font} p-pb-1`}>
                Отдел
              </label>
              <Controller
                name="department"
                control={control}
                render={(props) => (
                  <InputNumber
                    value={props.value}
                    min={0}
                    max={999999}
                    placeholder="00"
                    onChange={(e) => props.onChange(e.value)}
                    className={`${styles.borderBottom} `}
                  />
                )}
                defaultValue={1}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ParametersItemComponent;
