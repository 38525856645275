import React from "react";
import Title from "../titleBlock";

import styles from "../../../../index.module.css";

function ForWhat(state) {
  return (
    <div className={state.className}>
      <Title name={state.params.name} id={state.params.id} />

      <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap">
        <div
          className={`p-col-12 p-md-6 p-pl-4 p-pr-0 p-pr-md-3 p-pb-3 p-mb-5 ${styles.ellipse}`}
        >
          <p className="p-mb-1">
            Для защиты потребителей и контроля коммерческой деятельности
            организаций
          </p>

          <span>
            Для занятых в сфере торговли на уровне законодательства РФ введены
            требования к использованию маркированной продукции, что не позволяет
            осуществлять торговую деятельность без использования специальных
            технических средств.
          </span>
        </div>

        <div
          className={`p-col-12 p-sm-8 p-md-3 p-pr-0 p-pl-4 p-pt-3 p-pl-md-3 ${styles.ellipse} ${styles.image_md_none}`}
        >
          <p className="p-m-0 p-pb-3">
            Для уменьшения числа случаев продажи контрафактной продукции
          </p>
        </div>
      </div>
    </div>
  );
}

export default ForWhat;
