import React from "react";
import { connect } from "react-redux";

import { deleteBid } from "domain/actions/equipment/bid";
import { Button } from "primereact/button";

function DeleteBtn(props) {
  const confirm = () => {
    if (props.selected) return props.deleteBid(props.selected.id);
  };
  const isVisible = props.selected ? props.selected !== 11 : false;

  return (
    <div className={props.className}>
      {isVisible && (
        <Button
          label="Удалить заявку"
          className={` с-button-danger`}
          onClick={confirm}
          disabled={true}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  selected: state.bidList.select,
});
export default connect(mapStateToProps, { deleteBid })(DeleteBtn);
