import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { updateWorker, deleteWorker } from "domain/actions/equipment/bid";
import { getWorkers } from "domain/actions/organizations/index";
import Window from "components/windows";
import generateUUID from "utils/generateUUID";

import { Button } from "primereact/button";
import { TextField } from "components/forms/fields";
import CodeWorker from "./code";
import ColumnTypeToString from "controllers/ColumnTypeToString";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { showSuccess } from "domain/actions/error.actions";

const TypeToString = new ColumnTypeToString();

const generationCodeWorker = (name) => {
  return {
    name: name,
    host: "localhost",
    password: generateUUID().slice(-6),
  };
};

const editWorker = (name) => {
  return {
    name: name,
    host: "localhost",
  };
};

function EditWorkerWindow(props) {
  const {
    show,
    setShow,
    worker,
    updateWorker,
    deleteWorker,
    getWorkers,
    showSuccess,
  } = props;
  const [code, setCode] = useState(null);
  const [name, setName] = useState(``);

  useEffect(() => {
    if (worker) setName(worker.name);
  }, [worker]);

  const nameCashboxTemplate = (cashbox) => (
    <span>
      <strong>
        {cashbox?.cashboxModel ? cashbox.cashboxModel.model : "Не указано"}
      </strong>
    </span>
  );

  return (
    <>
      <Window
        className={`${props.className} ${
          props.activeSidebar
            ? "window-sidebar-active"
            : "window-sidebar-inActive"
        }`}
        style={{ maxWidth: "648px" }}
        header='Редактирование "Рабочего места"'
        visible={show}
        onHide={() => setShow(false)}
        modal={false}
        draggable={false}
      >
        <div className="p-col-12 p-d-flex p-flex-wrap">
          <div className="p-col-12 p-d-flex p-flex-wrap">
            <TextField
              label="Дата создания"
              className="p-col-12"
              value={worker ? new Date(worker.createdAt) : ""}
              disabled={true}
            />
          </div>

          <div className="p-col-12 p-d-flex p-p-0">
            <TextField
              label="Наименование"
              className="p-col-12"
              value={name}
              onChange={(e) => setName(e)}
            />
          </div>

          <div className="p-col-12 p-my-3">
            <Button
              className="p-col-12 p-text-center c-button"
              label="Сгенерировать новый код подключения"
              onClick={() => {
                updateWorker(worker.id, generationCodeWorker(name))
                  .then((r) => {
                    setCode(r.bindCode);
                    showSuccess("Сгенерирован новый код подключения!");
                  })
                  .catch((e) => console.log(e));
              }}
            />
            {code && <CodeWorker code={code} />}
          </div>

          <div className="p-col-12">
            <DataTable
              value={worker ? worker.cashboxes : []}
              paginator
              rows={15}
              dataKey="id"
              responsiveLayout="scroll"
              style={{ fontSize: "12px" }}
            >
              <Column
                header="Касса"
                body={(rowData) => nameCashboxTemplate(rowData)}
              ></Column>
              <Column field="factoryNumber" header="Заводской номер"></Column>
              <Column
                field="status"
                header="Статус"
                style={{ width: "5rem" }}
                body={(rowData) =>
                  TypeToString.toStatusEquipment(rowData.status)
                }
              ></Column>
            </DataTable>
          </div>

          <div className="p-col-12 p-d-flex p-flex-wrap">
            <div className="p-col-12 p-md-6">
              <Button
                className="p-col-12 p-text-center p-button-outlined p-button-danger"
                label="Удалить"
                onClick={() => {
                  deleteWorker(worker.id).then(() => {
                    getWorkers();
                    setShow(false);
                  });
                }}
              />
            </div>
            <div className="p-col-12 p-md-6">
              <Button
                className="p-col-12 p-text-center c-button"
                label="Применить"
                onClick={() => {
                  updateWorker(worker.id, editWorker(name)).then(() => {
                    getWorkers();
                    setShow(false);
                  });
                }}
              />
            </div>
          </div>
        </div>
      </Window>
    </>
  );
}

const mapStateToProps = (state) => ({
  orgs: state.organizations.organizations,
  activeSidebar: state.sidebar.show,
});

export default connect(mapStateToProps, {
  updateWorker,
  deleteWorker,
  getWorkers,
  showSuccess,
})(EditWorkerWindow);
