import orderBy from "lodash/orderBy";

/*
  pagination = {
    page: number;
    limit: number;
  }

  sort? = "ASC" | "DESC" | undefined;
*/
export function numericItem(data, pagination, sort = "ASC") {
  const result = orderBy(data, ["createdAt"], [sort.toLowerCase()]);
  const startNumeric =
    sort === "ASC"
      ? (pagination.page - 1) * pagination.limit
      : pagination.total - (pagination.page - 1) * pagination.limit;

  result.forEach((item, index) => {
    if (sort === "ASC") {
      item.number = startNumeric + index + 1;
    } else {
      item.number = startNumeric - index;
    }
  });

  return result;
}

export function numericItemLastIndex(data, last_index, count, sort = "ASC") {
  data.forEach((item, index) => {
    if (sort === "ASC") {
      item.number = last_index + index + 1;
    } else {
      item.number = count - index;
    }
  });

  return data;
}
