import { TOGGLE_PROGRESS } from "../../actionTypes";
import {
  SET_PAYMENTS,
  SET_PAGINATION_PAYMENTS_TOTAL,
  REFRESH_PAYMENTS,
  SELECT_PAYMENTS,
  SHOW_PAYMENT,
  SET_PAYMENTS_TOTAL_AMOUNT,
} from "../../types/payments";

import ClientController from "utils/Client";
import { ApiController } from "domain/controllers/ApiController";
import { download } from "utils/download";

const Client = new ClientController();
const Api = new ApiController();

export const getPayments = (params) => getByCursor(params);
export const getPaymentById = (id) => getById(id);
export const refreshPayments = () => refresh();

function getById(id) {
  return async function thunk(dispatch, getState) {
    const response = await Client.get(`/acqPayment/${id}`);

    dispatch({ type: SELECT_PAYMENTS, payload: response.data });
    dispatch({ type: SHOW_PAYMENT, payload: true });
  };
}

export function closeWinPayment() {
  return async function thunk(dispatch, getState) {
    return dispatch({ type: SHOW_PAYMENT, payload: false });
  };
}

function getByCursor(cursor) {
  return async function thunk(dispatch, getState) {
    dispatch({ type: TOGGLE_PROGRESS, payload: true });

    let filters = getFilters(getState);
    let params = { limit: 10, lastId: cursor.id, cursor: {} };
    if (filters?.sort?.createdAt) params.cursor["createdAt"] = cursor.createdAt;
    if (filters?.sort?.sum) params.cursor["sum"] = cursor.sum;

    Object.assign(params, filters);

    return await Client.post(`/acqPayment/list`, params)
      .then((response) => {
        let data = response.data;

        dispatch({
          type: SET_PAGINATION_PAYMENTS_TOTAL,
          payload: { total: data.count },
        });

        dispatch({
          type: SET_PAYMENTS,
          payload: data.data,
        });

        return response.data;
      })
      .then((res) => {
        return Api.get(
          `/acqPayment/listWithTotalAmount`,
          getFiltersForParams(filters)
        );
      })
      .then((res) => {
        dispatch({
          type: SET_PAYMENTS_TOTAL_AMOUNT,
          payload: res.data.result.totalCash,
        });
      })
      .finally((e) => dispatch({ type: TOGGLE_PROGRESS, payload: false }));
  };
}

function refresh() {
  return async function thunk(dispatch, getState) {
    dispatch({ type: REFRESH_PAYMENTS, payload: true });
    setTimeout(() => dispatch({ type: REFRESH_PAYMENTS, payload: false }), 500);
  };
}

export function loadData() {
  return async function thunk(dispatch, getState) {
    const filters = getFilters(getState);
    return await Api.get("/acqPayment/download", getFiltersForParams(filters), {
      responseType: "blob",
    })
      .then((response) => {
        download(response, "payments.xlsx");
      })
      .catch((e) => console.log(e));
  };
}

function getFilters(getState) {
  let filters = {
    sort: getState().paymentsFilter.sort,
    range: getState().paymentsFilter.range,
    filter: getState().paymentsFilter.filter,
  };

  for (let key in filters) {
    if (filters[key] === null) delete filters[key];
  }

  return filters;
}

function getFiltersForParams(filters) {
  return {
    dateFrom: filters.range?.createdAt?.from
      ? filters.range?.createdAt?.from
      : null,
    dateTo: filters.range?.createdAt?.to ? filters.range?.createdAt?.to : null,
    status: filters.filter?.status?.length
      ? JSON.stringify(filters.filter?.status)
      : [],
    organizationId: filters.filter?.organizationId?.length
      ? JSON.stringify(filters.filter?.organizationId)
      : [],
    operatorId: filters.filter?.operatorId?.length
      ? JSON.stringify(filters.filter?.operatorId)
      : [],
    sumFrom: filters.range?.sumDoc?.from
      ? filters.range?.sumDoc?.from
      : null,
    sumTo: filters.range?.sumDoc?.to
      ? filters.range?.sumDoc?.to
      : null,
  };
}
