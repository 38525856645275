import { SELECT_ORGANIZATIONS, INFO_ORGANIZATION } from "../actionTypes";
import {
  SHOW_WINDOW__ORGANIZATIONS,
  CLEAR_CREATE_ORGANIZATION,
} from "domain/types/organizations";

import ClientController from "../../utils/Client";

const Client = new ClientController();

export function getOrganizationById(id) {
  return async function thunk(dispatch, getState) {
    await Client.get(`/org/info/${id}`)
      .then((response) => {
        dispatch({ type: INFO_ORGANIZATION, payload: response.data.result });
      })
      .catch((e) => console.error(e));
  };
}

export function handleShowWindow(value) {
  return async function thunk(dispatch, getState) {
    dispatch({ type: SHOW_WINDOW__ORGANIZATIONS, payload: value });
    dispatch({ type: SELECT_ORGANIZATIONS, payload: null });
    dispatch({ type: CLEAR_CREATE_ORGANIZATION });
  };
}

export function selectedOrganization(organization) {
  return async function thunk(dispatch, getState) {
    return dispatch({
      type: SELECT_ORGANIZATIONS,
      payload: organization,
    });
  };
}
