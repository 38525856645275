import {
  SET_DOCS_SETTINGS,
  SET_SUPPORT_SETTINGS,
  SET_ACCOUNT_NAME_SETTINGS,
  SET_SETTINGS,
} from "domain/types/settings";
import { SHOW_SUCCESS, SHOW_ERROR } from "domain/actionTypes";
import ClientController from "utils/Client";
import { AMSController } from "domain/controllers";

const Client = new ClientController();
const AMS = new AMSController();

export function getSetting() {
  return async function thunk(dispatch, getState) {
    await AMS.get(`/accounts/settings`)
      .then((r) => dispatch({ type: SET_SETTINGS, payload: r.data.result }))
      .catch((e) => console.error(e));
  };
}

export function postSetting() {
  return async function thunk(dispatch, getState) {
    const settings = getState().settings;

    await AMS.put(`/accounts/settings`, settings)
      .then((r) =>
        dispatch({ type: SHOW_SUCCESS, payload: "Настройки обновлены" })
      )
      .catch((e) => console.error(e));
  };
}

export function setDocEmail(email) {
  return async function thunk(dispatch) {
    dispatch({ type: SET_DOCS_SETTINGS, payload: email });
  };
}

export function setSupport(email) {
  return async function thunk(dispatch) {
    dispatch({ type: SET_SUPPORT_SETTINGS, payload: email });
  };
}

export function setAccountName(name) {
  return async function thunk(dispatch) {
    dispatch({ type: SET_ACCOUNT_NAME_SETTINGS, payload: name });
  };
}

export function getCodeForRefreshToken() {
  return async function thunk(dispatch, getState) {
    const mobile = getState().users.current?.mobile;
    if (!mobile)
      return dispatch({
        type: SHOW_ERROR,
        payload: "Произошла ошибка, номер телефона не указан в анкете",
      });

    return await Client.post(`/auth/smsStatic`, { mobile });
  };
}

export function confirmTokenCode(code) {
  return async function thunk(dispatch, getState) {
    const mobile = getState().users.current?.mobile;

    await Client.post(`/auth/smsStaticConfirm`, {
      smsCode: Number(code),
      mobile,
    })
      .then((r) => r)
      .catch((e) => {
        dispatch({ type: SHOW_ERROR, payload: "Код подтверждения не верный" });
        throw new SyntaxError("Данные некорректны");
      });
  };
}
