import {
  SET_LOCAL_BID_EQUIPMENTS,
  SHOW_WINDOW_BID_EQUIPMENTS,
  SET_ORG_BID_EQUIPMENTS,
  SET_URL_BID_EQUIPMENTS,
  CLEAR_BID_EQUIPMENTS,
  SET_TARIFF_BID_EQUIPMENTS,
} from "../../../types/equipment";
import generateUUID from "utils/generateUUID";

const externId = generateUUID();

const initialState = {
  isLocal: null,
  tariffId: null,
  organizationId: null,
  externId: externId,
  show: false,
};

export default function bidEquipmentReduce(state = initialState, action) {
  switch (action.type) {
    case SET_LOCAL_BID_EQUIPMENTS:
      return {
        ...state,
        isLocal: action.payload,
      };
    case SET_ORG_BID_EQUIPMENTS:
      return {
        ...state,
        organizationId: action.payload,
      };
    case SET_URL_BID_EQUIPMENTS:
      return {
        ...state,
        urlShop: action.payload,
      };
    case SET_TARIFF_BID_EQUIPMENTS:
      return {
        ...state,
        tariffId: action.payload,
      };
    case CLEAR_BID_EQUIPMENTS:
      return {
        ...state,
        isLocal: null,
        tariffId: null,
        organizationId: null,
        externId: generateUUID(),
      };
    case SHOW_WINDOW_BID_EQUIPMENTS:
      return {
        ...state,
        show: action.payload,
      };
    default:
      return state;
  }
}
