import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ColumnTypeToString from "controllers/ColumnTypeToString";
import { Button } from "primereact/button";
import { showSuccess } from "domain/actions/error.actions";

import { ActionsColumn } from "components/static";

import { Number, Column } from "components/dataView";

// import InfoIcon from "@material-ui/icons/Info";

function CardComponent(props) {
  const [currentElementId, setCurrentElementId] = useState(null);
  const TypeToString = new ColumnTypeToString();
  const item = props.item;
  const onPreview = props.onPreview ? props.onPreview : null;
  const numColor = TypeToString.toStatusClass(item.status);
  const { showSuccess } = props;

  const sendLinkReceipt = () => props.showSendLinkReceipt(true);
  const redirectLinkReceipt = (uuid) => {
    const host =
      window.location.host === "gate.stage.vdpaybox.ru"
        ? "stage.vdpaybox.ru"
        : "vdpaybox.ru";
    const protocol = window.location.protocol;
    window.open(`${protocol}//rt.${host}/info/${uuid}`);
  };
  const handleCopyLink = () => {
    navigator.clipboard.writeText(item.urlPayment);

    showSuccess("Ссылка на онлайн-оплату скопирована");
  };

  useEffect(() => {
    if (currentElementId) props.onSetReceipt(currentElementId);
  }, [currentElementId, props.onSetReceipt]); // eslint-disable-line react-hooks/exhaustive-deps

  let menu = (item) => [
    {
      label: "Отправить ссылку на чек",
      icon: "pi pi-fw pi-envelope",
      command: () => sendLinkReceipt(),
    },
    {
      label: "Ссылка для просмотра",
      icon: "pi pi-fw pi-send",
      command: () => redirectLinkReceipt(item.uuid),
    },
    {
      label: "Просмотр чека",
      icon: "pi pi-fw pi-info-circle",
      command: () => onPreview(),
    },
  ];

  return (
    <div className="p-col-12 p-mb-2 c-shadow-2">
      <div className="p-col-12  p-d-flex p-flex-wrap">
        <div className="p-col-8 p-sm-2 p-lg-1 p-p-1 p-d-flex p-as-end p-order-0">
          <Number
            className={`p-as-end p-ml-1 ${numColor}`}
            value={item.number}
          />
        </div>

        <Column
          className="p-col-8 p-sm-2 p-lg-2 p-ai-start p-ai-sm-center p-p-1 p-as-end p-order-2 p-order-sm-1"
          title="Дата создания"
          value={TypeToString.toDate(item.createdAt)}
        />
        <Column
          className="p-col-3 p-lg-2 p-p-1 p-as-end p-d-none p-d-lg-flex"
          title="Тип чека"
          value={TypeToString.toNull(item.receiptType.name)}
        />

        <Column
          className="p-col-3 p-lg-2 p-p-1 p-as-end p-d-none p-d-sm-flex"
          title="Кассир"
          value={TypeToString.toNull(
            item?.taskJson?.parameters?.operator?.name
          )}
        />

        <Column
          className="p-col-2 p-sm-2 p-lg-1 p-p-1 p-as-end p-d-none p-d-sm-flex p-order-3"
          title="Сумма"
          value={TypeToString.toNull(item.sumDoc)}
        />
        <Column
          className="p-col-4 p-sm-2 p-lg-1 p-ai-end p-ai-sm-center p-sm-2 p-p-1 p-as-end p-order-3 p-order-sm-5"
          title="Статус"
          value={TypeToString.toStatusReceipt(item.status, item.trouble)}
        />
        <Column
          className="p-col-3 p-lg-2 p-p-1 p-as-end p-d-none p-d-lg-flex p-order-6"
          title="Дата фискализации"
          value={TypeToString.toDate(item.fnDateTime)}
        />

        <div className="p-col-4 p-sm-1 p-p-1 p-d-flex p-jc-end p-order-1 p-order-sm-6">
          {item?.urlPayment && item.status === 211 ? (
            <Button
              className="table-column--setting p-button-rounded p-button-text"
              icon="pi pi-copy"
              onClick={handleCopyLink}
            />
          ) : (
            <></>
          )}

          <ActionsColumn
            menuItems={menu(item)}
            element={item}
            setElement={() => setCurrentElementId(item.id)}
          />
        </div>
      </div>
    </div>
  );
}

export default connect(null, { showSuccess })(CardComponent);
