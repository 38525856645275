export const NavigationList = [
  { id: "ForWhat", name: "Для чего?", link: "#ForWhat" },
  { id: "WhyUs", name: "Почему мы?", link: "#WhyUs" },
  {
    id: "HowWeSolveProblems",
    name: "Как мы решаем проблемы?",
    link: "#HowWeSolveProblems",
  },
  {
    id: "WhoAreWeTryingFor",
    name: "Для кого мы стараемся?",
    link: "#WhoAreWeTryingFor",
  },
  {
    id: "HowCashRegistersWork",
    name: "Как работают кассы?",
    link: "#HowCashRegistersWork",
  },
  {
    id: "IntegrationCMS",
    name: "Интеграция с популярными CMS",
    link: "#IntegrationCMS",
  },
  {
    id: "IntegrationAPI",
    name: "Собственная интеграция по API",
    link: "#IntegrationAPI",
  },
];
