import React from "react";

const style = {
  width: "100%",
  maxWidth: "215px",
};

function Logotype() {
  return (
    <a href="https://www.vti-service.ru">
      <img
        src={process.env.PUBLIC_URL + "/img/vti-service.png"}
        style={style}
        alt="ВТИ-Сервис"
      />
    </a>
  );
}

export default Logotype;
