import {
  SET_SUPPLIER_EDIT_CREATERECEIPT,
  SET_AGENTS_EDIT_CREATERECEIPT,
  SET_CODES_EDIT_CREATERECEIPT,
} from "../../../types/createReceipt";
import { SELECTED_ITEMS_CREATERECEIPTS } from "../../../actionTypes";

export function selectedItem(id) {
  return async function thunk(dispatch, getState) {
    let items = getState().createReceiptItems.items.slice();
    let item = items.filter((el) => el.id === id);

    item = item.length ? item[0] : null;

    dispatch({ type: SELECTED_ITEMS_CREATERECEIPTS, payload: item });

    setTimeout(() => {
      dispatch({
        type: SET_AGENTS_EDIT_CREATERECEIPT,
        payload: item && item.agentInfo ? item.agentInfo : null,
      });
      dispatch({
        type: SET_SUPPLIER_EDIT_CREATERECEIPT,
        payload: item && item.supplierInfo ? item.supplierInfo : null,
      });
      dispatch({
        type: SET_CODES_EDIT_CREATERECEIPT,
        payload: {
          markingCode: item && item.markingCode ? item.markingCode : null,
          nomenclatureCode:
            item && item.nomenclatureCode ? item.nomenclatureCode : null,
        },
      });
    }, 500);
  };
}

export function setSupplier(data) {
  return async function thunk(dispatch, getState) {
    dispatch({ type: SET_SUPPLIER_EDIT_CREATERECEIPT, payload: data });
  };
}

export function setAgents(data) {
  return async function thunk(dispatch, getState) {
    dispatch({ type: SET_AGENTS_EDIT_CREATERECEIPT, payload: data });
  };
}

export function setCodes(data) {
  return async function thunk(dispatch, getState) {
    dispatch({ type: SET_CODES_EDIT_CREATERECEIPT, payload: data });
  };
}
