import {
  SETSORTS_RECEIPTS,
  SETRANGE_RECEIPTS,
  SETFILTRES_RECEIPTS,
  REFRESH_RECEIPTS,
  SHOW_FILTERS_RECEIPTS,
} from "../../../types/receipts";
import { sleep } from "controllers/sleep";

export function setSortsReceipts(...params) {
  return async function thunk(dispatch, getState) {
    if (!Object.keys(params[0]).length) params = null;

    dispatch({
      type: SETSORTS_RECEIPTS,
      payload: params ? params[0] : params,
    });

    refreshThunk(dispatch, getState);
  };
}

export function setFiltersReceipts(params) {
  return async function thunk(dispatch, getState) {
    if (!Object.keys(params).length) params = null;

    dispatch({
      type: SETFILTRES_RECEIPTS,
      payload: params,
    });

    refreshThunk(dispatch, getState);
  };
}

export function setRangeReceipts(...data) {
  return async function thunk(dispatch, getState) {
    const filters = { ...getState().receiptsFilter.range };

    let params = {};

    if (data[1]) {
      params[data[0]] = {
        from: data[1][0],
        to: data[1][1],
      };
    }

    if (!Object.keys(params).length) params = null;

    for (let key in filters) {
      if (key === data[0] && params !== null) {
        filters[key] = params[key];
      }
    }

    Object.assign(params ? params : {}, filters);

    dispatch({
      type: SETRANGE_RECEIPTS,
      payload: params,
    });

    refreshThunk(dispatch, getState);
  };
}

export function onShowFiltersReceipts(isShow) {
  return async function thunk(dispatch, getState) {
    dispatch({ type: SHOW_FILTERS_RECEIPTS, payload: isShow });
  };
}

async function refreshThunk(dispatch, getState) {
  dispatch({
    type: REFRESH_RECEIPTS,
    payload: true,
  });

  await sleep(300);

  dispatch({
    type: REFRESH_RECEIPTS,
    payload: false,
  });
}
