import {
  SET_RECEIPT_TEMPLATES,
  SET_SELECT_RECEIPT_TEMPLATES,
  SET_PAGINATION_TEMPLATES,
  SET_CLEAR_RECEIPT_TEMPLATES,
} from "../types/createReceipt/templates";

const initialState = {
  data: [],
  current: null,
  pagination: {
    limit: 1,
    page: 1,
    pages: 1,
    total: 1,
  },
};

export default function ReceiptTemplatesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_RECEIPT_TEMPLATES:
      return {
        ...state,
        data: action.payload,
      };
    case SET_SELECT_RECEIPT_TEMPLATES:
      return {
        ...state,
        current: action.payload,
      };
    case SET_CLEAR_RECEIPT_TEMPLATES:
      return {
        ...state,
        current: null,
      };
    case SET_PAGINATION_TEMPLATES:
      const { limit, page, pages, total } = action.payload;
      return {
        ...state,
        limit: limit,
        page: page,
        pages: pages,
        total: total,
      };
    default:
      return state;
  }
}
