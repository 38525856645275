import React from "react";
import styles from "../index.module.css";
import { Controller } from "react-hook-form";

import { MultiSelect } from "primereact/multiselect";

function MultiSelectField({
  className = "",
  name = "",
  label = "",
  value = null,
  options = [],
  optionLabel = "name",
  showClear = false,
  placeholder = "Не выбрано...",
  disabled = false,
  onChange = () => console.log("onChange"),
  onFocus = () => console.log("onFocus"),
  required = false,
}) {
  return (
    <div className={className}>
      <label htmlFor="type-receipt" className={`${styles.label} p-pb-1`}>
        {label} {required && <span style={{ color: "red" }}>*</span>}
      </label>
      <MultiSelect
        name={name}
        value={value}
        options={options}
        onChange={(e) => onChange(e.value)}
        onFocus={onFocus}
        optionLabel={optionLabel}
        className={`${styles.borderBottom}`}
        showClear={showClear}
        placeholder={placeholder}
        selectedItemsLabel="Выбрано {0} элементов"
        disabled={disabled}
      />
    </div>
  );
}

function MultiSelectFieldControl({
  className = "",
  name = "",
  label = "",
  options = [],
  optionLabel = "name",
  showClear = false,
  defaultValue = null,
  control,
  disabled = false,
}) {
  const getDefaultValue = (defaultValue) => {
    if (!defaultValue) return null;
    let value = options.filter((i) => i.id === 1);

    return value.length ? value[0] : null;
  };
  return (
    <div className={className}>
      <label htmlFor="type-receipt" className={`${styles.label} p-pb-1`}>
        {label}
      </label>
      <Controller
        name={name}
        control={control}
        render={(props) => (
          <MultiSelect
            value={props.value}
            options={options}
            onChange={(e) => {
              props.onChange(e.value);
            }}
            optionLabel={optionLabel}
            className={`${styles.borderBottom}`}
            showClear={showClear}
            selectedItemsLabel="Выбрано {0} элементов"
            disabled={disabled}
          />
        )}
        defaultValue={getDefaultValue(defaultValue)}
      />
    </div>
  );
}

export { MultiSelectField, MultiSelectFieldControl };
