import React, { useState, useEffect } from "react";
import { DCashbox, DConnect, BtnDelete } from "../components";
import { Comment } from "routes/pages/equipments/components";
import Line from "components/Line";
import { setProp } from "domain/actions/equipment/bid";
import { socket } from "domain/controllers/socket";
import { connect } from "react-redux";
import { showError } from "domain/actions/error.actions";
import { setLoading } from "domain/actions/equipment/bid/index";
import { SpinnerWrapper } from "components/wrappers";

function CashboxClient(props) {
  const [updateKey, forceUpdate] = useState(1);
  const [text, setText] = useState({ message: "", type: null });
  const setProp = props.setProp;
  // const showError = props.showError;
  const selectedWorker = props.selectedWorker;
  const { loading, setLoading } = props;

  useEffect(() => {
    setProp("connect", { ...props.item.connect, os: "Windows" }, props.item.id);
  }, [props.item]); // eslint-disable-line react-hooks/exhaustive-deps

  socket.on("setCashboxHardware", (cashbox) => {
    setLoading(false);

    if (cashbox?.err) {
      // return showError(cashbox.err.message);
      return setText({
        message: cashbox.err.message,
        type: "error",
      });
    }

    const item = props.item;
    if (
      cashbox?.cashbox.address === item?.connect.address &&
      cashbox?.cashbox.connectInterface.interface === item?.connect.interface
    ) {
      const model = props.models.filter(
        (i) => i?.model === cashbox.cashboxModel
      );

      const connect = { ...item.connect, os: "Windows" };

      if (selectedWorker) setProp("workerId", selectedWorker.id, item.id);
      setProp("cashboxModelId", model.length ? model[0].id : null, item.id);
      setProp("factoryNumber", cashbox.factoryNumber, item.id);
      setProp("regNumber", cashbox.regNumber, item.id);
      setProp("connect", connect, props.item.id);
      forceUpdate(updateKey + 1);
      setText({
        message: "Соединение установлено",
        type: "success",
      });
    }
  });

  useEffect(() => {
    return () => {
      socket.off("setCashboxHardware", () => {});
    };
  }, []);

  return (
    <SpinnerWrapper position="center" progress={loading}>
      <div className={props.className}>
        <DConnect className="p-col-12 p-p-0 " item={props.item} text={text} />
        <DCashbox
          className="p-col-12 p-p-0 p-mb-3"
          item={props.item}
          refresh={updateKey}
        />
        <BtnDelete
          id={props.item.id}
          className="c-position-absolute c-top-5 c-right-5"
        />

        <div className="p-col-12">
          <Line className="p-mt-2 p-mb-2" color="#9a9898" />
        </div>

        <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap">
          <Comment className="p-col-12 " item={props.item} />
        </div>
      </div>
    </SpinnerWrapper>
  );
}

const mapStateToProps = (state) => ({
  models: state.requisites.cashboxModel,
  selectedWorker: state.organizations.selectedWorker,
  loading: state.bidEquipmentsCashbox.isProgress,
});

export default connect(mapStateToProps, { setProp, showError, setLoading })(
  CashboxClient
);
