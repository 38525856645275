import React from "react";
import { PasswordFieldControl } from "components/forms/fields";
import { connect } from "react-redux";

function Email(props) {
  const disabled = !!props.select;
  const { errors } = props;

  return (
    <React.Fragment>
      <PasswordFieldControl
        control={props.formHook.control}
        errors={props.formHook.errors}
        label="Пароль:"
        name="password"
        required={!disabled}
        hint={true}
        className={props.className}
        disabled={disabled}
        responseError={errors?.password?.messages[0]}
      />

      <PasswordFieldControl
        control={props.formHook.control}
        errors={props.formHook.errors}
        label="Повторить пароль:"
        name="confirmPassword"
        className={props.className}
        required={!disabled}
        disabled={disabled}
        responseError={errors?.confirmPassword?.messages[0]}
      />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  select: state.users.select,
  errors: state.users.responseError,
});
export default connect(mapStateToProps)(Email);
