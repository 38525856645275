import { Route, Redirect } from "react-router-dom";
import { useAuth } from "./ProvideAuth";

export function PublicRoute({ children, ...rest }) {
  let auth = useAuth();

  return (
    <Route
      {...rest}
      render={({ location }) => {
        let pathname = location.state
          ? location.state.from.pathname
          : "/main/analytics";

        return !auth.isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: pathname,
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
}

export function PrivateRoute({ children, ...rest }) {
  let auth = useAuth();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

// import ClientController from "utils/Client";
// import {LOGIN_AUTH, LOGOUT_AUTH} from "domain/actionTypes";

// const Client = new ClientController();
// await Client.get("/user/me")
//     .then(() => dispatch({ type: LOGIN_AUTH }))
//     .catch((e) => dispatch({ type: LOGOUT_AUTH }))
