import React from "react";
import EditIcon from "@material-ui/icons/Edit";

function Edit(props) {
  return (
    <button
      className="c-btn-outline c-btn-success p-ml-md-0 "
      onClick={props.onClick}
    >
      <EditIcon />
    </button>
  );
}

export default Edit;
