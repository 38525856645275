import React from "react";
import { connect } from "react-redux";
import Label from "components/based/label";

function PreviewOrg(props) {
  const entity =
    props.select.isLegalEntity &&
    props.listEntity.filter((i) => i.id === props.select.entityId)[0].name;

  const entityName = props.select.isLegalEntity
    ? `${entity} "${props.select.name}"`
    : `ИП ${props.select.managerLastName} ${props.select.managerFirstName} ${props.select.managerMiddleName}`;

  return (
    <div className={props.className}>
      <Label
        value={entityName}
        className={`p-col-12 p-md-6 p-xl-4 p-pr-2 p-p-1 text-size-18`}
      />

      <div className="p-col-12 p-md-6 p-xl-3 p-p-1 p-d-flex p-jc-between">
        <Label value="Идентификатор:" className="p-col-4 p-p-0" />
        <span className="p-col-8 p-p-0">{props.select.id}</span>
      </div>

      <div className="p-col-12 p-md-6 p-xl-3 p-p-1 p-d-flex p-jc-between">
        <Label value="ИНН:" className="p-col-4 p-p-0" />
        <span className="p-col-8 p-p-0">{props.select.inn}</span>
      </div>

      <div className="p-col-12 p-md-6 p-xl-3 p-p-1 p-d-flex p-jc-between">
        <Label value="E-mail:" className="p-col-4 p-p-0" />
        <span className="p-col-8 p-p-0">{props.select.email}</span>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  listEntity: state.requisites.entity,
  select: state.orgCreate.select,
});
export default connect(mapStateToProps)(PreviewOrg);
