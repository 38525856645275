import React from "react";
import Header from "./header";
import Body from "./body";

function ProviderCashbox(props) {
  return (
    <div className={props.className}>
      <span className="block-label-title p-pl-2">На стороне поставщика</span>
      <Header className="p-col-12 p-d-none p-d-md-flex " />
      <Body
        list={props.item.cashboxes.slice(0, 1)}
        amount={props.item.amount}
        className="p-col-12 p-pt-1 p-pb-1 "
      />
    </div>
  );
}

export default ProviderCashbox;
