import React from "react";
import { connect } from "react-redux";

function HeaderWin(props) {
  const status = props.item.status;
  const cashbox = props.item.cashboxModel;

  const color = status === 2 ? "#c1c1c1" : status === 1 ? "#fb8d05" : "#4CAF50";

  const isActive = (active) => {
    const className = "p-p-1 p-as-start ";
    return active === 0 ? (
      <span className={className} style={{ color: color }}>
        Активна
      </span>
    ) : active === 1 ? (
      <span className={className} style={{ color: color }}>
        Неактивна
      </span>
    ) : (
      <span className={className} style={{ color: color }}>
        Заблокирована
      </span>
    );
  };
  return (
    <div className="p-d-flex p-flex-column">
      <div className="p-d-flex">
        <p className="p-mr-1 p-mt-0 p-mb-0">Параметры кассы</p>
        <p className="p-m-0">
          {" "}
          - <u>{cashbox.model}</u>
        </p>
      </div>
      {isActive(status)}
    </div>
  );
}

const mapStateToProps = (state) => ({
  item: state.equipments.select,
});
export default connect(mapStateToProps)(HeaderWin);
