import moment from "moment";

export function toPhone(phone) {
  if (!phone) return this.toNull();

  return phone.replace(/(\d)(\d\d\d)(\d\d\d)(\d\d)(\d\d)/, "+$1($2)$3-$4-$5");
}

export function toDate(date) {
  if (!date) return this.toNull();

  return moment(date).format("YYYY.MM.DD HH:mm");
}
