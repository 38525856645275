import React from "react";
import { MultiSelect } from "primereact/multiselect";
import styles from "../../style.module.css";

function MultiFilterComponent(props) {
  return (
    <div className={props.className}>
      <span className={styles.multiComponent__label}>{props.label}</span>

      <div className={styles.multiComponent}>
        <MultiSelect
          value={props.value}
          options={props.options ? props.options : []}
          onChange={(e) => props.setValue(e.value)}
          optionLabel={props.optionLabel ? props.optionLabel : "name"}
          placeholder="Не выбрано..."
          filter
          className={styles.multiComponent__selects}
          selectedItemsLabel="Выбрано {0} элементов"
          showClear={true}
          resetFilterOnHide={true}
          emptyFilterMessage="Совпадений не найдено"
        />
      </div>
    </div>
  );
}

export default MultiFilterComponent;
