import React from "react";
import { connect } from "react-redux";
import { Menu } from "components/static";

import { refreshPayments, loadData } from "domain/actions/payments";
import { updateType } from "domain/actions/createReceipt/items";

import AddIcon from "@material-ui/icons/Add";
import RefreshIcon from "@material-ui/icons/Refresh";
import CloudUpload from "@material-ui/icons/CloudUpload";

function MenuComponent(props) {
  const listMenu = [
    {
      id: "1",
      name: "Добавить платеж",
      icon: AddIcon,
      callback: () => {
        props.updateType("payment");
        props.history.push("/main/create/receipt");
      },
      disabled: true,
    },
    {
      id: "2",
      name: "Обновить страницу",
      icon: RefreshIcon,
      callback: () => props.refreshPayments(),
    },
    {
      id: "3",
      name: "Скачать",
      icon: CloudUpload,
      callback: () => props.loadData(),
    },
  ];
  return <Menu list={listMenu} className={props.className} />;
}

export default connect(null, { refreshPayments, updateType, loadData })(
  MenuComponent
);
