import React, { useRef } from "react";

import { Menu } from "primereact/menu";
import { Button } from "primereact/button";

export const ActionsColumn = (props) => {
  const { element, setElement, menuItems } = props;
  const menu = useRef(null);

  const handleClick = (event) => {
    if (menu && menu.current) menu.current.toggle(event);
    setElement();
  };

  return (
    <>
      <Menu model={menuItems} popup ref={menu} id={`popup_${element.id}`} />
      <Button
        className="table-column--setting p-button-rounded p-button-text"
        icon="pi pi-cog"
        onClick={handleClick}
        aria-controls={`popup_${element.id}`}
        aria-haspopup
      />
    </>
  );
};

export default ActionsColumn;
