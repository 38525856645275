import React from "react";
import { Button } from "primereact/button";

function CancelButton(props) {
  const { onShow, className } = props;

  return (
    <div className={className}>
      <Button
        label="Отмена"
        className="p-button-outlined p-button-secondary p-col-12"
        style={{ height: "50px" }}
        onClick={onShow}
      />
    </div>
  );
}

export default CancelButton;
