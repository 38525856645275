import React, { useState, useEffect } from "react";

import { Select } from "components/forms/fields";

const operationsReport = [
  { id: 1, name: "Открыть смену" },
  { id: 0, name: "Закрыть смену" },
  { id: 2, name: "Х-отчёт" },
];
const operationsPayout = [
  { id: 1, name: "Внесение" },
  { id: 2, name: "Выплата" },
];

function TypeOperation(props) {
  const [value, setValue] = useState(null);
  const list = props.isReport ? operationsReport : operationsPayout;

  useEffect(() => {
    let obj = list.filter((i) => i.id === props.value);
    setValue(obj.length ? obj[0] : null);
  }, [props.value, setValue, list]);

  return (
    <Select
      label="Тип операции"
      options={list}
      className={props.className}
      value={value}
      onChange={(e) => props.setValue(e.id)}
    />
  );
}

export default TypeOperation;
