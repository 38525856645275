import React, { useState } from "react";
import { connect } from "react-redux";
import { postRights } from "domain/actions/users";
import Window from "components/windows";
import CancelButton from "components/btns/cancelBtn";
import { Button } from "primereact/button";
import OrgList from "./org.list";
import CashboxList from "./cashbox.list";
import { showError } from "domain/actions/error.actions";

function WindowRights(props) {
  const [orgSelected, setOrg] = useState([]);
  const [cashboxSelected, setCash] = useState([]);
  const { show, setShow, users, select, showError, postRights } = props;

  const handleCancel = () => setShow(false);

  const handleSubmit = () => {
    const userData = users.filter((u) => u.id === select.id);
    if (!userData.length) return showError("Пользователь не найден");
    let memberId = 0;
    try {
      memberId = userData[0].rights[0].memberId;
    } catch (error) {
      return showError("У данного пользователя не назначены права доступа");
    }
    const data = {
      memberId: memberId,
      serviceId: 1,
      scope: {},
    };
    if (orgSelected.length)
      data.scope["organization"] = orgSelected.map((org) => org.id);
    if (cashboxSelected.length)
      data.scope["cashbox"] = cashboxSelected.map((cash) => cash.id);

    postRights([data]).then((r) => {
      if (r) handleCancel();
    });
  };

  const title = "Редактирование прав доступа";

  return (
    <Window
      header={title}
      visible={show}
      onHide={handleCancel}
      draggable={false}
      // className="p-col-12 p-md-10 p-xl-6 p-p-0"
    >
      <div className="p-col-12 p-p-1">
        <h5>Организации</h5>
        <OrgList selected={orgSelected} setSelect={setOrg} />
      </div>
      <div className="p-col-12 p-p-1">
        <h5>Оборудования</h5>
        <CashboxList selected={cashboxSelected} setSelect={setCash} />
      </div>

      <div className="p-col-12 p-d-flex p-jc-between">
        <CancelButton
          className="p-col-12 p-md-6 p-xl-4"
          onShow={handleCancel}
        />

        <div className="p-col-12 p-md-6 p-xl-4">
          <Button
            label="Применить"
            className="с-button-success"
            type="confirm"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </Window>
  );
}

const mapStateToProps = (state) => ({
  select: state.users.select,
  users: state.users.users,
});
export default connect(mapStateToProps, {
  showError,
  postRights,
})(WindowRights);
