import React from "react";
import { Dofd, DParams } from "../components";
import { PlaceTrade, Comment } from "routes/pages/equipments/components";
import Line from "components/Line";

function CashboxProvider(props) {
  return (
    <div className={props.className}>
      <Dofd className="p-col-12 p-p-0 p-mb-3" item={props.item} />
      <DParams className="p-col-12 p-p-0 p-mb-3" item={props.item} />

      <div className="p-col-12">
        <Line className="p-mt-2 p-mb-2" color="#9a9898" />
      </div>

      <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap">
        <PlaceTrade className="p-col-12 p-md-4 p-flex-wrap" item={props.item} />
        <Comment className="p-col-12 " item={props.item} />
      </div>
    </div>
  );
}

export default CashboxProvider;
