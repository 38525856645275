import React from "react";
import Window from "components/windows";
import Content from "./content";

function WorkerWindow(props) {
  const { className, show, close } = props;
  return (
    <Window
      className={className}
      header='Привязка "Рабочего места"'
      visible={show}
      onHide={close}
      style={{ minWidth: "375px" }}
      draggable={false}
    >
      <Content onClose={close} />
    </Window>
  );
}

export default WorkerWindow;
