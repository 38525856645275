import {
  SET_PROPS_CREATE_ORGANIZATION,
  SET_PROPS_EDIT_ORGANIZATION,
  CLEAR_CREATE_ORGANIZATION,
  CHANGE_VALIDATE_ORGANIZATION,
  SELECT_ORGANIZATIONS,
  ISVALIDATE_CREATE_ORGANIZATION,
  SET_ACQUIRING_ORGANIZATION,
  DELETE_ACQUIRING_ORGANIZATION,
  SET_PROPS_ACQUIRING_ORGANIZATION,
  SET_SHOW_ACQUIRING_ORGANIZATION,
  SET_SHOW_ACQUIRING_BIND_CASHBOX,
  SET_SHOW_ACQUIRING_LOCAL_ORGANIZATION,
  SET_SHOW_CONNECT_ORGANIZATION,
  SET_SHOW_CONNECT_EDIT_ORGANIZATION,
} from "domain/types/organizations";

const initialState = {
  item: {
    legalEntity: null,
    name: "",
    entityId: null,
    inn: "",
    kpp: "",
    ogrn: "",
    ogrnip: "",
    managerFirstName: "",
    managerMiddleName: "",
    managerLastName: "",
    positionName: "",
    phone: "",
    email: "",
    addressLegal: "",
    addressActual: "",
    delegate: false,
    delegateDoc: "",
    emailReceipt: "",
    region: "",
    postcode: "",
    organizationAcquiring: [],
    isActive: true,
  },
  acquiring: {
    id: 1,
    acquiringId: 1,
    paymentToken: "",
    paymentLogin: "",
    paymentPassword: "",
    paymentCallback: "",
    comment: "",
    extParams: {},
  },
  acquiringShow: false,
  acquiringLocalShow: false,
  bindCashboxShow: false,
  connectShow: false,
  connectShowEdit: false,
  select: null,
  isValid: false,
  required: false,
};

export default function pagination(state = initialState, action) {
  switch (action.type) {
    case SET_PROPS_CREATE_ORGANIZATION:
      return {
        ...state,
        item: action.payload,
      };
    case SET_PROPS_EDIT_ORGANIZATION:
      return {
        ...state,
        select: action.payload,
      };
    case CHANGE_VALIDATE_ORGANIZATION:
      return {
        ...state,
        required: action.payload,
      };
    case ISVALIDATE_CREATE_ORGANIZATION:
      return {
        ...state,
        isValid: action.payload,
      };
    case SELECT_ORGANIZATIONS:
      return {
        ...state,
        select: action.payload,
      };
    case SET_ACQUIRING_ORGANIZATION:
      return {
        ...state,
        item: action.payload,
      };
    case SET_PROPS_ACQUIRING_ORGANIZATION:
      return {
        ...state,
        item: action.payload,
      };
    case DELETE_ACQUIRING_ORGANIZATION:
      return {
        ...state,
        item: action.payload,
      };
    case SET_SHOW_ACQUIRING_ORGANIZATION:
      return {
        ...state,
        acquiringShow: action.payload,
      };
    case SET_SHOW_ACQUIRING_LOCAL_ORGANIZATION:
      return {
        ...state,
        acquiringLocalShow: action.payload,
      };
    case SET_SHOW_CONNECT_ORGANIZATION:
      return {
        ...state,
        connectShow: action.payload,
      };
    case SET_SHOW_CONNECT_EDIT_ORGANIZATION:
      return {
        ...state,
        connectShowEdit: action.payload,
      };
    case SET_SHOW_ACQUIRING_BIND_CASHBOX:
      return {
        ...state,
        bindCashboxShow: action.payload,
      };
    case CLEAR_CREATE_ORGANIZATION:
      return {
        ...state,
        item: initialState.item,
        isValid: false,
        required: false,
      };
    default:
      return state;
  }
}
