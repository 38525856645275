import React from "react";
import RepeatOutlinedIcon from "@material-ui/icons/RepeatOutlined";
import CancelIcon from "@material-ui/icons/Cancel";

function IconsTypeCard(props) {
  const notPay = (
    <React.Fragment>
      <RepeatOutlinedIcon
        style={{
          alignSelf: "center",
        }}
        className="c-mdi-icon--header"
      />
      <CancelIcon
        style={{
          alignSelf: "center",
          color: "#B40000",
        }}
        className="c-mdi-icon--header"
      />
    </React.Fragment>
  );
  return (
    <div className={props.className}>
      <div className="p-col-12 p-p-0 p-d-flex p-jc-between">{notPay}</div>
    </div>
  );
}

export default IconsTypeCard;
