import React from "react";
import { connect } from "react-redux";
import {
  Comment,
  ConfirmButton,
  Login,
  Password,
  Token,
  TypeAcquiring,
  UrlCallback,
  Tid,
  CertKey,
  FirstComponent,
  MerchantName,
  Merchant,
  SBPId,
  SbpAccountNumber,
  SBPMerchant,
  SecondComponent,
  Terminal,
  TimeLifePayment,
  RedirectSuccessfulUrl,
  RedirectFailFulUrl,
} from "./components";
import { Message } from "primereact/message";

function ContentAdd(props) {
  const _acquiring = props.acquiring.filter(
    (_item) => _item.id === +props.acquiringId
  );

  return (
    <div className={props.className}>
      <TypeAcquiring className="p-col-12" />

      {!props.select && _acquiring.length && (
        <>
          {_acquiring[0].name === "Alfabank" && (
            <div className="text-center p-col-12 p-p-0">
              Данная форма для создания эквайринга, если ссылка{" "}
              <span style={{ color: "green" }}>payment.alfabank.ru</span>
            </div>
          )}
          {_acquiring[0].name === "AlfabankAlt" && (
            <div className="text-center p-col-12 p-p-0">
              Данная форма для создания эквайринга, если ссылка{" "}
              <span style={{ color: "green" }}>pay.alfabank.ru</span>
            </div>
          )}
        </>
      )}

      {!props.select && _acquiring.length && (
        <>
          {_acquiring[0].name !== "Alfabank" &&
            _acquiring[0].name !== "AlfabankAlt" && (
              <>
                <Token className="p-col-12" />
                <UrlCallback className="p-col-12" />
              </>
            )}
        </>
      )}

      <Login className="p-col-12 p-md-6" />
      <Password
        className="p-col-12 p-md-6"
        name={
          _acquiring[0]?.name === "Alfabank" ||
          _acquiring[0].name === "AlfabankAlt"
            ? "Пароль"
            : undefined
        }
      />

      {!props.select && _acquiring.length && (
        <>
          {(_acquiring[0].name === "Alfabank" ||
            _acquiring[0].name === "AlfabankAlt") && (
            <>
              <RedirectSuccessfulUrl className="p-col-12" />
              <RedirectFailFulUrl className="p-col-12" />
              <TimeLifePayment className="p-col-12" />
            </>
          )}
        </>
      )}

      {!props.select && _acquiring.length && (
        <>
          {_acquiring[0].name === "SberQR" && (
            <>
              <Tid className="p-col-12 p-md-4" />
              <CertKey className="p-col-12 p-md-8" />
              <Message
                className="p-col-12"
                severity="info"
                text="Добавление сертификата будет доступно в детальном просмотре 'Эквайринга' после создания."
                closable={false}
              />
            </>
          )}
        </>
      )}

      {!props.select && _acquiring.length && (
        <>
          {_acquiring[0].name === "PSB" && (
            <>
              <SBPId className="p-col-12 p-md-4" />
              <Terminal className="p-col-12 p-md-8" />
              <Merchant className="p-col-12 p-md-6 p-xl-4" />
              <MerchantName className="p-col-12 p-md-6 p-xl-4" />
              <SBPMerchant className="p-col-12 p-md-6 p-xl-4" />
              <SbpAccountNumber className="p-col-12 p-md-6 p-xl-4" />
              <FirstComponent className="p-col-12 p-md-6 p-xl-4" />
              <SecondComponent className="p-col-12 p-md-6 p-xl-4" />
            </>
          )}
        </>
      )}

      <Comment className="p-col-12" />

      <div className="p-col-12 p-d-flex p-flex-wrap p-jc-end p-p-0">
        <ConfirmButton className="p-col-12 p-md-6" />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  acquiring: state.requisites.acquiring,
  acquiringId: state.orgAcquiring.acquiring.acquiringId,
  select: state.orgAcquiring.select,
});
export default connect(mapStateToProps)(ContentAdd);
