import React from "react";
import { Label } from "components/based";

function UrlPayment(props) {
  // const minUrl = props.urlPayment.slice(0, 19) + "...";
  const minUrl = props.urlPayment;
  const url = (
    <a
      href={props.urlPayment}
      target="_blank"
      rel="noopener noreferrer"
      className="text-color-success line-height-14"
      style={{ textDecoration: "underline", overflowWrap: "anywhere" }}
    >
      {minUrl}
    </a>
  );

  return (
    <div className={props.className}>
      <div className="p-d-flex p-col-12 p-p-0">
        <Label value="Url оплаты эквайринга" className="p-mr-4" />
        {url}
      </div>
    </div>
  );
}

export default UrlPayment;
