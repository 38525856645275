import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { SpinnerWrapper } from "components/wrappers";
import EmptyComponent from "../empty";
import PieChart from "./chart";
import Info from "./info";
import styles from "../style.module.css";

function ReceiptTypePayments(props) {
  const [isEmpty, setEmpty] = useState(true);
  const [isComing, setComing] = useState(true);

  useEffect(() => {
    const data = props.data;
    if (data && Object.keys(data).length) {
      try {
        const sum = +data[isComing ? "moneyIn" : "moneyOut"].total;
        setEmpty(sum === 0);
      } catch (error) {
        console.log(error);
      }
    }
  }, [setEmpty, props.data, isComing]);

  const renderContent = (
    <>
      {!isEmpty ? (
        <>
          <div className="c-h-300 p-col-12 p-p-1">
            <PieChart item={true} data={props.data} isComing={isComing} />
          </div>
          <div className="p-col-12 p-p-1 p-d-flex p-flex-column p-jc-center">
            <Info item={true} data={props.data} isComing={isComing} />
          </div>
        </>
      ) : (
        <EmptyComponent className="p-col-12 p-text-center" />
      )}
    </>
  );

  return (
    <div className={props.className}>
      <div className="p-col-12 p-p-3 c-box-shadow">
        <div className="analytics-title">Тип оплаты</div>

        <div className="p-col-12 p-pr-0 p-pl-0 p-d-flex p-jc-lg-between">
          <span
            onClick={() => setComing(true)}
            className={`${styles.ratingOperators} ${
              isComing && styles.comingRatActive
            }`}
          >
            По чекам прихода
          </span>
          <span
            onClick={() => setComing(false)}
            className={`${styles.ratingOperators} ${
              !isComing && styles.expendRatActive
            }`}
          >
            По чекам расхода
          </span>
        </div>

        <SpinnerWrapper position="center" progress={props.progress}>
          <div
            className="p-col-12 p-p-0 p-d-flex p-flex-wrap"
            style={{ minHeight: "390px", alignContent: "baseline" }}
          >
            {!props.progress && renderContent}
          </div>
        </SpinnerWrapper>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  data: state.analytics.data?.paymentType,
  progress: state.analytics.isProgress,
});

export default connect(mapStateToProps)(ReceiptTypePayments);
