import React from "react";
import SortsPanels from "./sorts";

function BidSettingsPanel(props) {
  return (
    <div className={props.className}>
      <SortsPanels className="p-flex-wrap p-col-12 p-p-0 p-d-flex p-p-md-3" />
    </div>
  );
}

export default BidSettingsPanel;
