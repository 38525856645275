import React from "react";
import { connect } from "react-redux";
import { getUserById } from "domain/actions/users";
import Card from "../../layouts/card";

function ListUser(props) {
  const onEdit = (item) => props.getUserById(item.id);

  const renderItems = props.users.map((i) => {
    const user = i;
    return (
      <Card
        key={user.id}
        item={user}
        onPreview={() => onEdit(user)}
        rights={props.rights}
      />
    );
  });

  return <div className={props.className}>{renderItems}</div>;
}

const mapStateToProps = (state) => ({
  users: state.users.users,
  rights: state.users.rights,
});
export default connect(mapStateToProps, { getUserById })(ListUser);
