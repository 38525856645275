import React from "react";
import styles from "../index.module.css";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import { Password } from "primereact/password";
import Line from "components/Line";

import {
  isPassword,
  REGEX_PASSWORD,
  REGEX_PASSWORD14,
} from "utils/password.util";

function PasswordField({
  className = "",
  name = "",
  label = "",
  value = null,
  feedback = false,
  defaultValue = "",
  onChange = () => console.log("onChange"),
  onBlur = () => console.log("onBlur"),
}) {
  return (
    <div className={className}>
      <label className={`${styles.label} p-pb-1`}>{label}</label>
      <Password
        name={name}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onBlur={onBlur}
        className={`${styles.borderBottom}`}
        feedback={feedback}
        defaultValue={defaultValue}
      />
    </div>
  );
}

function PasswordFieldControl({
  control,
  errors,
  className = "",
  name = "",
  label = "",
  feedback = false,
  defaultValue = "",
  required = false,
  hint = false,
  disabled = false,
  responseError = null,
}) {
  let rules = {
    validate: (value) => {
      if (!required) return true;

      let valid = isPassword(value);

      if (!valid)
        return "Слишком легкий пароль или содержит недопустимые символы";

      return valid;
    },
  };

  if (required) {
    rules.required = "Обязательно для заполнения!";
  }

  const header = <h6>Шкала уровня сложности пароля</h6>;
  const footer = (
    <div className={`${styles.passwordHint}`}>
      <Line />
      <p className="p-mt-2">Подсказка</p>
      <ul className="p-pl-2 p-ml-2 p-mt-0" style={{ lineHeight: "1.5" }}>
        <li>Используйте только латинские буквы. Пробелы не допустимы.</li>
        <li>Минимум одна строчная буква и одно число</li>
        <li>От 8 до 16 символов.</li>
      </ul>
      <Line />
      <span>Данная шкала не влияет на правильность заполнения</span>
    </div>
  );

  return (
    <div className={className}>
      <label className={`${styles.label} p-pb-1`}>
        {label} {required && <span style={{ color: "red" }}>*</span>}
      </label>
      <Controller
        name={name}
        control={control}
        render={(props) => (
          <Password
            value={props.value}
            onChange={(e) => props.onChange(e)}
            className={`${styles.borderBottom}`}
            feedback={feedback || hint}
            header={hint ? header : null}
            footer={hint ? footer : null}
            mediumRegex={REGEX_PASSWORD.source}
            strongRegex={REGEX_PASSWORD14.source}
            disabled={disabled}
          />
        )}
        rules={rules}
        defaultValue={defaultValue}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        as={<p className="errorText">message</p>}
      />
      {responseError && (
        <small className="p-error p-d-block">{`${responseError}`}</small>
      )}
    </div>
  );
}

export { PasswordField, PasswordFieldControl };
