import React from "react";
import Title from "../titleBlock";
import BlockText from "./components/IconAndText";

import styles from "../../../../index.module.css";

function WhyUs(state) {
  return (
    <div className={state.className}>
      <Title name={state.params.name} id={state.params.id} />

      <div className="p-d-flex p-flex-wrap">
        <div className="p-col-12 p-md-6 p-pl-0 p-pl-md-4">
          <BlockText
            iconName="robot"
            text="Автоматизированная система управления"
            className="p-col-12 p-p-0 p-d-flex"
          />
          <span className={styles.WhyUsSpan}>
            позволяет балансировать нагрузкой в масштабе вычислительной системы
            в режиме реального времени
          </span>
        </div>

        <div className="p-col-12 p-md-6 p-pl-0 p-pl-md-4">
          <BlockText
            iconName="notebook"
            text="Мониторинг работы кассового оборудования"
            className="p-col-12 p-p-0 p-d-flex"
          />
        </div>

        <div className="p-col-12 p-md-6 p-pl-0 p-as-end p-pl-md-4">
          <BlockText
            iconName="castle"
            text="Возможности настройки и ведения собственной политики безопасности"
            className="p-col-12 p-p-0 p-d-flex"
          />
        </div>

        <div className="p-col-12 p-md-6 p-pl-0 p-as-end p-pl-md-4">
          <BlockText
            iconName="notepad"
            text="Управление приоритетами задач"
            className="p-col-12 p-p-0 p-d-flex"
          />
        </div>
      </div>
    </div>
  );
}

export default WhyUs;
