import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  setPropAcquiring,
  setPropAcquiringEdit,
} from "domain/actions/organizations/created";
import { TextField } from "components/forms/fields";

function Merchant(props) {
  const [value, setValue] = useState("");
  const [updateKey, forceUpdate] = useState(1);

  const setProp = !props.select
    ? props.setPropAcquiring
    : props.setPropAcquiringEdit;

  useEffect(() => {
    if (props.select) return setValue(props.select.merchant);
    setValue(props.value);
  }, [props.value, props.select]);

  return (
    <TextField
      className={props.className}
      key={updateKey}
      label="Мерчант"
      value={value}
      onChange={(e) => setValue(e)}
      onBlur={(e) => {
        setProp("extParams", e.target.value, props.index, "merchant");
        forceUpdate(updateKey + 1);
      }}
    />
  );
}

export default connect(null, {
  setPropAcquiring,
  setPropAcquiringEdit,
})(Merchant);
