import React from "react";

import { Parameters, PanelButtons, Footer, ConfirmButton } from "./components";

function FormLayout(props) {
  return (
    <React.Fragment>
      <form className={props.className}>
        <Parameters
          className="p-d-flex p-flex-wrap"
          formHook={props.formHook}
        />

        <ConfirmButton id="formAddingItems" formHook={props.formHook} />
      </form>

      <PanelButtons className="p-col-12 p-d-flex p-flex-wrap" />

      <Footer className="p-col-12 p-d-flex p-jc-end" />
    </React.Fragment>
  );
}

export default FormLayout;
