import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button } from "primereact/button";
import CancelButton from "components/btns/cancelBtn";
import { TextField } from "components/forms/fields";
import { showSendPayLink, sendPayLink } from "domain/actions/receipt";
import { isEmail } from "utils/email.util";

function Content(props) {
  const [value, setValue] = useState(null);
  const { showSendPayLink, sendPayLink, item } = props;

  useEffect(() => {
    if (item) setValue(item.taskJson?.parameters?.clientInfo?.emailOrPhone);
  }, [item]);

  const handleSend = () => sendPayLink(value);

  return (
    <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap">
      <TextField
        className="p-col-12"
        label="Email:"
        value={value}
        onChange={(e) => setValue(e)}
        required={true}
        validate={{
          onValid: (val) => isEmail(val),
          message: "Email не прошел проверку",
        }}
      />
      <div className="p-col-12 p-d-flex p-flex-wrap p-p-0">
        <CancelButton
          className="p-col-12 p-md-6 p-xl-4"
          onShow={() => showSendPayLink(false)}
        />

        <div className="p-col-12 p-md-6 p-xl-4">
          <Button
            label="Отправить"
            className="с-button-success"
            onClick={handleSend}
            disabled={!isEmail(value)}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  item: state.receipts.select,
});
export default connect(mapStateToProps, { showSendPayLink, sendPayLink })(
  Content
);
