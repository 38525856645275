import React from "react";
import { Header, DataView } from "./components";
import { WindowOrg, WindowAcquiring, WindowBindCashbox } from "../windows";

function Layout(props) {
  return (
    <div className="p-pt-3 p-pr-3 p-pl-3 ">
      <Header className="p-d-flex p-flex-wrap p-col-12 p-p-0 p-jc-between" />

      <DataView />

      <WindowOrg className="c-window-page" />

      <WindowAcquiring className="p-col-12 p-md-9 p-xl-6 p-p-0" />

      <WindowBindCashbox className="p-col-12 p-md-9 p-xl-6 p-p-0" />
    </div>
  );
}

export default Layout;
