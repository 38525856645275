import React from "react";
import { connect } from "react-redux";
import { EmailField } from "components/forms/fields/email";
import { setSupport } from "domain/actions/settings";

function TechEmail(props) {
  return (
    <div className={props.className}>
      <EmailField
        label="Email - Технического отдела"
        value={props.email}
        onChange={props.setSupport}
        isValid={props.email && !!props.email.length}
        className="label-color-green"
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  email: state.settings.emailTech,
  isValid: true,
});
export default connect(mapStateToProps, { setSupport })(TechEmail);
