import React from "react";
import styles from "./contacts.module.css";
import LocationOnIcon from "@material-ui/icons/LocationOn";

function Address({ address, className = "" }) {
  return (
    <div className={`${styles.block} ${className}`}>
      <LocationOnIcon style={{ marginRight: "1rem" }} />
      <span className={`${styles.text} contacts-address`}>{address}</span>
    </div>
  );
}

export default Address;
