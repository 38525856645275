import React from "react";

import CodeItem from "./codeItem";
// import CodeItem from "routes/pages/createReceipt/windows/components/form/layouts/components/panelButtons/codeItem";
import Agents from "./agent";
import Supplier from "./supplier";

function PanelButtons(props) {
  return (
    <div className={props.className}>
      <CodeItem className="p-col-12 p-md-6 p-xl-3" />

      <Agents className="p-col-12 p-md-6 p-xl-3" />

      <Supplier className="p-col-12 p-md-6 p-xl-3" />

      <div className="p-col-12 p-md-6 p-xl-3"></div>
    </div>
  );
}

export default PanelButtons;
