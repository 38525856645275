import React from "react";
import { InputText } from "primereact/inputtext";

const style = {
  border: "1px solid",
};

function Like(props) {
  return (
    <div className={props.className}>
      <span className="p-input-icon-right p-col-12 p-p-0" style={style}>
        <i className="pi pi-search" />
        <InputText
          value={props.value}
          onChange={(e) => props.setValue(e.target.value)}
          placeholder="Поиск..."
          className="p-col-12"
        />
      </span>
    </div>
  );
}

export default Like;
