import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  setProp,
  setPropEdit,
  setIsValidate,
} from "domain/actions/organizations/created";
import { TextField } from "components/forms/fields";

function DelegateFirstName(props) {
  const [value, setValue] = useState("");
  const [updateKey, forceUpdate] = useState(1);
  const { disabled = false } = props;
  const setProp = !props.select ? props.setProp : props.setPropEdit;

  useEffect(() => {
    if (props.select) return setValue(props.select.delegateFirstName);
    setValue(props.value);
  }, [props.value, props.select]);

  return (
    <TextField
      className={props.className}
      key={updateKey}
      label="Имя"
      value={value}
      onChange={(e) => {
        setValue(e);
        setProp("delegateFirstName", e);
      }}
      onBlur={(e) => {
        setProp("delegateFirstName", e.target.value);
        forceUpdate(updateKey + 1);
      }}
      required={props.required && props.delegate}
      onValidate={props.setIsValidate}
      disabled={disabled}
    />
  );
}

const mapStateToProps = (state) => ({
  value: state.orgCreate.item.delegateFirstName,
  required: state.orgCreate.required,
  delegate: state.orgCreate.item.delegate,
  select: state.orgCreate.select,
});
export default connect(mapStateToProps, {
  setProp,
  setIsValidate,
  setPropEdit,
})(DelegateFirstName);
