import React, { useState, useEffect } from "react";
import styles from "../index.module.css";
import { Controller } from "react-hook-form";

import { Skeleton } from "primereact/skeleton";
import { Dropdown } from "primereact/dropdown";

function Select({
  className = "",
  name = "",
  label = "",
  value = null,
  options = [],
  optionLabel = "name",
  showClear = false,
  placeholder = "Не выбрано...",
  filter = false,
  filterBy = "name",
  showFilterClear = false,
  disabled = false,
  onChange = () => console.log("onChange"),
  required = false,
}) {
  return (
    <div className={className}>
      <label htmlFor="type-receipt" className={`${styles.label} p-pb-1`}>
        {label} {required && <span style={{ color: "red" }}>*</span>}
      </label>

      <Dropdown
        name={name}
        value={value}
        options={options}
        onChange={(e) => onChange(e.value)}
        optionLabel={optionLabel}
        className={`${styles.borderBottom}`}
        showClear={showClear}
        placeholder={placeholder}
        filter={filter}
        filterBy={filterBy}
        showFilterClear={showFilterClear}
        disabled={disabled}
        emptyMessage="Нет данных"
      />
    </div>
  );
}

function SelectControl({
  className = "",
  name = "",
  label = "",
  options = [],
  optionLabel = "name",
  filter = false,
  filterBy = "name",
  showClear = false,
  defaultValue = null,
  control,
  disabled = false,
}) {
  const getDefaultValue = (defaultValue) => {
    if (!defaultValue) return null;
    let value = options.filter((i) => i.id === defaultValue.id);

    return value.length ? value[0] : null;
  };
  return (
    <div className={className}>
      <label htmlFor="type-receipt" className={`${styles.label} p-pb-1`}>
        {label}
      </label>
      <Controller
        name={name}
        control={control}
        render={(props) => (
          <Dropdown
            value={props.value}
            options={options}
            onChange={(e) => {
              props.onChange(e.value);
            }}
            optionLabel={optionLabel}
            className={`${styles.borderBottom}`}
            showClear={showClear}
            filter={filter}
            filterBy={filterBy}
            disabled={disabled}
            emptyMessage="Нет данных"
          />
        )}
        defaultValue={getDefaultValue(defaultValue)}
      />
    </div>
  );
}

function LazySelect(props) {
  const [itemsCash, setItemsCash] = useState([]);
  const [pageCash, setPageCash] = useState({});

  useEffect(() => {
    if (!props.options) return;

    setItemsCash(props.options);
    setPageCash({});
  }, [props.options]);

  const onLazyLoad = async (event) => {
    const { last } = event;
    const page = Math.ceil((last + 1) / props.limit);

    if (pageCash[page]) return;
    setPageCash((prev) => Object.assign(prev, (prev[page] = page)));

    await props
      .getData(page, props.limit)
      .then((response) => response.data.result)
      .then((response) => {
        response.data.forEach((i) =>
          setItemsCash((prev) => {
            if (prev.map((i) => i.id).indexOf(i.id) >= 0) return prev;

            return [...prev, i];
          })
        );
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className={props.className}>
      <label htmlFor="type-receipt" className={`${styles.label} p-pb-1`}>
        {props.label}
      </label>
      <Dropdown
        value={props.value}
        options={itemsCash}
        onChange={props.onChange}
        optionLabel={props.optionLabel ? props.optionLabel : "name"}
        virtualScrollerOptions={{
          lazy: true,
          onLazyLoad: onLazyLoad,
          itemSize: 31,
          showLoader: false,
          // loading: lazyLoading,
          delay: 0,
          loadingTemplate: (options) => {
            return (
              <div
                className="p-d-flex p-ai-center p-p-2"
                style={{ height: "31px" }}
              >
                <Skeleton width={options.even ? "60%" : "50%"} height="1rem" />
              </div>
            );
          },
        }}
        placeholder={props.placeholder ? props.placeholder : "Не выбрано..."}
        disabled={props.disabled}
        showClear={props.showClear}
        className={styles.borderBottom}
        emptyMessage="Нет данных"
      />
    </div>
  );
}

export { Select, SelectControl, LazySelect };
