import React from "react";
import { useForm } from "react-hook-form";
import FormLayout from "./layouts";

function Form(props) {
  const { ...formHook } = useForm();
  return (
    <FormLayout
      className={props.className}
      formHook={formHook}
      close={props.close}
    />
  );
}

export default Form;
