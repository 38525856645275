import React from "react";
import { Menu, Sorts, Like } from "../../components";
import { Heading } from "components/static";

function Header(props) {
  return (
    <div className={props.className}>
      <div className="p-col-12 p-md-4">
        <Heading className="p-col-12 p-p-0" title="Пользователи" />

        <Menu history={props.history} className="p-col-12 p-p-0" />
      </div>

      <div className="p-col-12 p-md-8 p-lg-6 p-xl-5 p-d-flex p-flex-wrap">
        <Sorts className="p-flex-wrap p-col-12 p-d-flex p-jc-start p-jc-md-end" />
        <Like className="p-col-12" />
      </div>
    </div>
  );
}

export default Header;
