import React, { useState } from "react";
import { Card } from "primereact/card";
import { connect } from "react-redux";
import { toDate } from "controllers/typeToString";
import { onRead } from "domain/actions/notification";
import { useEffect } from "react";

const style = (isRead) => ({
  display: isRead ? "none" : "block",
  width: "14px",
  height: "14px",
  backgroundColor: "#056f09",
  borderRadius: "50%",
  border: "1px solid",
  borderColor: "#056f09",
  marginLeft: ".5rem",
  cursor: "pointer",
});

export const Message = (props) => {
  let { message, restart, onRead, onShow } = props;
  const [mess, setMess] = useState(null);
  useEffect(() => {
    setMess(message);
  }, [message]);

  const handleRead = async () => {
    if (mess.isRead === true) return;
    await onRead(mess.externId);
    restart();
  };

  const header = (type, isRead) => {
    const round = (
      <div style={style(isRead)} onClick={() => handleRead()}></div>
    );
    return type === "ALERT" ? (
      <div className="p-pt-3 p-pl-3 p-pr-3 p-d-flex p-jc-between">
        <h4 className="p-m-0" style={{ color: isRead ? "#f68383" : "red" }}>
          {" "}
          Внимание{" "}
        </h4>
        {round}
      </div>
    ) : (
      <div className="p-pt-3 p-pl-3 p-pr-3 p-d-flex p-jc-between">
        <h4 className="p-m-0" style={{ color: isRead ? "#327232b8" : "green" }}>
          {" "}
          Сообщение{" "}
        </h4>
        {round}
      </div>
    );
  };

  if (!mess) return <></>;

  return (
    <Card
      key={message.isRead}
      header={header(message.type, message.isRead)}
      style={{
        fontSize: "12px",
        margin: ".5em",
        height: "112px",
      }}
    >
      <div
        onClick={() => {
          onShow(message);
          handleRead(message.id);
        }}
      >
        <p
          className="p-m-0"
          style={{
            lineHeight: "1.5",
            color: message.isRead ? "silver" : "black",
          }}
        >
          {message.text}
        </p>

        <p>{toDate(message.createdAt)}</p>
      </div>
    </Card>
  );
};

export default connect(null, { onRead })(Message);
