import React from "react";
import styles from "../../../style.module.css";
import moment from "moment";

const MonthFilter = React.forwardRef((props, ref) => {
  const onClick = () => {
    const startOfMonth = moment().startOf("month").format();
    const endOfMonth = moment().endOf("month").format();
    if (props.active) return props.onChange(!props.active);

    props.onChange(!props.active, startOfMonth, endOfMonth);
  };
  return (
    <div
      className={`${styles.fastDateFilter} ${
        props.active ? styles.active : ""
      }`}
      onClick={onClick}
      ref={ref}
    >
      Месяц
    </div>
  );
});

export default MonthFilter;
