import React from "react";

import { ActiveSphere } from "components/based";
import { COLORS } from "./chart";

function Info(props) {
  const typeSort = props.isComing ? "moneyIn" : "moneyOut";
  const data =
    Object.keys(props.data).length > 0
      ? props.data[typeSort]
        ? props.data[typeSort]
        : {}
      : {};

  const obj = {
    electronically: data.electronically,
    cash: data.cash,
    other: data.other,
  };

  const renderItems = Object.entries(obj).map(([key, value], index) => {
    const typeName =
      key === "electronically"
        ? "Электронными"
        : key === "cash"
        ? "Наличными"
        : "Прочее";

    return (
      <div key={index} className="p-col-6 p-md-4 p-pr-1 p-pl-1 p-pt-1 p-d-flex">
        <ActiveSphere
          isActive={true}
          position="center"
          color={`${COLORS[index]}`}
        />

        <div className="p-pl-1">
          <span className="dashboard-label">{typeName}</span>
          <br />
          <span className="">{value} руб.</span>
        </div>
      </div>
    );
  });

  return (
    <div className="p-col-12 p-pb-4 p-d-flex p-flex-wrap">{renderItems}</div>
  );
}

export default Info;
