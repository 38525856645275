import {
  SORT_EQUIPMENTS,
  FILTER_EQUIPMENTS,
  RANGE_EQUIPMENTS,
  LIKE_EQUIPMENTS,
  SHOW_FILTERS_EQUIPMENTS,
} from "domain/types/equipment";

const initialState = {
  sort: null,
  range: null,
  filter: null,
  like: "",
  show: true,
};

export default function filters(state = initialState, action) {
  switch (action.type) {
    case SORT_EQUIPMENTS:
      return {
        ...state,
        sort: action.payload,
      };
    case RANGE_EQUIPMENTS:
      return {
        ...state,
        range: action.payload,
      };
    case FILTER_EQUIPMENTS:
      return {
        ...state,
        filter: action.payload,
      };
    case LIKE_EQUIPMENTS:
      return {
        ...state,
        like: action.payload,
      };
    case SHOW_FILTERS_EQUIPMENTS:
      return {
        ...state,
        show: action.payload,
      };
    default:
      return state;
  }
}
