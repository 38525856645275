export function clearObjectFromNull(object) {
  for (let key in object) {
    if (
      object[key] === undefined ||
      object[key] === null ||
      object[key] === ""
    ) {
      delete object[key];
    }
  }

  return object;
}
