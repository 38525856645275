import React from "react";
import Title from "../titleBlock";
import Monitoring from "./components/Monitoring";
import Technical from "./components/Technical";
import WorkWithItems from "./components/WorkWithItems";
import ConnectingToStoreCms from "./components/ConnectingToStoreCMS";
import IntegrationWithServices from "./components/IntegrationWithServices";
import SettingUpAndMonitoringActivities from "./components/SettingUpAndMonitoringActivities";
import EmptyColumn from "./components/Empty";

import styles from "../../../../index.module.css";

function HowCashRegistersWork(state) {
  return (
    <div className={state.className}>
      <Title name={state.params.name} id={state.params.id} />

      <div className="p-d-flex p-flex-wrap p-col-12 p-p-0">
        <Monitoring
          className={`${styles.HowCashRegistersWork} p-d-flex p-flex-column p-p-0 p-col-12 p-md-6 p-xl-3`}
        />

        <Technical
          className={`${styles.HowCashRegistersWork} p-d-flex p-flex-column p-p-0 p-col-12 p-md-6 p-xl-3`}
        />

        <WorkWithItems
          className={`${styles.HowCashRegistersWork} p-d-flex p-flex-column p-p-0 p-col-12 p-md-6 p-xl-3`}
        />

        <EmptyColumn
          className={`${styles.HowCashRegistersWork} p-col-12 p-md-6 p-xl-3 p-p-0 p-d-none p-d-md-block`}
        />

        <SettingUpAndMonitoringActivities
          className={`${styles.HowCashRegistersWork} p-d-flex p-flex-column p-p-0 p-col-12 p-md-6 p-xl-3`}
        />

        <IntegrationWithServices
          className={`${styles.HowCashRegistersWork} p-d-flex p-flex-column p-p-0 p-col-12 p-md-6 p-xl-3`}
        />

        <ConnectingToStoreCms
          className={`${styles.HowCashRegistersWork} p-d-flex p-flex-column p-p-0 p-col-12 p-md-6 p-xl-3`}
        />

        <EmptyColumn
          className={`${styles.HowCashRegistersWork} p-col-12 p-md-6 p-xl-3 p-p-0 p-d-none p-d-md-block`}
        />
      </div>
    </div>
  );
}

export default HowCashRegistersWork;
