import React from "react";

function HeaderTable(props) {
  const { amount, count, className } = props;
  console.log("payments", amount);
  return (
    <>
      <div className={className}>
        <span>
          <b>Поступление:</b> {amount.totalAmount}руб.
        </span>
        <span>
          <b>Возврат:</b> {amount.totalRefund}руб.
        </span>
        <span className="p-d-none">
          <b>Кол-во платежей:</b> {count}шт.
        </span>
      </div>
    </>
  );
}

export default HeaderTable;
