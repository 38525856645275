import React, { useState, useEffect } from "react";
import CashboxTable from "../cashboxTable";
import BidTable from "../bidTable";

import { TabView, TabPanel } from "primereact/tabview";

function TabViewComponent(props) {
  const [activeIndex, setActiveIndex] = useState(0);
  const getTabIndex = props.getTabIndex;

  useEffect(() => getTabIndex(activeIndex), [activeIndex, getTabIndex]);

  return (
    <TabView
      activeIndex={activeIndex}
      onTabChange={(e) => setActiveIndex(e.index)}
      className={props.className}
    >
      <TabPanel header="Общий список">
        <CashboxTable history={props.history} className="p-p-0" />
      </TabPanel>
      <TabPanel header="Заявки">
        <BidTable history={props.history} className="p-p-0" />
      </TabPanel>
    </TabView>
  );
}

export default TabViewComponent;
