import React from "react";

function NoAccess(props) {
  return (
    <div className="p-col-12 p-d-flex p-flex-center p-ai-center">
      <p>У вас недостаточно прав для редактирования общих настроек</p>
    </div>
  );
}

export default NoAccess;
