import React from "react";
import { useLocation, Link } from "react-router-dom";

export default function NotFound() {
  let location = useLocation();

  return (
    <React.Fragment>
      <h3 className={`title-page p-col-12`}>
        Страница{" "}
        <u>
          <code>{location.pathname}</code>
        </u>{" "}
        не найдена{" "}
      </h3>

      <Link to="/">Вернуться на главную</Link>
    </React.Fragment>
  );
}
