import React from "react";
import { connect } from "react-redux";
import { deleteAcquiring } from "domain/actions/organizations/created";

import { DeleteBtn } from "components/btns/iconBtn";

function DeleteButton(props) {
  const handleDelete = () => {
    props.deleteAcquiring(props.index);
  };
  return (
    <div className={props.className}>
      <DeleteBtn onClick={handleDelete} />
    </div>
  );
}

export default connect(null, { deleteAcquiring })(DeleteButton);
