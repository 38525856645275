import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setProp } from "domain/actions/equipment/bid";
import { Select } from "components/forms/fields";
import { showError } from "domain/actions/error.actions";

function OsConnect(props) {
  const [updateKey, forceUpdate] = useState(1);
  const [value, setValue] = useState(null);

  const getValue = props.getValue;

  useEffect(() => {
    getValue(props.item.os);
  }, [props.item, getValue]);

  useEffect(() => {
    // const item = props.os.filter((i) => i.os === props.item.connect.os);
    // setValue(item.length ? item[0] : null);
    // getValue(item.length ? item[0] : null);

    const item = props.os.filter((i) => i.os === "Windows");

    if (!item.length) {
      return props.showError('Операционная система "Windows" не найдена');
    }
    setValue(item[0]);
    getValue(item[0]);
  }, [props.os, props.item.connect.os, setValue, getValue]); // eslint-disable-line react-hooks/exhaustive-deps

  // const isActive = props.selected ? props.selected.status !== 11 : false;

  return (
    <Select
      key={updateKey}
      label="Операционная система"
      options={props.os}
      className={props.className}
      value={value}
      optionLabel="os"
      onChange={(e) => {
        let obj = {
          os: e.os,
          interface: null,
          address: props.item.connect.address,
        };
        props.setProp("connect", obj, props.item.id);
        props.setConnect(obj);
        forceUpdate(updateKey + 1);
      }}
      // disabled={isActive}
      disabled={true}
      // required
    />
  );
}

const mapStateToProps = (state) => ({
  os: state.requisites.connectInterface,
  selected: state.bidList.select,
});
export default connect(mapStateToProps, { setProp, showError })(OsConnect);
