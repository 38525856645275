import React from "react";
import { Heading } from "components/static";
import { Menu, Settings } from "../../../components";

function Header(props) {
  return (
    <div className={props.className}>
      <div className="p-col-12 p-md-4 p-pr-0">
        <Heading className="p-col-12 p-p-0" title="Организации" />

        <Menu history={props.history} className="p-col-12 p-p-0" />
      </div>

      <div className="p-col-12 p-md-8 p-lg-6 p-xl-4">
        <Settings className=" p-d-flex p-flex-wrap" />
      </div>
    </div>
  );
}

export default Header;
