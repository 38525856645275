import React from "react";

import Mobile from "./card.mobile";
import Desktop from "./card.desktop";
import { connect } from "react-redux";
import { getPaymentById } from "domain/actions/payments";
import { useMediaQuery } from "react-responsive";

function CardComponent(props) {
  const { getPaymentById } = props;

  const isMobile = useMediaQuery({
    query: "(max-width: 576px)",
  });

  return (
    <React.Fragment>
      {isMobile && (
        <Mobile
          item={props.item}
          onPreview={props.onPreview}
          onSetReceipt={getPaymentById}
          showSendLinkReceipt={() => console.log("showSendLinkReceipt")}
        />
      )}
      {!isMobile && (
        <Desktop
          item={props.item}
          onPreview={props.onPreview}
          onSetReceipt={getPaymentById}
          showSendLinkReceipt={() => console.log("showSendLinkReceipt")}
        />
      )}
    </React.Fragment>
  );
}

export default connect(null, { getPaymentById })(CardComponent);
