import React, { useState } from "react";
import { connect } from "react-redux";

import { KladrField } from "components/forms/fields";
import {
  setProp,
  setPropEdit,
  setIsValidate,
} from "domain/actions/organizations/created";

function AddressLegal(props) {
  const [value, setValue] = useState("");
  const [error] = useState(false);
  const { defaultQuery, select, rules, disabled } = props;

  const setProp = !select ? props.setProp : props.setPropEdit;

  return (
    <div className={props.className}>
      <KladrField
        label="Юридический адрес"
        className="p-col-12 p-p-0"
        value={value}
        onChange={(e) => {
          setValue(e);
          setProp("addressLegal", e.value);
          setProp("region", e.data.region);
          setProp("postcode", e.data.postal_code);
        }}
        defaultQuery={select ? select.addressLegal : defaultQuery}
        rules={rules}
        onValidate={props.setIsValidate}
        disabled={disabled}
      />
      {error && (
        <small className="p-error p-d-block">Адрес введен не полностью!</small>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  value: state.orgCreate.item.addressLegal,
  defaultQuery: state.orgCreate.item.addressLegal,
  rules: state.orgCreate.required,
  select: state.orgCreate.select,
});
export default connect(mapStateToProps, {
  setProp,
  setPropEdit,
  setIsValidate,
})(AddressLegal);
