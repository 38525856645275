import React from "react";
import {
  Delegate,
  DelegateDoc,
  DelegateFirstName,
  DelegateLastName,
  DelegateMiddleName,
} from "../../../../components/fields";

function Director(props) {
  const { disabled = false } = props;
  return (
    <div className={props.className}>
      <span className="block-label-title p-pb-4 title-silver p-col-12">
        Представитель
      </span>

      <Delegate className="p-col-12" disabled={disabled} />
      <DelegateDoc className="p-col-12" disabled={disabled} />

      <DelegateLastName className="p-col-12" disabled={disabled} />
      <DelegateFirstName className="p-col-12 p-md-6" disabled={disabled} />
      <DelegateMiddleName className="p-col-12 p-md-6" disabled={disabled} />
    </div>
  );
}

export default Director;
