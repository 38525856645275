import React from "react";
import { TextAreaField } from "components/forms/fields";

function Comment(props) {
  return (
    <TextAreaField
      label="Комментарий"
      className={props.className}
      value={props.value ? props.value : ""}
      onChange={(e) => {
        if (e.length < 512) props.setValue(e);
      }}
      autoResize
      params={{ rows: 5 }}
      placeholder="Напишите комментарий для приостановки/возобновления работы оборудования..."
      required={true}
    />
  );
}

export default Comment;
