import React from "react";

function Technical({ className }) {
  return (
    <div className={className}>
      <div>
        <strong>Технический мониторинг:</strong>
      </div>
      <p>
        отслеживание состояния устройства, обновление версий прошивки и ПО,
        уведомления в случае поломки, возможность удаленного подключения ЦТО для
        отладки
      </p>
    </div>
  );
}

export default Technical;
