import React from "react";
import { connect } from "react-redux";
import { postUser, putUser, handleShowWindow } from "domain/actions/users";
import { showError } from "domain/actions/error.actions";
import { Button } from "primereact/button";

function ConfirmButton(props) {
  const labelBtn = props.select ? "Изменить" : "Добавить";

  const onConfirm = async (data) => {
    if (data.inn?.length !== 0) {
      if (data.inn.length !== 12)
        return props.showError("Не верно заполнен ИНН физ.лица");
    }

    if (data.password !== data.confirmPassword)
      return props.showError("Пароли не совпадают");

    if (!props.select)
      return props
        .postUser(data)
        .then((r) => !!r && props.handleShowWindow(false));
    if (props.select)
      return props
        .putUser(data)
        .then((r) => !!r && props.handleShowWindow(false));
  };

  return (
    <div className={props.className}>
      <Button
        label={labelBtn}
        className="с-button-success"
        type="confirm"
        onClick={props.handleSubmit(onConfirm)}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  select: state.users.select,
});
export default connect(mapStateToProps, {
  showError,
  postUser,
  putUser,
  handleShowWindow,
})(ConfirmButton);
