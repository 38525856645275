import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  setPropAcquiring,
  setPropAcquiringEdit,
} from "domain/actions/organizations/acquiring";
import { TextField } from "components/forms/fields";

function Merchant(props) {
  const [value, setValue] = useState("");
  const [updateKey, forceUpdate] = useState(1);

  const setProp = !props.select
    ? props.setPropAcquiring
    : props.setPropAcquiringEdit;

  useEffect(() => {
    if (props.select) return setValue(props.select.extParams?.merchant);
    if (props.value?.extParams && props.value?.extParams?.merchant)
      setValue(props.value.extParams.merchant);
  }, [props.value, props.select]);

  return (
    <TextField
      className={props.className}
      key={updateKey}
      label="merchant"
      value={value}
      onChange={(e) => setValue(e)}
      onBlur={(e) => {
        setProp("extParams", e.target.value, 'merchant');
        forceUpdate(updateKey + 1);
      }}
    />
  );
}

const mapStateToProps = (state) => ({
  value: state.orgAcquiring.acquiring,
  select: state.orgAcquiring.select,
});

export default connect(mapStateToProps, {
  setPropAcquiring,
  setPropAcquiringEdit,
})(Merchant);
