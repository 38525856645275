import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import Card from "./card";

import Pagination from "components/static/pagination";
import { SpinnerWrapper } from "components/wrappers";

import {
  getTemplates,
  selectedTemplates,
} from "domain/actions/receiptTemplates.actions";

function Layouts(props) {
  const { templates, pagination, getTemplates, selectedTemplates } = props;

  const [page, setPage] = useState(1);
  const history = useHistory();

  useEffect(() => {
    if (page) getTemplates(page);
  }, [getTemplates, page]);

  const handleSelect = (i) => {
    selectedTemplates(i);
    history.push("/main/create/receipt");
  };

  const renderedListItems = templates.map((i, index) => {
    return <Card key={index} item={i} onSelect={() => handleSelect(i)} />;
  });

  return (
    <>
      <Pagination callback={(page) => setPage(page)} pagination={pagination} />

      <SpinnerWrapper className="c-pagination-table">
        <div className="p-d-flex p-flex-wrap">{renderedListItems}</div>
      </SpinnerWrapper>

      <Pagination callback={(page) => setPage(page)} pagination={pagination} />
    </>
  );
}

const mapStateToProps = (state) => ({
  templates: state.receiptTemplates.data,
  pagination: state.receiptTemplates.pagination,
});
export default connect(mapStateToProps, { getTemplates, selectedTemplates })(
  Layouts
);
