import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setProp } from "domain/actions/equipment/bid";
import { Select } from "components/forms/fields";

function InterfaceConnect(props) {
  const [updateKey, forceUpdate] = useState(1);
  const [value, setValue] = useState(null);

  useEffect(() => {
    const item = props.interface.filter(
      (i) => i.id === props.item.connect.interface
    );
    setValue(item.length ? item[0] : null);
  }, [props.interface, props.item.connect.interface, setValue]);

  const isActive = props.selected ? props.selected.status !== 11 : false;

  return (
    <Select
      key={updateKey}
      label="Интерфейс"
      options={props.interface}
      className={props.className}
      value={value}
      onChange={(e) => {
        let obj = {
          os: props.item.connect.os,
          interface: e.id,
          address: props.item.connect.address,
        };
        props.setProp("connect", obj, props.item.id);
        props.setConnect(obj);
        forceUpdate(updateKey + 1);
      }}
      disabled={isActive}
      required
    />
  );
}

const mapStateToProps = (state) => ({
  selected: state.bidList.select,
});
export default connect(mapStateToProps, { setProp })(InterfaceConnect);
