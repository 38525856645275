import React from "react";
import { connect } from "react-redux";
import { LegalEntity } from "../../../../components/fields";
import {
  DataOrg,
  AddingData,
  AcquiringData,
  ConfirmButton,
} from "../components";
import CancelButton from "components/btns/cancelBtn";
import { TabView } from "components/based";
import { handleShowWindow } from "domain/actions/organizations.actions";

function AddContent(props) {
  const { handleShowWindow } = props;

  return (
    <div className={props.className}>
      <LegalEntity className="p-col-12 p-md-6 p-xl-5 p-d-flex p-flex-wrap" />

      {props.isLegalEntity && (
        <React.Fragment>
          <TabView>
            <DataOrg label="Данные организации" className="p-col-12 p-p-0" />
            <AcquiringData
              label="Эквайринг"
              className="p-col-12 p-p-0 p-d-flex p-flex-wrap"
            />
            <AddingData
              label="Дополнительные параметры"
              className="p-col-12 p-p-0 p-d-flex p-flex-wrap"
            />
          </TabView>

          <div className="p-col-12 p-pr-0 p-pl-0 p-d-flex p-jc-between p-flex-grow-1 p-ai-end p-flex-wrap c-pB-mobile-100">
            <CancelButton
              className="p-col-12 p-md-4 p-xl-3"
              onShow={() => handleShowWindow(false)}
            />
            <ConfirmButton className="p-col-12 p-md-4 p-xl-3" />
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLegalEntity: state.orgCreate.item.legalEntity,
});
export default connect(mapStateToProps, { handleShowWindow })(AddContent);
