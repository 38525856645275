import React from "react";
import { AccountingEmail, TechEmail, NameAccount } from "../../../components";

function FormComponent(props) {
  return (
    <div className={props.className}>
      <span className="block-label-title p-pb-4 title-silver p-col-12">
        Главные настройки аккаунта
      </span>

      <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap">
        <NameAccount className="p-col-12 p-md-6 p-xl-4" />
        <TechEmail className="p-col-12 p-md-6 p-xl-4" />
        <AccountingEmail className="p-col-12 p-md-6 p-xl-4" />
      </div>
    </div>
  );
}

export default FormComponent;
