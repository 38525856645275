import React from "react";
import { connect } from "react-redux";
import { onShowFiltersReceipts } from "domain/actions/receipt/filters";

import Line from "components/Line";
import SortsPanels from "./sorts";
import DatePanel from "./datePanel";
import RangesPanel from "./ranges";
import MultiFilterPanel from "./multiFilter";
import { FiltersIcon } from "components/static";

function SettingsPanel(props) {
  return (
    <div className={props.className}>
      <div className="p-d-flex p-col-12 p-p-0">
        <div className="p-col-12 p-md-8 p-p-0">
          <DatePanel className="p-d-flex p-flex-wrap p-col-12 p-p-0 p-mb-2" />
          <SortsPanels className="p-d-flex p-flex-wrap p-col-12 p-p-0" />
        </div>

        <div className="p-col-4 p-d-none p-d-md-flex p-jc-end">
          <FiltersIcon
            setActive={props.onShowFiltersReceipts}
            active={props.showFilters}
          />
        </div>
      </div>

      <div className="p-col-12 p-p-0 p-mt-2 p-mb-2">
        <Line color="#0000003b" />
      </div>

      <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap">
        <MultiFilterPanel className="p-d-flex p-flex-wrap p-col-12 p-p-0">
          <RangesPanel className="p-d-flex p-flex-wrap p-col-12 p-lg-6" />
        </MultiFilterPanel>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  showFilters: state.receiptsFilter.show,
});
export default connect(mapStateToProps, { onShowFiltersReceipts })(
  SettingsPanel
);
