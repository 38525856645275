import React from "react";

import { Checkbox } from "primereact/checkbox";

function isVirtualComponent(props) {
  return (
    <div className={props.className}>
      <div className="p-d-flex">
        <label
          htmlFor="isVirtual"
          className="p-mr-2"
          style={{ userSelect: "none" }}
        >
          Показывать виртуальные кассы
        </label>

        <Checkbox
          inputId="isVirtual"
          checked={props.value}
          onChange={(e) => props.setValue(e.checked)}
        />
      </div>
    </div>
  );
}

export default isVirtualComponent;
