import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setOrganization } from "domain/actions/equipment/bid";
import { getLazyOrganizations } from "domain/actions/organizations";

import Label from "components/based/label";
import { LazySelect } from "components/forms/fields";

import styles from "../../style.module.css";

function SelectOrgs(props) {
  const [value, setValue] = useState(null);

  useEffect(() => {
    const org = props.orgs.filter((i) => +i.id === +props.organizationId);
    setValue(org.length ? org[0] : null);
  }, [props.orgs, props.organizationId, setValue]);

  const onChange = (e) => {
    props.setOrganization(e.value);
  };

  return (
    <div className={props.className}>
      <Label
        value="Выберите, организацию"
        className={`${styles.hLabel} p-col-12 p-pr-2 p-pl-0`}
        required
      />

      <LazySelect
        className={`${styles.local} p-col-12 p-p-0 `}
        value={value}
        optionLabel="name"
        options={value ? [value] : props.orgs}
        limit={5}
        onChange={(e) => onChange(e)}
        getData={props.getLazyOrganizations}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLocal: state.bidEquipments.isLocal,
  orgs: state.organizations.organizations,
  organizationId: state.bidEquipments.organizationId,
  selected: state.bidList.select,
});
export default connect(mapStateToProps, {
  setOrganization,
  getLazyOrganizations,
})(SelectOrgs);
