import React from "react";

function Monitoring({ className }) {
  return (
    <div className={className}>
      <div>
        <strong>Мониторинг торговых операций:</strong>
      </div>
      <p>
        хранение документации, формирование отчетов, аналитика, выгрузка
        графиков, реестров
      </p>
    </div>
  );
}

export default Monitoring;
