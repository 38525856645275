import React, { useRef } from "react";
import { connect } from "react-redux";
import { uploadCert } from "domain/actions/organizations/acquiring";
import { Button } from "primereact/button";

function Cert(props) {
  const ref = useRef(null);

  const handlerLoader = (event) => {
    event.preventDefault();
    if (ref && ref.current) ref.current.click();
  };
  const fileSelectHandler = (event) => {
    props.uploadCert(event.target.files[0]).then((r) => console.log(r));
  };

  return (
    <div className="p-col-12">
      <input
        type="file"
        name="cert"
        style={{ display: "none" }}
        onChange={fileSelectHandler}
        ref={ref}
        accept=".p12"
      />
      <Button
        className="с-button-success"
        label="Загрузить сертификат"
        onClick={handlerLoader}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  select: state.orgAcquiring.select,
});

export default connect(mapStateToProps, { uploadCert })(Cert);
