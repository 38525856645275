import React from "react";
import ClientList from "./clientCashbox";
import ProviderList from "./providerCashbox";

function ListCashbox(props) {
  return (
    <div className={props.className}>
      {props.item.isLocal ? (
        <ClientList item={props.item} />
      ) : (
        <ProviderList item={props.item} />
      )}
    </div>
  );
}

export default ListCashbox;
