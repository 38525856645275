import React from "react";
import { Heading } from "components/static";
import { Form } from "./components";
import { ConfirmBtn } from "../components";
import Workers from "routes/pages/workers";

function LayoutsComponent(props) {
  return (
    <div className="p-pt-3 p-pr-3 p-pl-3 ">
      <Heading className="p-col-12 p-pb-0" title="Настройки" />

      <Form className="p-col-12 p-p-3 c-box-shadow" />

      <div className="p-col-12 p-pr-0 p-d-flex p-jc-end">
        <ConfirmBtn className="p-col-12 p-md-4 p-xl-3 p-p-0" />
      </div>

      <Workers className="p-col-12" />
    </div>
  );
}

export default LayoutsComponent;
