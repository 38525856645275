import React, { useEffect } from "react";
import { Dialog } from "primereact/dialog";

const body = document.getElementsByTagName("body")[0];

function Window(props) {
  useEffect(() => {
    if (props.visible) body.classList.add("p-overflow-hidden");
    if (!props.visible) body.classList.remove("p-overflow-hidden");
  }, [props.visible]);

  return <Dialog {...props}>{props.children}</Dialog>;
}

export default Window;
