import {
  SET_PROPS_CREATE_ORGANIZATION,
  SET_PROPS_EDIT_ORGANIZATION,
  CHANGE_VALIDATE_ORGANIZATION,
  ISVALIDATE_CREATE_ORGANIZATION,
  REFRESH_ORGANIZATION,
  SHOW_WINDOW__ORGANIZATIONS,
  CLEAR_CREATE_ORGANIZATION,
  SELECT_ORGANIZATIONS,
  SET_ACQUIRING_ORGANIZATION,
  DELETE_ACQUIRING_ORGANIZATION,
  SET_PROPS_ACQUIRING_ORGANIZATION,
} from "domain/types/organizations";
import { SHOW_ERROR } from "domain/actionTypes";
import { isEmail } from "utils/email.util";
import { isINN, isKPP, isOGRN } from "utils/rus.tax.codes.util";
import { clearObjectFromNull } from "controllers/clearObjectFromNull";

import { ApiController } from "domain/controllers";

const Client = new ApiController();

export function postOrg() {
  return async function thunk(dispatch, getState) {
    const data = { ...getState().orgCreate.item };
    data.isLegalEntity = data.legalEntity.isLegalEntity;

    if (!isValid(data, dispatch)) return;
    clearObjectFromNull(data);

    if (data.organizationAcquiring.length)
      data.organizationAcquiring.forEach((i) => {
        delete i.id;
        clearObjectFromNull(i);
      });

    await Client.post(`/org`, data)
      .then((r) => {
        clearFull(dispatch);
      })
      .catch((e) => console.error(e));
  };
}

export function putOrg() {
  return async function thunk(dispatch, getState) {
    const data = { ...getState().orgCreate.select };

    if (!isValid(data, dispatch)) return;

    await Client.put(`/org/${data.id}`, data).then((r) => {
      clearFull(dispatch);
    });
  };
}

export function deleteOrg() {
  return async function thunk(dispatch, getState) {
    const data = { ...getState().orgCreate.select };

    await Client.delete(`/org/${data.id}`).then(() => clearFull(dispatch));
  };
}

export function setProp(key, value) {
  return async function thunk(dispatch, getState) {
    const item = { ...getState().orgCreate.item };

    item[key] = value;

    dispatch({ type: SET_PROPS_CREATE_ORGANIZATION, payload: item });
  };
}

export function setLegalEntity(value) {
  return async function thunk(dispatch, getState) {
    const item = { ...getState().orgCreate.item };

    if (value.isLegalEntity !== item.legalEntity?.isLegalEntity) {
      dispatch({ type: CLEAR_CREATE_ORGANIZATION });
      const newItem = { ...getState().orgCreate.item };
      newItem.legalEntity = value;

      dispatch({ type: SET_PROPS_CREATE_ORGANIZATION, payload: newItem });
      return;
    }

    item.legalEntity = value;

    dispatch({ type: SET_PROPS_CREATE_ORGANIZATION, payload: item });
  };
}

export function setAcquiring() {
  return async function thunk(dispatch, getState) {
    const item = { ...getState().orgCreate.item };
    const acquiring = { ...getState().orgCreate.acquiring };
    acquiring.id = item.organizationAcquiring.length;
    item.organizationAcquiring = [...item.organizationAcquiring, acquiring];

    dispatch({ type: SET_ACQUIRING_ORGANIZATION, payload: item });
  };
}

export function deleteAcquiring(index) {
  return async function thunk(dispatch, getState) {
    const item = { ...getState().orgCreate.item };
    item.organizationAcquiring.splice(index, 1);

    dispatch({ type: DELETE_ACQUIRING_ORGANIZATION, payload: item });
  };
}

export function setPropAcquiring(key, value, index, key2) {
  return async function thunk(dispatch, getState) {
    const item = { ...getState().orgCreate.item };
    const array = item.organizationAcquiring.slice();

    if(key2) {
      array[index][key][key2] = value;
    }
    else array[index][key] = value;

    dispatch({ type: SET_PROPS_ACQUIRING_ORGANIZATION, payload: item });
  };
}

export function setPropAcquiringEdit(acquiring) {
  return async function thunk(dispatch, getState) {
    // dispatch({ type: DELETE_ACQUIRING_ORGANIZATION, payload: acquiring });
  };
}

export function setPropEdit(key, value) {
  return async function thunk(dispatch, getState) {
    const item = { ...getState().orgCreate.select };
    item[key] = value;

    dispatch({ type: SET_PROPS_EDIT_ORGANIZATION, payload: item });
  };
}

export function changeValidate(isValid) {
  return async function thunk(dispatch, getState) {
    dispatch({ type: ISVALIDATE_CREATE_ORGANIZATION, payload: isValid });
    dispatch({ type: CHANGE_VALIDATE_ORGANIZATION, payload: isValid });
  };
}

export function setIsValidate(isValid) {
  return async function thunk(dispatch, getState) {
    dispatch({ type: ISVALIDATE_CREATE_ORGANIZATION, payload: isValid });
  };
}

function isValid(data, dispatch) {
  if (data.name === "") {
    dispatch({
      type: SHOW_ERROR,
      payload: "Не указанно наименование организации",
    });
    return false;
  }
  if (data.inn === "") {
    dispatch({ type: SHOW_ERROR, payload: "Не указан ИНН организации" });
    return false;
  }

  if (!isINN(data.inn)) {
    dispatch({
      type: SHOW_ERROR,
      payload: "ИНН организации не прошел проверку",
    });
    return false;
  }

  if (data.isLegalEntity && data.kpp === "") {
    dispatch({ type: SHOW_ERROR, payload: "Не указанно КПП организации" });
    return false;
  }

  if (data.isLegalEntity && !isKPP(data.kpp)) {
    dispatch({
      type: SHOW_ERROR,
      payload: "КПП организации не прошел проверку",
    });
    return false;
  }

  if (data.isLegalEntity && data.ogrn === "") {
    dispatch({ type: SHOW_ERROR, payload: "Не указанно ОГРН организации" });
    return false;
  }
  if (!data.isLegalEntity && data.ogrnip === "") {
    dispatch({ type: SHOW_ERROR, payload: "Не указанно ОГРНИП организации" });
    return false;
  }

  if (data.isLegalEntity && !isOGRN(data.ogrn)) {
    dispatch({
      type: SHOW_ERROR,
      payload: "ОГРН организации не прошел проверку",
    });
    return false;
  }

  if (!data.isLegalEntity && !isOGRN(data.ogrnip)) {
    dispatch({
      type: SHOW_ERROR,
      payload: "ОГРНИП организации не прошел проверку",
    });
    return false;
  }

  if (data.phone === "") {
    dispatch({
      type: SHOW_ERROR,
      payload: "Не указан номер телефона организации",
    });
    return false;
  }

  if (data.email === "") {
    dispatch({ type: SHOW_ERROR, payload: "Не указан e-mail организации" });
    return false;
  }

  if (!isEmail(data.email)) {
    dispatch({
      type: SHOW_ERROR,
      payload: "E-mail организации не прошел проверку",
    });
    return false;
  }

  if (data.addressLegal === "") {
    dispatch({
      type: SHOW_ERROR,
      payload: "Не указан юридический адрес организации",
    });
    return false;
  }

  if (data.postcode === "") {
    dispatch({
      type: SHOW_ERROR,
      payload: "Юридический адрес указан не достаточно точно",
    });
    return false;
  }

  if (data.addressActual === "") {
    dispatch({
      type: SHOW_ERROR,
      payload: "Не указан фактический адрес организации",
    });
    return false;
  }

  if (data.positionName === "") {
    dispatch({
      type: SHOW_ERROR,
      payload: "Не указано название должности руководителя",
    });
    return false;
  }

  if (data.managerLastName === "") {
    dispatch({ type: SHOW_ERROR, payload: "Не указана фамилия руководителя" });
    return false;
  }

  if (data.managerFirstName === "") {
    dispatch({ type: SHOW_ERROR, payload: "Не указано имя руководителя" });
    return false;
  }

  if (data.managerMiddleName === "") {
    dispatch({ type: SHOW_ERROR, payload: "Не указано отчество руководителя" });
    return false;
  }

  if (data.delegate && data.delegateDoc === "") {
    dispatch({
      type: SHOW_ERROR,
      payload: "Не указан номер документа представителя",
    });
    return false;
  }

  return true;
}

function clearFull(dispatch) {
  dispatch({ type: SHOW_WINDOW__ORGANIZATIONS, payload: false });
  dispatch({ type: REFRESH_ORGANIZATION });
  dispatch({ type: SELECT_ORGANIZATIONS, payload: null });
  dispatch({ type: CLEAR_CREATE_ORGANIZATION });
}
