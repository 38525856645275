import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import BarChart from "./chart";
import { SpinnerWrapper } from "components/wrappers";
import EmptyComponent from "../empty";

function Revenue(props) {
  const [isEmpty, setEmpty] = useState(true);

  useEffect(() => {
    const data = props.data;
    if (data && data.length > 0) {
      const sum = data.reduce((prev, current) => prev.total + current.total, 0);
      console.log(sum);
      setEmpty(sum === 0);
    }
  }, [setEmpty, props.data]);

  const renderContent = (
    <React.Fragment>
      <div className="c-h-400 p-col-12 p-p-1">
        {!isEmpty ? (
          <div className="c-h-400">
            <BarChart item={true} />
          </div>
        ) : (
          <EmptyComponent className="p-col-12 p-text-center" />
        )}
      </div>
    </React.Fragment>
  );

  return (
    <div className={props.className}>
      <div className="p-col-12 c-box-shadow">
        <div className="p-col-12 analytics-title">Средняя выручка в неделю</div>

        <SpinnerWrapper position="center" progress={props.progress}>
          <div
            className="p-col-12 p-p-0 p-d-flex p-flex-wrap"
            style={{ minHeight: "390px", alignContent: "baseline" }}
          >
            {!props.progress && renderContent}
          </div>
        </SpinnerWrapper>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  data: state.analytics.data?.revenueDaysOfWeek,
  progress: state.analytics.isProgress,
});

export default connect(mapStateToProps)(Revenue);
