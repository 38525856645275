import React from "react";
import { connect } from "react-redux";

import Empty from "components/dataView/emptyData";
import Item from "../itemTable";

import { ScrollPanel } from "primereact/scrollpanel";

function BodyTable(props) {
  const renderItems = props.items.map((item, index) => {
    return <Item key={index} item={item} />;
  });

  return (
    <div className={props.className}>
      {!props.items.length && <Empty value="Список товаров пуст" />}

      <ScrollPanel
        style={{ width: "100%" }}
        className={`c-scrollPanel p-p-2 c-scrollPanel-mobile-show`}
      >
        {renderItems}

        <div
          style={{ height: "110px" }}
          className="p-d-none p-d-md-block"
        ></div>
      </ScrollPanel>
    </div>
  );
}

const mapStateToProps = (state) => ({
  items: state.createReceiptItems.items,
});
export default connect(mapStateToProps)(BodyTable);
