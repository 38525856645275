import React from "react";
import Line from "components/Line";

const style = {
  fontStyle: "normal",
  fontWeight: "300",
  fontSize: "16px",
  lineHeight: "19px",
};

function Slide_1({ className }) {
  return (
    <div className={`${className} p-col-12 p-p-0 p-d-flex`}>
      <div className="p-col-12 p-pr-0 p-pl-3 p-md-6 p-d-flex p-flex-column p-jc-center">
        <div className="p-col-12 p-pt-3 p-pb-3">
          <p className="p-m-0" style={style}>
            API-сервер с <strong>расширенным функционалом</strong> для создания
            интерфейсов под нужды клиента
          </p>
          <Line height={3} />
        </div>

        <div className="p-col-12 p-pt-3 p-pb-3">
          <p className="p-m-0" style={style}>
            Настройка и ведение{" "}
            <strong>собственной политики безопасности</strong>
          </p>
          <Line height={3} />
        </div>

        <div className="p-col-12 p-pt-3 p-pb-3">
          <p className="p-m-0" style={style}>
            <strong>Отсутствие ограничений</strong> по числу сотрудников,
            организаций, оборудования и сервисов для взаимодействия с кассами
          </p>
          <Line height={3} />
        </div>
      </div>

      <div
        className="p-d-none p-d-md-flex p-flex-column p-jc-center"
        style={{ margin: "2rem auto" }}
      >
        <img
          src={process.env.PUBLIC_URL + "/img/greeting/slide_1.jpg"}
          alt="Для кого мы стараемся?"
          className="p-col-12 p-pr-3"
          style={{ margin: "0 auto" }}
        />
      </div>
    </div>
  );
}

export default Slide_1;
