import React, { useEffect } from "react";
import { FileUpload } from "primereact/fileupload";

// interface IProps {
//   myUploader?: (event: any) => void;
//   onSelectFIle: (event: any) => void;
//   label: string,
//   currentImg?: string
// }

const SingleUploadImage = React.forwardRef((props, ref) => {
  const { label, onSelectFIle, file, currentImg } = props;

  const itemTemplate = (file, props) => {
    // console.log(file)
    return (
      <div className="flex align-items-center flex-wrap p-0">
        <img
          alt={file.name}
          role="presentation"
          src={file.objectURL}
          style={{ width: "100%" }}
        />
      </div>
    );
  };

  const chooseOptions = {
    icon: "pi pi-fw pi-images",
    iconOnly: false,
    className: "single-choose-btn p-button-rounded p-button-outlined",
    label: "Загрузить",
  };
  const cancelOptions = {
    icon: "pi pi-fw pi-times",
    iconOnly: true,
    className:
      "custom-cancel-btn p-button-danger p-button-rounded p-button-outlined",
  };

  useEffect(() => {
    if (ref && ref.current && file) {
      ref.current.setState({ files: [file] });
    }
  }, [ref, file]);

  const headerTemplate = (options) => {
    const { className, chooseButton } = options;

    return (
      <div
        className={className}
        style={{
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
        }}
      >
        {chooseButton}
      </div>
    );
  };

  const emptyTemplate = () => {
    const template = (
      <div className="flex align-items-center p-dir-col">
        <span>{label}</span>
      </div>
    );

    const currentTemplate = (
      <div className="flex align-items-center flex-wrap p-0">
        <img
          alt="Логотип"
          role="presentation"
          src={`data:image/*;base64, ${currentImg}`}
          style={{
            width: "100%",
          }}
        />
      </div>
    );

    return !!currentImg && !file ? currentTemplate : template;
  };

  return (
    <div className="galleria single-uploader">
      <FileUpload
        ref={ref}
        name="image"
        url=""
        onUpload={() => {}}
        accept="image/*"
        maxFileSize={2097152}
        customUpload
        multiple={false}
        headerTemplate={headerTemplate}
        chooseOptions={chooseOptions}
        cancelOptions={cancelOptions}
        itemTemplate={itemTemplate}
        onSelect={(e) => {
          if (e.files.length) onSelectFIle(e.files[0]);
        }}
        onValidationFail={(e) => {
          if (ref && ref.current)
            setTimeout(() => {
              ref.current.setState({ files: [] });
            }, 500);
        }}
        emptyTemplate={emptyTemplate}
      />
    </div>
  );
});

export default SingleUploadImage;
