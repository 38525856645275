import React, { useState, useEffect, useRef, useCallback } from "react";
import DateFilter from "components/static/dateFilter";
import Day from "components/static/dateFilter/components/day";
import Month from "components/static/dateFilter/components/month";
import Week from "components/static/dateFilter/components/week";

function DatePanel({ setRange, className, defaultRange }) {
  const [dActive, toggleDActive] = useState(false);
  const [mActive, toggleMActive] = useState(false);
  const [wActive, toggleWActive] = useState(false);
  const [date, setDate] = useState(null);
  const [dateEvent, toggleDateEvent] = useState(false);

  let refDay = useRef(null);
  let refWeek = useRef(null);
  let refMonth = useRef(null);

  const memoizedRef = useCallback(
    (def) => {
      let refs = {
        day: refDay,
        week: refWeek,
        month: refMonth,
      };

      try {
        refs[def].current.click();
      } catch (e) {
        console.error(e);
      }
    },
    [refDay, refWeek, refMonth]
  );

  useEffect(() => {
    if (defaultRange) memoizedRef(defaultRange);
  }, [defaultRange, memoizedRef]);

  useEffect(() => {
    if (dateEvent) {
      toggleDActive(false);
      toggleMActive(false);
      toggleWActive(false);
    }
  }, [dateEvent]);

  const funcDActive = (active, start, end) => {
    toggleDActive(active);
    toggleMActive(false);
    toggleWActive(false);
    toggleDateEvent(false);
    setDate([start, end]);
  };

  const funcMActive = (active, start, end) => {
    toggleMActive(active);
    toggleDActive(false);
    toggleWActive(false);
    toggleDateEvent(false);
    setDate([start, end]);
  };

  const funcWActive = (active, start, end) => {
    toggleWActive(active);
    toggleMActive(false);
    toggleDActive(false);
    toggleDateEvent(false);
    setDate([start, end]);
  };

  useEffect(() => {
    if (!date) return;
    if (!date || date[0] === null) return setRange("createdAt", null);

    setRange("createdAt", date, {
      range: (dActive && "Day") || (mActive && "Month") || (wActive && "Year"),
    });
  }, [date, setRange, dActive, mActive, wActive]);

  return (
    <DateFilter
      className={className}
      date={date}
      setDate={setDate}
      isDateEvent={toggleDateEvent}
    >
      <div className="p-d-flex">
        <Day active={dActive} onChange={funcDActive} ref={refDay} />
        <Week active={wActive} onChange={funcWActive} ref={refWeek} />
        <Month active={mActive} onChange={funcMActive} ref={refMonth} />
      </div>
    </DateFilter>
  );
}

export default DatePanel;
