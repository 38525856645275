import React from "react";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

export function MessageDialog(props) {
  const { message, visible, setVisible, onBilling } = props;

  if (!message) return <></>;

  return (
    <Dialog
      header="Уведомление"
      className="notification"
      visible={visible}
      contentClassName="p-pr-3 p-pl-3"
      modal
      style={{ width: "50vw", minWidth: "355px" }}
      onHide={() => setVisible(false)}
      draggable={false}
      resizable={false}
    >
      <div>
        <p
          className="p-m-0"
          style={{
            lineHeight: "1.5",
          }}
        >
          {message.text}
        </p>

        {message.text.toUpperCase().indexOf("БАЛАНС") !== -1 && (
          <div className="p-col-12 p-d-flex p-jc-end">
            <Button label="Оплатить" onClick={onBilling} />
          </div>
        )}
      </div>
    </Dialog>
  );
}

export default MessageDialog;
