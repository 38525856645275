export function setAgentsFunc(data, cb, requisites) {
  if (data) {
    let agents = data.agents.map((i) => requisites.getAgentByEng(i));
    let agentInfo = { ...data };

    agentInfo.agents = agents;
    cb(agentInfo);
  }
}

export function setSupplierFunc(data, cb) {
  if (data) {
    cb(data);
  }
}

export function setCodesFunc(data, cb, requisites) {
  if (data.nomenclatureCode || data.markingCode) {
    let types = {
      nomenclatureCode: data.nomenclatureCode
        ? data.nomenclatureCode.type
        : null,
      markingCodeType: data.markingCodeType ? data.markingCodeType.type : null,
    };
    let nomenclatureCodeType = requisites.getNomenclatureCodeByEng(
      types.nomenclatureCode
    );
    let markingCodeType = requisites.getMarkingCodeByEng(types.markingCode);
    let codes = {
      nomenclatureCode: { ...data.nomenclatureCode },
      markingCode: { ...data.markingCode },
    };
    if (!!codes.nomenclatureCode)
      codes.nomenclatureCode.type = nomenclatureCodeType;
    if (!!codes.markingCodeType) codes.markingCodeType.type = markingCodeType;

    cb(codes);
  }
}

export function setParametersFunc(data, cb) {
  for (let key in data) {
    if (key === "measurementUnit") {
    } else {
      cb(key, data[key]);
    }
  }
}

export function setUnitFunc(unit, cb, requisites) {
  if (unit) {
    try {
      const result = requisites.unit.filter((i) => i.name === unit.name);
      const item = result.length ? result[0] : null;
      cb("measurementUnit", item);
    } catch (e) {
      console.log(e);
    }
  }
}
export function setTypeFunc(data, cb, requisites) {
  if (data) {
    cb(requisites.getType(data));
  }
}
export function setTaxFunc(data, cb, requisites) {
  if (data) {
    cb(requisites.getTax(data));
  }
}
export function setPayObjectFunc(data, cb, requisites) {
  if (data) {
    cb(requisites.getPayObject(data));
  }
}
export function setPaymentMethodFunc(data, cb, requisites) {
  if (data) {
    cb(requisites.getPaymentMethod(data));
  }
}
