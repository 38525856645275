import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getEquipmentsByFilter } from "domain/actions/equipment";
import { setFiltersEquipments } from "domain/actions/equipment/equipment/filters";
import { ScrollPanel } from "primereact/scrollpanel";
import Pagination from "components/static/pagination";
import { SpinnerWrapper } from "components/wrappers";
import { CashboxCard } from "./cashbox.card";

function CashboxList(props) {
  const [page, setPage] = useState(1);
  const [update, setUpdate] = useState(1);
  const {
    selected,
    setSelect,
    cashbox,
    pagination,
    getEquipmentsByFilter,
    setFiltersEquipments,
    select,
  } = props;

  useEffect(() => {
    setFiltersEquipments({});
  }, [setFiltersEquipments]);

  useEffect(() => getEquipmentsByFilter(page), [getEquipmentsByFilter, page]);

  useEffect(() => {
    if (select.scope) {
      const scope = select.scope.scope;
      if (scope?.cashbox) {
        const cash = [];
        scope.cashbox.forEach((id) => {
          const _filter = cashbox.filter((cash) => cash.id === id);
          if (_filter.length) cash.push(_filter[0]);
        });
        setSelect(cash);
      }
    }
  }, [select]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderedList = cashbox.map((i, index) => {
    const isSelect = selected.map((cash) => cash.id).includes(i.id);
    return (
      <CashboxCard
        key={index}
        update={update}
        item={i}
        setUpdate={setUpdate}
        setSelect={(item) => {
          setSelect((prev) => {
            const index = prev.map((cash) => cash.id).indexOf(i.id);
            if (index > -1) {
              prev.splice(index, 1);
            } else {
              prev.push(item);
            }

            return prev;
          });
        }}
        isSelect={isSelect}
      />
    );
  });

  return (
    <>
      <Pagination callback={setPage} pagination={pagination} />
      <ScrollPanel
        style={{
          width: "100%",
          height: "290px",
          boxShadow: "0px 0px 6px silver",
        }}
      >
        <SpinnerWrapper className="c-pagination-table">
          <div className="p-col-12 p-p-1">{renderedList}</div>
        </SpinnerWrapper>
      </ScrollPanel>
      <Pagination callback={setPage} pagination={pagination} />
    </>
  );
}

const mapStateToProps = (state) => ({
  select: state.users.select,
  pagination: state.equipmentPagination,
  cashbox: state.equipments.equipments,
});
export default connect(mapStateToProps, {
  getEquipmentsByFilter,
  setFiltersEquipments,
})(CashboxList);
