import React from "react";
import { connect } from "react-redux";
import { ConfirmBtn, SuccessIcon, ErrorIcon, TextResult } from "./components";

function Complete(props) {
  return (
    <div>
      <div className="p-col-12 p-p-0 p-d-flex p-jc-center">
        {props.isError ? <ErrorIcon /> : <SuccessIcon />}
      </div>

      <TextResult className="p-mt-4 p-mb-4" isError={props.isError} />

      <ConfirmBtn confirm={props.confirm} />
    </div>
  );
}

const mapStateToProps = (state) => ({
  isError: state.forms.isError,
});
export default connect(mapStateToProps)(Complete);
