import React, { useState } from "react";
import { connect } from "react-redux";
import { setProp } from "domain/actions/equipment/bid";
import { Select } from "components/forms/fields";

function ManufactureCashbox(props) {
  const [updateKey, forceUpdate] = useState(1);

  const isActive = props.selected ? props.selected.status !== 11 : false;

  return (
    <Select
      key={updateKey}
      label="Производитель"
      options={props.manufacture}
      className={props.className}
      value={props.item.providerId}
      onChange={(e) => {
        props.setProp("providerId", e, props.item.id);
        forceUpdate(updateKey + 1);
      }}
      disabled={isActive}
    />
  );
}

const mapStateToProps = (state) => ({
  manufacture: state.requisites.provider,
  selected: state.bidList.select,
});
export default connect(mapStateToProps, { setProp })(ManufactureCashbox);
