import {
  SET_DATA_ACCOUNT,
  SET_STATIC_TOKEN_ACCOUNT,
  SHOW_ERROR,
} from "../actionTypes";
import { AMSController } from "domain/controllers";

const AMS = new AMSController();

export function setAccountData(dispatch, data) {
  dispatch({
    type: SET_DATA_ACCOUNT,
    payload: data,
  });
}

export function getStaticToken() {
  return async function thunk(dispatch, getState) {
    return await AMS.get(`/accessTokens`)
      .then((r) =>
        dispatch({
          type: SET_STATIC_TOKEN_ACCOUNT,
          payload: r.data?.token?.token,
        })
      )
      .catch((e) => dispatch({ type: SHOW_ERROR, payload: e.message }));
  };
}

export function generationStaticToken() {
  return async function thunk(dispatch, getState) {
    return await AMS.post(`/accessTokens`)
      .then((r) =>
        dispatch({
          type: SET_STATIC_TOKEN_ACCOUNT,
          payload: r.data.token.token,
        })
      )
      .catch((e) => dispatch({ type: SHOW_ERROR, payload: e.message }));
  };
}
