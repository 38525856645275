import React from "react";

import { Column } from "components/dataView";
import EditIcon from "@material-ui/icons/Edit";
// import InfoIcon from "@material-ui/icons/Info";

import VirtualCashbox from "./virCashboxBtn";
import ColumnTypeToString from "controllers/ColumnTypeToString";

function Card(props) {
  const TypeToString = new ColumnTypeToString();
  const { item, rights } = props;

  return (
    <div className="p-d-flex p-flex-wrap p-pt-4 p-pb-4 c-card-bb">
      <div className="p-col-9 p-md-4 p-lg-2 p-order-0 p-p-1 p-as-center p-d-flex">
        <Column
          className="p-as-start"
          classTitle="p-d-none"
          classBody="c-color-green p-text-left"
          value={item.name}
        />
      </div>

      <Column
        className="p-col-6 p-md-3 p-lg-2 p-p-1 p-ai-start p-ai-md-center p-as-center p-order-2 p-order-md-4 p-order-lg-1"
        title="ИНН"
        value={item.inn}
      />

      <Column
        className="p-col-6 p-md-3 p-lg-2 p-p-1 p-ai-start p-ai-md-center p-as-center p-order-5 p-order-md-5 p-order-lg-2"
        title="КПП"
        value={item.kpp ? item.kpp : "-"}
      />

      <Column
        className="p-col-12 p-md-4 p-lg-3 p-p-1 p-ai-sm-center p-as-center p-order-6 p-order-md-1 p-order-lg-3"
        title="Юр.адрес"
        value={item.addressLegal}
      />

      <Column
        className="p-col-6 p-md-3 p-lg-1 p-p-1 p-ai-end p-ai-md-center p-as-center p-order-4 p-order-lg-4"
        title="Дата создания"
        value={TypeToString.toDate(item.createdAt)}
      />

      <VirtualCashbox
        className="p-col-6 p-md-3 p-lg-1 p-p-1 p-ai-end p-ai-md-center p-as-center p-order-5 p-d-flex p-d-lg-none"
        item={item}
      />

      <div className="p-col-3 p-md-4 p-lg-2 p-d-flex p-p-1 p-order-1 p-ai-end p-order-md-3 p-order-lg-5 p-jc-end">
        {rights.includes("ORGANIZATIONS_VIRTUAL_CASHBOX") && (
          <VirtualCashbox
            className="p-col-6 p-p-1 p-ai-end p-ai-md-center p-as-center p-d-none p-d-lg-flex"
            item={item}
          />
        )}

        {rights.includes("ORGANIZATIONS_READ") && (
          <EditIcon
            style={{ height: "2rem", width: "2rem" }}
            className="p-col-4 p-lg-6 p-p-1 c-hover-cursor"
            onClick={props.onPreview}
          />
        )}
      </div>
    </div>
  );
}

export default Card;
