import React from "react";
import Content from "./layouts";
import RightsPage from "components/rightsControl/RightsPage";

function Users(props) {
  return (
    <RightsPage right="USERS">
      <Content history={props.history} />
    </RightsPage>
  );
}

export default Users;
