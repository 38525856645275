import React from "react";
import { connect } from "react-redux";
import { handleShowWindow } from "domain/actions/equipment/bid";
import Content from "./layouts";
import Window from "components/windows";

import styles from "../../../style.module.css";

function BidWindow(props) {
  const close = props.handleShowWindow;

  return (
    <Window
      className={`${props.className} ${styles.bidWindow} ${
        props.activeSidebar
          ? "window-sidebar-active"
          : "window-sidebar-inActive"
      }`}
      header="Форма заявки на подключение кассы"
      visible={props.show}
      onHide={close}
      position="right"
      modal={false}
      draggable={false}
    >
      <Content className="p-col-12" />
    </Window>
  );
}

const mapStateToProps = (state) => ({
  show: state.bidEquipments.show,
  activeSidebar: state.sidebar.show,
});
export default connect(mapStateToProps, { handleShowWindow })(BidWindow);
