import React from "react";
import { useForm } from "react-hook-form";
import FormLayout from "./layouts";

function FormComponent(props) {
  const { ...formHook } = useForm();
  return <FormLayout className={props.className} formHook={formHook} />;
}

export default FormComponent;
