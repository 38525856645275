import {
  SORT_ORGANIZATION,
  FILTER_ORGANIZATION,
  RANGE_ORGANIZATION,
  SET_LIKE_ORGANIZATION,
} from "../../../types/organizations";

const initialState = {
  sort: null,
  range: null,
  filter: null,
  like: "",
};

export default function orgFilters(state = initialState, action) {
  switch (action.type) {
    case SORT_ORGANIZATION:
      return {
        ...state,
        sort: action.payload,
      };
    case RANGE_ORGANIZATION:
      return {
        ...state,
        range: action.payload,
      };
    case FILTER_ORGANIZATION:
      return {
        ...state,
        filter: action.payload,
      };
    case SET_LIKE_ORGANIZATION:
      return {
        ...state,
        like: action.payload,
      };
    default:
      return state;
  }
}
