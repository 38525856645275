import React from "react";
import { connect } from "react-redux";
import { CardComponent } from "components/static";
import { ProductComponent } from "./components";

function Products(props) {
  const label = (
    <span className="block-label-title title-bold p-pl-2 p-as-center">
      Список товаров
    </span>
  );

  const empty = <div className="p-col-12 p-text-center">-</div>;

  const renderedListItems = props.items.map((i, index) => {
    return <ProductComponent key={index} item={i} />;
  });

  return (
    <div className={props.className}>
      <CardComponent label={label} className="p-col-12">
        {renderedListItems.length ? renderedListItems : empty}
      </CardComponent>
    </div>
  );
}

const mapStateToProps = (state) => ({
  item: state.receipts.select,
});
export default connect(mapStateToProps)(Products);
