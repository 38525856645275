import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";
import orderBy from "lodash/orderBy";

export const COLORS = [
  "#104011",
  "#40af03d4",
  "#dc8919d4",
  "#721494d4",
  "#a94011",
  "#ccc",
];

function DonutChart(props) {
  const [data, setData] = useState([]);

  // const sortData = orderBy(data, [typeSort], ["desc"]).slice(0, 6);

  useEffect(() => {
    if (!props.data) return;
    const typeSort = props.isComing ? "moneyIn" : "moneyOut";
    const users = orderBy(props.data.users, [typeSort], ["desc"]);
    const result = [];

    if (users.length) {
      users.forEach((i) => {
        if (i[typeSort] === 0) return;
        else {
          result.push(i);
        }
      });
    }

    setData(() =>
      result.map((item) => ({
        name: `${item.lastName} ${item.firstName} ${item.middleName}`,
        value: +item[typeSort],
      }))
    );
  }, [props.data, props.isComing]);

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const name = payload[0].payload.name;
      const typeName = name;

      return (
        <div className="custom-tooltip p-p-2">
          <p className="dashboard-label">{`Кассир: ${typeName}`}</p>
          <p className="dashboard-label-other">{`Сумма: ${payload[0].payload.value} руб.`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer width="100%">
      <PieChart>
        <Pie
          data={data}
          activeIndex={0}
          // activeShape={renderActiveShape}
          dataKey="value"
          cx="50%"
          cy="50%"
          innerRadius={3}
          outerRadius={100}
          labelLine={false}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index]} />
          ))}
        </Pie>

        <Tooltip content={<CustomTooltip />} />
      </PieChart>
    </ResponsiveContainer>
  );
}

const mapStateToProps = (state) => ({
  data: state.analytics.data,
});
export default connect(mapStateToProps)(DonutChart);
