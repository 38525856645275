import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Email,
  FirstName,
  LastName,
  MiddleName,
  RootLevel,
  Mobile,
  Passwords,
  InnField,
  IdField,
} from "./components";
import { StaticToken } from "routes/pages/settings/components";
import { Button } from "primereact/button";
import WindowRights from "../../../rights";

function BodyForm(props) {
  const [rightsWin, showRight] = useState(false);
  const classNameField = "p-col-12 p-md-6 p-xl-4";
  const updateUser = !!props.select;
  const isDisabledRight = updateUser
    ? !props.rights.includes("USERS_UPDATE")
    : !props.rights.includes("USERS_CREATE");
  const isRedactors =
    props.role.includes("ACCOUNT_OWNER") &&
    props?.select?.roles !== "ACCOUNT_OWNER";

  const isShowToken = props.select
    ? props.select.id === props.account.id
    : false;

  const role = props.select
    ? props.roles.filter((i) => i === props.select.roles[0])
    : null;

  const roleIsActive = props.select
    ? props.select.id !== props.account.id && !!props.select.roles && role
    : true;

  return (
    <div className={props.className}>
      <IdField formHook={props.formHook} />

      <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap">
        <span className="p-col-12 block-label-title title-silver">
          Общие данные:
        </span>
        <LastName
          formHook={props.formHook}
          className={classNameField}
          disabled={isDisabledRight}
        />
        <FirstName
          formHook={props.formHook}
          className={classNameField}
          disabled={isDisabledRight}
        />
        <MiddleName
          formHook={props.formHook}
          className={classNameField}
          disabled={isDisabledRight}
        />
        <InnField
          formHook={props.formHook}
          className={classNameField}
          lengthVatin={12}
          disabled={isDisabledRight}
        />

        {roleIsActive && (
          <RootLevel
            formHook={props.formHook}
            className={classNameField}
            disabled={isDisabledRight}
          />
        )}

        {isShowToken && (
          <StaticToken
            formHook={props.formHook}
            className={classNameField}
            disabled={isDisabledRight}
          />
        )}
      </div>

      <div
        className={
          "p-flex-wrap p-mb-2 " + (updateUser ? "p-d-none" : "p-d-flex")
        }
      >
        <span className="p-col-12 block-label-title title-silver">
          Данные для авторизации:
        </span>
        <Passwords
          formHook={props.formHook}
          className="p-col-12 p-md-6"
          disabled={isDisabledRight}
        />
      </div>

      <div className={props.className}>
        <span className="p-col-12 block-label-title title-silver">
          Контактные данные:
        </span>
        <Mobile
          formHook={props.formHook}
          className={classNameField}
          disabled={isDisabledRight}
        />
        {/* <Phone formHook={props.formHook} className={classNameField} disabled={isDisabledRight}/> */}
        <Email
          formHook={props.formHook}
          className={classNameField}
          disabled={isDisabledRight}
        />
      </div>

      {isRedactors && updateUser && (
        <Button
          label="Назначить права доступа"
          className="с-button-success"
          type="confirm"
          onClick={(e) => {
            e.preventDefault();
            showRight(true);
          }}
          disabled={isDisabledRight}
        />
      )}

      <WindowRights show={rightsWin} setShow={showRight} />
    </div>
  );
}

const mapStateToProps = (state) => ({
  select: state.users.select,
  account: state.account.data?.user,
  role: state.account.data?.roles,
  roles: state.users.roles,
  rights: state.users.rights,
});
export default connect(mapStateToProps)(BodyForm);
