import React from "react";
import Window from "components/windows";

import Content from "./layouts";

function ConfirmGetToken(props) {
  return (
    <Window
      className={props.className}
      header="Подтвердить"
      visible={props.show}
      onHide={props.close}
      style={{ minWidth: "340px" }}
      draggable={false}
    >
      <Content className="p-col-12 c-body-full" setCloseWindow={props.close} />
    </Window>
  );
}

export default ConfirmGetToken;
