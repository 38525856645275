import axios from "axios";
import store from "domain/store";
import ErrorController from "domain/Error";
import { SHOW_ERROR } from "domain/actionTypes";
import { AuthController } from "./AuthController";

export class ApiController {
  constructor() {
    this.Auth = new AuthController();
    this.Error = new ErrorController();
  }

  dispatchCall(type, payload) {
    store.dispatch({
      type: type,
      payload: payload,
    });
  }

  getBaseUrl() {
    const env = process.env;
    const prefix = "/api";

    if (env.REACT_APP_ENVIRONMENT === "production") {
      return env.REACT_APP_BACKEND_PROD + prefix;
    }

    if (env.REACT_APP_ENVIRONMENT === "development") {
      return env.REACT_APP_BACKEND_DEV + prefix;
    }

    return env.REACT_APP_BACKEND_LOCAL + prefix;
  }

  getHeaders(params, headersCustom) {
    const tokens = this.Auth.getTokenStorage();

    let headers = {
      timeout: 10000,
      headers: {
        "Content-Type": "application/json",
      },
      params: params,
      baseURL: this.getBaseUrl(),
      ...headersCustom,
    };

    if (tokens.accessToken) {
      headers.headers.Authorization = `Bearer ${tokens.accessToken}`;
    }

    return headers;
  }

  handleError(e) {
    const timeoutRegexp = /timeout/i;
    const timeout = "Убедитесь в подключении к Интернету и повторите запрос";
    const forbidden = "У вас недостаточно прав на выполнение данной операции";

    if (e.message === "Network Error") {
      this.dispatchCall(SHOW_ERROR, timeout);
      throw new Error(timeout);
    }

    if (timeoutRegexp.test(e.message)) {
      this.dispatchCall(SHOW_ERROR, timeout);
      throw new Error(timeout);
    }

    if (e.response.status === 403) {
      this.dispatchCall(SHOW_ERROR, forbidden);
      throw new Error(forbidden);
    }

    this.dispatchCall(SHOW_ERROR, this.Error.getText(e));
    throw new Error(e.message);
  }

  async get(url, params, headers = {}) {
    return await axios
      .get(url, this.getHeaders(params, headers))
      .catch((e) => this.handleError(e));
  }

  async post(url, body) {
    return await axios
      .post(url, body, this.getHeaders())
      .catch((e) => this.handleError(e));
  }

  async put(url, body) {
    return await axios
      .put(url, body, this.getHeaders())
      .catch((e) => this.handleError(e));
  }

  async delete(url) {
    return await axios
      .delete(url, this.getHeaders())
      .catch((e) => this.handleError(e));
  }
}
