import React from "react";

function HeaderTable(props) {
  const { amount, count, className } = props;
  console.log("receipts", amount);
  return (
    <>
      <div className={className}>
        <div className="p-col-12 p-md-6 p-py-0 p-d-flex p-flex-column">
          <span>
            <b>Приход:</b> {amount.totalSell}руб.
          </span>
          <span>
            <b>Возврат прихода:</b> {amount.totalReturnSell}руб.
          </span>
        </div>
        <div className="p-col-12 p-md-6 p-py-0 p-d-flex p-flex-column">
          <span>
            <b>Расход:</b> {amount.totalBuy}руб.
          </span>
          <span>
            <b>Возврат расход:</b> {amount.totalReturnBuy}руб.
          </span>
        </div>
        <span className="p-d-none">
          <b>Кол-во платежей:</b> {count}шт.
        </span>
      </div>
    </>
  );
}

export default HeaderTable;
