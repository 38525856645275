import {
  SET_EQUIPMENTS,
  SELECT_EQUIPMENTS,
  SHOW_WINDOW__EQUIPMENTS,
  SHOW_SUCCESS,
  SHOW_ERROR,
} from "../../actionTypes";
import { SET_PAGINATION_EQUIPMENT } from "domain/types/equipment";
import { TOGGLE_PROGRESS } from "domain/actionTypes";
import getFilters from "controllers/getFilters";

import ClientController from "utils/Client";

const Client = new ClientController();

export function getEquipments() {
  return async function thunk(dispatch, getState) {
    return await Client.get("/cashbox/list")
      .then((response) => {
        let result = formCashbox(response.data);
        dispatch({ type: SET_EQUIPMENTS, payload: result });
      })
      .catch((e) => console.log(e));
  };
}

export function getEquipmentsByFilter(page) {
  return async function thunk(dispatch, getState) {
    dispatch({ type: TOGGLE_PROGRESS, payload: true });

    await Client.sleep(300);

    if (!page) page = getState().equipmentPagination.page;

    let params = { limit: 10, page: page };
    let filters = getFilters(getState, "equipmentFilters");

    // Object.assign(params, filters);
    filters.filter = {
      ...filters.filter,
      ...params,
    };

    return await Client.post(`/cashbox/list`, filters)
      .then((response) => {
        let result = formCashbox(response.data);

        dispatch({ type: SET_EQUIPMENTS, payload: result });

        dispatch({
          type: SET_PAGINATION_EQUIPMENT,
          payload: response.data.result,
        });

        dispatch({ type: TOGGLE_PROGRESS, payload: false });
        return true;
      })
      .catch((e) => dispatch({ type: TOGGLE_PROGRESS, payload: false }));
  };
}

export function getLazyCashboxes(page, limit = 10) {
  return async function thunk(dispatch, getState) {
    let params = { limit: limit, page: page };
    let filters = getFilters(getState, "equipmentFilters");

    // Object.assign(params, filters);
    filters.filter.limit = params.limit;
    filters.filter.page = params.page;

    return await Client.post(`/cashbox/list`, filters)
      .then((response) => {
        response.data.result.data.forEach((i) => {
          try {
            i.name = `${i.cashboxModel.model} (${i.organization.name})`;
          } catch (err) {
            i.name = `Не определено`;
          }
        });
        return response;
      })
      .catch((e) => console.error(e));
  };
}

export function selectedEquipment(equipment) {
  return async function thunk(dispatch, getState) {
    dispatch({ type: SELECT_EQUIPMENTS, payload: { equipment: equipment } });
    dispatch({ type: SHOW_WINDOW__EQUIPMENTS, payload: true });
  };
}
export function handleShowWindow(value) {
  return async function thunk(dispatch, getState) {
    dispatch({ type: SHOW_WINDOW__EQUIPMENTS, payload: value });
  };
}

export function postOperationCashbox(data) {
  return async function thunk(dispatch, getState) {
    await Client.post(`/cashbox/shift`, data)
      .then((r) =>
        dispatch({ type: SHOW_SUCCESS, payload: "Запрос успешно отправлен" })
      )
      .catch((e) => dispatch({ type: SHOW_ERROR, payload: e.message }));
  };
}

export function postFreezeCash(data) {
  return async function thunk(dispatch, getState) {
    await Client.post(`/cashbox/freeze`, data)
      .then((r) => {
        dispatch({ type: SHOW_SUCCESS, payload: "Запрос успешно отправлен" });
        refreshStatus(r.data.result, dispatch, getState);
      })
      .catch((e) => console.error(e));
  };
}

async function refreshStatus(data, dispatch, getState) {
  const equipment = { ...getState().equipments.select };
  equipment.status = data.code;

  dispatch({ type: SELECT_EQUIPMENTS, payload: { equipment: equipment } });
}

const mapDispatchToProps = (dispatch) => {
  return {
    controller: {
      get: () => dispatch(getEquipments()),
      selected: (item) => dispatch(selectedEquipment(item)),
    },
  };
};

function formCashbox(data) {
  const licenses = data.licenses;
  let result = data.result.data;
  result.forEach((i) => {
    if (licenses) {
      i.license = licenses[i.licenseId];
    }

    try {
      i.name = `${i.model.name} №${i.factoryNumber}`;
    } catch (err) {
      i.name = `Не определенно`;
    }
  });

  return result;
}

export default mapDispatchToProps;
