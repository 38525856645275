import React from "react";
import styles from "components/static/style.module.css";

function TextResult(props) {
  const htext = props.isError ? "Извините !" : "Спасибо !";
  const ptext = props.isError
    ? "Произошла ошибка на сервере, попробуйте позже"
    : "Мы обязательно с Вами свяжемся";
  return (
    <div className={props.className + " " + styles.textResult}>
      <h3 className="p-col-12 p-p-0 p-text-center">{htext}</h3>
      <span className="p-col-12 p-p-0 p-text-center p-d-block">{ptext}</span>
    </div>
  );
}

export default TextResult;
