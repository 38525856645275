import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setProp } from "domain/actions/equipment/bid";
import { Select } from "components/forms/fields";

function Tariff(props) {
  const [updateKey, forceUpdate] = useState(1);
  const [value, setValue] = useState(null);
  const { className, item, tariffs, selected, setProp } = props;

  useEffect(() => {
    const item2 = tariffs.filter((i) => i.id === item?.tariffId);
    setValue(item2.length ? item2[0] : null);
  }, [tariffs, item.tariffId, setValue]);

  const isActive = selected ? selected.status !== 11 : false;

  return (
    <Select
      key={updateKey}
      label="Тариф"
      optionLabel="description"
      options={tariffs}
      className={className}
      value={value}
      onChange={(e) => {
        setProp("tariffId", e.id, item.id);
        forceUpdate(updateKey + 1);
      }}
      disabled={isActive}
    />
  );
}

const mapStateToProps = (state) => ({
  tariffs: state.requisites.tariffs,
  selected: state.bidList.select,
});
export default connect(mapStateToProps, { setProp })(Tariff);
