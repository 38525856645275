import {
  SETSORTS_RECEIPTS,
  SETRANGE_RECEIPTS,
  SETFILTRES_RECEIPTS,
  SHOW_FILTERS_RECEIPTS,
} from "../../../types/receipts";

const initialState = {
  sort: null,
  range: null,
  filter: null,
  show: false,
};

export default function receiptFilters(state = initialState, action) {
  switch (action.type) {
    case SETSORTS_RECEIPTS:
      return {
        ...state,
        sort: action.payload,
      };
    case SETRANGE_RECEIPTS:
      return {
        ...state,
        range: action.payload,
      };
    case SETFILTRES_RECEIPTS:
      return {
        ...state,
        filter: action.payload,
      };
    case SHOW_FILTERS_RECEIPTS:
      return {
        ...state,
        show: action.payload,
      };
    default:
      return state;
  }
}
