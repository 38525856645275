import React, { useState } from "react";
import { connect } from "react-redux";
import { setAgents } from "domain/actions/createReceipt/editItem";

import AgentsWindow from "../../../../../../../../../windows/agents.window";
import { Button } from "primereact/button";

function Agents(props) {
  const [display, setDisplay] = useState(false);

  const show = (e) => {
    e.preventDefault();
    setDisplay(true);
  };
  const close = (e) => setDisplay(false);

  return (
    <div className={props.className}>
      <AgentsWindow
        show={display}
        close={close}
        onConfirms={props.setAgents}
        agentInfo={props.info}
      />
      <Button label="Агент" onClick={show} className="c-btns--gradient" />
    </div>
  );
}

const mapStateToProps = (state) => ({
  info: state.createReceiptEdit.agents,
});
export default connect(mapStateToProps, { setAgents })(Agents);
