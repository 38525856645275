import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getBidByFilter } from "domain/actions/equipment/bid";

import Head from "./header";
import Body from "./body";

import Pagination from "components/static/pagination";
import { SpinnerWrapper } from "components/wrappers";

function BidTable(props) {
  const [page, setPage] = useState(1);
  const getBidByFilter = props.getBidByFilter;

  useEffect(() => getBidByFilter(page), [getBidByFilter, page, props.refresh]);

  return (
    <div className={props.className}>
      <Pagination
        callback={(page) => setPage(page)}
        pagination={props.pagination}
      />

      <Head className="p-col-12 p-pl-0 p-pt-0 p-d-none p-d-md-flex c-theader-shadow" />

      <SpinnerWrapper className="c-pagination-table">
        <Body className="p-col-12 p-p-1" />
      </SpinnerWrapper>

      <Pagination
        callback={(page) => setPage(page)}
        pagination={props.pagination}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  refresh: state.bidList.refresh,
  pagination: state.bidPagination,
});
export default connect(mapStateToProps, { getBidByFilter })(BidTable);
