import React from "react";
import { Controller } from "react-hook-form";

import { MultiSelect } from "primereact/multiselect";

import styles from "../styles.module.css";

function AgentComponent({
  control,
  className,
  title = true,
  requisite,
  defaultValue = [],
}) {
  return (
    <div className={`${styles.form} ${className}`}>
      <div className="p-p-0">
        {title && <span className={`${styles.blocktitle}`}>Данные агента</span>}

        <div className="p-col-12 p-d-flex p-flex-column">
          <label htmlFor="type-receipt" className={`${styles.font} p-pb-1`}>
            Тип агента
          </label>

          <Controller
            name="agentInfo.agents"
            control={control}
            render={(props) => (
              <MultiSelect
                display="chip"
                optionLabel="name"
                value={props.value}
                options={requisite ? requisite.agentType : []}
                onChange={(e) => props.onChange(e.value)}
                className={`${styles.borderBottom} ${styles.inputOpacity1}`}
                selectedItemsLabel="Выбрано {0} элементов"
              />
            )}
            defaultValue={defaultValue}
          />
        </div>
      </div>
    </div>
  );
}

export default AgentComponent;
