import {
  SET_SUPPLIER_EDIT_CREATERECEIPT,
  SET_AGENTS_EDIT_CREATERECEIPT,
  SET_CODES_EDIT_CREATERECEIPT,
} from "../../../types/createReceipt";
import { SELECTED_ITEMS_CREATERECEIPTS } from "../../../actionTypes";

const initialState = {
  item: null,
  supplier: null,
  agents: null,
  codes: null,
};

export default function EditItemReceiptReducer(state = initialState, action) {
  switch (action.type) {
    case SELECTED_ITEMS_CREATERECEIPTS:
      return {
        ...state,
        item: action.payload,
      };
    case SET_SUPPLIER_EDIT_CREATERECEIPT:
      return {
        ...state,
        supplier: action.payload,
      };
    case SET_AGENTS_EDIT_CREATERECEIPT:
      return {
        ...state,
        agents: action.payload,
      };
    case SET_CODES_EDIT_CREATERECEIPT:
      return {
        ...state,
        codes: action.payload,
      };
    default:
      return state;
  }
}
