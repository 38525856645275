import React from "react";
import { connect } from "react-redux";
import ConfirmBtn from "./confirmBtn";
import CancelButton from "components/btns/cancelBtn";
import { handleShowWindow } from "domain/actions/users";

function Footer(props) {
  const { handleShowWindow, rights } = props;

  return (
    <div className={props.className}>
      <CancelButton
        onShow={() => handleShowWindow(false)}
        className="p-col-12 p-md-6 p-xl-4"
      />
      {rights.includes("USERS_UPDATE") && (
        <ConfirmBtn
          handleSubmit={props.handleSubmit}
          className="p-col-12 p-md-6 p-xl-4"
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  rights: state.users.rights,
});
export default connect(mapStateToProps, { handleShowWindow })(Footer);
