import React from "react";
import { connect } from "react-redux";
import { handleShowAcquiring } from "domain/actions/organizations/acquiring";

import Window from "components/windows";
import ContentAdd from "./add";
import ContentEdit from "./edit";

function WindowAcquiringLocal(props) {
  const { acquiring = null, refresh } = props;
  const close = props.handleShowAcquiring;
  let title = acquiring ? "Информация по эквайрингу" : "Добавление эквайринга";

  return (
    <Window
      className={props.className}
      header={title}
      visible={props.show}
      onHide={close}
      style={{ minWidth: "340px" }}
      draggable={false}
    >
      {acquiring ? (
        <ContentEdit
          className="p-col-12 p-d-flex p-flex-wrap"
          acquiringLocal={acquiring}
          refresh={refresh}
        />
      ) : (
        <ContentAdd
          className="p-col-12 p-d-flex p-flex-wrap"
          refresh={refresh}
        />
      )}
    </Window>
  );
}

const mapStateToProps = (state) => ({
  show: state.orgCreate.acquiringLocalShow,
});
export default connect(mapStateToProps, { handleShowAcquiring })(
  WindowAcquiringLocal
);
