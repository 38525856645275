import React, { useState } from "react";
import { TextField } from "components/forms/fields";
import { Button } from "primereact/button";
import { connect } from "react-redux";
import { confirmTokenCode } from "domain/actions/settings";
import { isAuth } from "domain/actions/auth.actions";

function Content(props) {
  const [code, setCode] = useState("");

  const onConfirm = (code) => {
    props
      .confirmTokenCode(code)
      .then((r) => {
        props.isAuth().then(() => props.setCloseWindow(false));
      })
      .catch((e) => console.error(e));
  };

  return (
    <div className={props.className}>
      <p className="p-pr-2 p-pl-2 p-d-inline-block">
        На указанный <u>в анкете номер телефона</u> был отправлен код для
        подтверждения генерации нового статического токена
      </p>

      <TextField
        className="p-col-12"
        label="Код подтверждения"
        placeholder="Введите код из смс..."
        value={code}
        onChange={setCode}
      />

      <div className="p-d-flex p-justify-between p-pt-3">
        <Button
          label="Отменить"
          icon="pi pi-times"
          onClick={() => props.setCloseWindow(false)}
          className="p-button-text"
        />
        <Button
          type="submit"
          label="Применить"
          icon="pi pi-check"
          onClick={() => onConfirm(code)}
        />
      </div>
    </div>
  );
}

export default connect(null, { confirmTokenCode, isAuth })(Content);
