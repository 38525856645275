import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { showError } from "domain/actions/error.actions";

import {
  postConnect,
  postConnectFiles,
  selectOrg,
} from "domain/actions/organizations";

import { TextField, ColorField } from "components/forms/fields";
import SingleUploadImage from "components/forms/upload";
import { Select } from "components/forms/fields";
import { Button } from "primereact/button";
import { SpinnerWrapper } from "components/wrappers";

const styleLabel = {
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: "700",
  fontSize: "14px",
  color: "#000",
};

function ContentAdd(props) {
  const [isLoading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [sum, setSum] = useState("");
  const [acqType, setAcquiring] = useState(null);
  const [cashbox, setCashbox] = useState(null);
  const [name, setName] = useState("");
  const [color, setColor] = useState("");
  const [primary, setPrimary] = useState(null);
  const [background, setBackground] = useState(null);
  const {
    org,
    showError,
    selectOrg,
    postConnect,
    postConnectFiles,
    handleShowConnect,
  } = props;

  let refPrimary = useRef(null);
  let refBackground = useRef(null);

  const confirm = () => {
    if (!name) return showError('Поле "Наименование" не заполнено');
    // if (!title) return showError('Поле "Описание" не заполнено');
    // if (!acqType) return showError('Поле "Тип платежной системы" не заполнено');
    // if (!cashbox) return showError('Поле "Касса" не заполнено');
    // if (!color) return showError('Поле "Цвет текста" не заполнено');
    // if (!primary) return showError('Поле "Основной фон" не заполнен');
    // if (!background) return showError('Поле "Задний фон" не заполнен');

    const data = {
      name,
      title,
      acqType,
      cashbox,
      color,
      defaultPaymentSum: sum,
    };

    setLoading(true);
    postConnect(data)
      .then((r) => {
        const images = {};
        if (primary) images.primary = primary;
        if (background) images.background = background;

        return postConnectFiles({ id: r.data.acceptId, ...images });
      })
      .then((r) => {
        return selectOrg(org.id);
      })
      .then((r) => {
        setLoading(false);
        handleShowConnect(false);
      })
      .catch(() => setLoading(false));
  };

  return (
    <SpinnerWrapper
      position="center"
      progress={isLoading}
      classNameSpinner="custom-spinner-connect"
    >
      <div className={props.className}>
        <TextField
          className="p-col-12"
          label="Наименование"
          value={name}
          onChange={setName}
          reqStar
        />

        <div className="p-col-12">
          <Select
            className="p-col-12 p-p-0"
            options={org ? org.organizationAcquiring : []}
            label="Тип платежной системы(по умолчанию)"
            optionLabel="acquiring.name"
            value={acqType}
            onChange={setAcquiring}
            // required
          />
        </div>

        <div className="p-col-12">
          <Select
            className="p-col-12 p-p-0"
            options={org ? org.cashbox : []}
            label="Касса(по умолчанию)"
            value={cashbox}
            onChange={setCashbox}
            // required
          />
        </div>

        <TextField
          className="p-col-12"
          label="Описание"
          value={title}
          onChange={setTitle}
          // reqStar
        />

        <TextField
          className="p-col-12"
          label="Сумма(по умолчанию)"
          value={sum}
          onChange={setSum}
          // reqStar
        />

        <ColorField
          className="p-col-12"
          label="Цвет текста"
          value={color}
          onChange={setColor}
          // reqStar
        />

        <div className="p-col-12 p-d-flex p-flex-wrap p-p-0">
          <div className="p-col-12 p-md-6">
            <label className={` p-pb-1`} style={styleLabel}>
              Основное изображение: <span style={{ color: "red" }}></span>
            </label>
            <SingleUploadImage
              ref={refPrimary}
              label={"Загрузить фотографию"}
              file={primary}
              onSelectFIle={setPrimary}
            />
          </div>

          <div className="p-col-12 p-md-6">
            <label className={`p-pb-1`} style={styleLabel}>
              Фоновое изображение: <span style={{ color: "red" }}></span>
            </label>
            <SingleUploadImage
              ref={refBackground}
              label={"Загрузить фотографию"}
              file={background}
              onSelectFIle={setBackground}
            />
          </div>
        </div>

        <div className="p-col-12 p-d-flex p-flex-wrap p-jc-end p-p-0">
          <Button
            label="Применить"
            className="с-button-success p-col-12 p-md-6"
            onClick={confirm}
          />
        </div>
      </div>
    </SpinnerWrapper>
  );
}

const mapStateToProps = (state) => ({
  org: state.orgCreate.select,
});
export default connect(mapStateToProps, {
  showError,
  postConnect,
  postConnectFiles,
  selectOrg,
})(ContentAdd);
