import React from "react";
import Line from "components/Line";

const style = {
  fontStyle: "normal",
  fontWeight: "bold",
  fontSize: "18px",
  lineHeight: "21px",
  margin: ".5rem 0",
};

function IconAndText({ className = "", iconName = "robot", text = "" }) {
  let icon = (
    <img
      src={process.env.PUBLIC_URL + "/img/icons/" + iconName + ".png"}
      alt={iconName}
      style={{ paddingTop: ".5rem", width: "50px", height: "55px" }}
    />
  );

  return (
    <div className={className}>
      <div className="p-d-flex p-jc-center p-ai-md-end">{icon}</div>

      <div className=" p-p-2" style={{ width: "100%" }}>
        <p style={style}>{text}</p>
        <Line height={3} />
      </div>
    </div>
  );
}

export default IconAndText;
