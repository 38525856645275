import React from "react";

import styles from "../styles.module.css";

function AuthButtonComponent({ handleAuth }) {
  return (
    <div className={styles.auth_block}>
      <div className="p-col-12 p-d-flex p-jc-center p-ai-center">
        <img
          src={process.env.PUBLIC_URL + "/img/logo512.png"}
          className={styles.logo_vdcloud}
          alt="logo"
        />
        <span className={styles.logo_name}>ВТИ-Сервис</span>
      </div>
      <div className={styles.button} onClick={handleAuth}>
        Авторизоваться
      </div>
    </div>
  );
}

export default AuthButtonComponent;
