import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  setPropAcquiring,
  setPropAcquiringEdit,
} from "domain/actions/organizations/acquiring";
import { TextAreaField } from "components/forms/fields";

function Comment(props) {
  const [value, setValue] = useState(props.value);
  const [updateKey, forceUpdate] = useState(1);

  const setProp = !props.select
    ? props.setPropAcquiring
    : props.setPropAcquiringEdit;

  useEffect(() => {
    if (props.select) return setValue(props.select.comment);
    setValue(props.value);
  }, [props.value, props.select]);

  return (
    <TextAreaField
      key={updateKey}
      label="Комментарий"
      className={props.className}
      value={value ? value : ""}
      onChange={(e) => {
        if (e.length < 512) setValue(e);
      }}
      onBlur={(e) => {
        setProp("comment", e.target.value);
        forceUpdate(updateKey + 1);
      }}
      autoResize
      params={{ rows: 5 }}
      placeholder="Напишите комментарий..."
    />
  );
}

const mapStateToProps = (state) => ({
  value: state.orgAcquiring.acquiring.comment,
  select: state.orgAcquiring.select,
});
export default connect(mapStateToProps, {
  setPropAcquiring,
  setPropAcquiringEdit,
})(Comment);
