import React from "react";
import { Button } from "primereact/button";

function ConfirmBtn(props) {
  const confirm = () => props.confirm();

  return (
    <div className={props.className}>
      <Button label="Да" className="с-button-success" onClick={confirm} />
    </div>
  );
}

export default ConfirmBtn;
