export const SORT_ORGANIZATION = "SORT_ORGANIZATION";
export const FILTER_ORGANIZATION = "FILTER_ORGANIZATION";
export const RANGE_ORGANIZATION = "RANGE_ORGANIZATION";
export const SET_LIKE_ORGANIZATION = "SET_LIKE_ORGANIZATION";
export const REFRESH_ORGANIZATION = "REFRESH_ORGANIZATION";

export const SET_ORGANIZATIONS = "SET_ORGANIZATIONS";
export const UPDATE_ORGANIZATIONS = "UPDATE_ORGANIZATIONS";
export const SELECT_ORGANIZATIONS = "SELECT_ORGANIZATIONS";
export const INFO_ORGANIZATION = "INFO_ORGANIZATION";
export const BREAK_ORGANIZATIONS = "BREAK_ORGANIZATIONS";
export const LOGOUT_AUTH = "LOGOUT_AUTH";
export const SHOW_ERROR = "SHOW_ERROR";

export const SET_PAGINATION_ORGANIZATION = "SET_PAGINATION_ORGANIZATION";

export const SHOW_WINDOW__ORGANIZATIONS = "SHOW_WINDOW__ORGANIZATIONS";
export const SET_ACQUIRING_ORGANIZATION = "SET_ACQUIRING_ORGANIZATION";
export const SET_SHOW_ACQUIRING_ORGANIZATION =
  "SET_SHOW_ACQUIRING_ORGANIZATION";
export const SET_SHOW_ACQUIRING_LOCAL_ORGANIZATION =
  "SET_SHOW_ACQUIRING_LOCAL_ORGANIZATION";
export const SET_SHOW_ACQUIRING_BIND_CASHBOX =
  "SET_SHOW_ACQUIRING_BIND_CASHBOX";
export const DELETE_ACQUIRING_ORGANIZATION = "DELETE_ACQUIRING_ORGANIZATION";
export const SET_SHOW_CONNECT_ORGANIZATION = "SET_SHOW_CONNECT_ORGANIZATION";
export const SET_SHOW_CONNECT_EDIT_ORGANIZATION =
  "SET_SHOW_CONNECT_EDIT_ORGANIZATION";

export const SET_PROPS_CREATE_ORGANIZATION = "SET_PROPS_CREATE_ORGANIZATION";
export const SET_PROPS_EDIT_ORGANIZATION = "SET_PROPS_EDIT_ORGANIZATION";
export const CLEAR_CREATE_ORGANIZATION = "CLEAR_CREATE_ORGANIZATION";
export const CHANGE_VALIDATE_ORGANIZATION = "CHANGE_VALIDATE_ORGANIZATION";
export const ISVALIDATE_CREATE_ORGANIZATION = "ISVALIDATE_CREATE_ORGANIZATION";

export const SET_WORKERS_ORGANIZATION = "SET_WORKERS_ORGANIZATION";
export const SET_WORKERS_SELECTED_ORGANIZATION =
  "SET_WORKERS_SELECTED_ORGANIZATION";

export const SET_PROPS_ACQUIRING_ORGANIZATION =
  "SET_PROPS_ACQUIRING_ORGANIZATION";
export const SET_PROPS_ACQUIRING_EDIT_ORGANIZATION =
  "SET_PROPS_ACQUIRING_EDIT_ORGANIZATION";
export const SET_PROPS_SELECT_ACQUIRING_ORGANIZATION =
  "SET_PROPS_SELECT_ACQUIRING_ORGANIZATION";
export const CHANGE_VALIDATE_ACQUIRING_ORGANIZATION =
  "CHANGE_VALIDATE_ACQUIRING_ORGANIZATION";
export const SELECT_ACQUIRING_ORGANIZATIONS = "SELECT_ACQUIRING_ORGANIZATIONS";
export const ISVALIDATE_ACQUIRING_ORGANIZATION =
  "ISVALIDATE_ACQUIRING_ORGANIZATION";
export const CLEAR_ACQUIRING_ORGANIZATION = "CLEAR_ACQUIRING_ORGANIZATION";
export const SET_ORGANIZATIONS_CASHIERS = "SET_ORGANIZATIONS_CASHIERS";
