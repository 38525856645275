import React from "react";
import { Name, Ogrnip, Inn } from "../../../../components/fields";

function BasicIndividual(props) {
  return (
    <div className={props.className}>
      <span className="block-label-title p-pb-4 title-silver p-col-12">
        Основные сведения Индивидуального предпринимателя
      </span>

      <Name className="p-col-12" />
      <Inn className="p-col-12 p-md-6" lengthInn={12} />
      <Ogrnip className="p-col-12 p-md-6" />
    </div>
  );
}

export default BasicIndividual;
