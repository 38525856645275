import {
  SET_REQUISITES,
  SET_TRANSLATE_ROLES_REQUISITES,
  SET_REQUISITES_TARIFFS,
} from "../actionTypes";
import {
  ApiController,
  AMSController,
  BillingController,
} from "domain/controllers";

const Client = new ApiController();
const AMS = new AMSController();
const Billing = new BillingController();

export function getAllRequisites() {
  return async function thunk(dispatch, getState) {
    // await Client.sleep(500);

    return await Client.get("/info/pay")
      .then((response) =>
        dispatch({
          type: SET_REQUISITES,
          payload: response.data.result,
        })
      )
      .catch((e) => console.log(e));
  };
}

export function getTariffs(local) {
  return async function thunk(dispatch, getState) {
    const isLocal = local.id === 1;
    return await Billing.get(`/tariffs?isLocal=${isLocal}`)
      .then((response) =>
        dispatch({
          type: SET_REQUISITES_TARIFFS,
          payload: response.data.data,
        })
      )
      .catch((e) => console.log(e));
  };
}

export function getRolesTranslateAMS() {
  return async function thunk(dispatch, getState) {
    return await AMS.get("/roles/translates")
      .then((response) =>
        dispatch({
          type: SET_TRANSLATE_ROLES_REQUISITES,
          payload: response.data,
        })
      )
      .catch((e) => console.log(e));
  };
}
