import moment from "moment";

class ColumnTypeToString {
  toDate(date) {
    if (!date) return this.toNull();

    return moment(date).format("YYYY.MM.DD HH:mm");
  }
  toDateColor(date) {
    if (!date) return this.toNull();

    const current = moment();
    const dt = moment(date);
    if (dt < moment(date))
      return (
        <span className="text-color-danger">
          {dt.format("YYYY.MM.DD HH:mm")}
        </span>
      );

    if (dt < current.add(7, "days"))
      return (
        <span className="text-color-warning">
          {dt.format("YYYY.MM.DD HH:mm")}
        </span>
      );

    return (
      <span className="text-color-success">
        {dt.format("YYYY.MM.DD HH:mm")}
      </span>
    );
  }
  toPhone(phone) {
    if (!phone) return this.toNull();

    return phone.replace(/(\d)(\d\d\d)(\d\d\d)(\d\d)(\d\d)/, "+$1($2)$3-$4-$5");
  }
  toStatusReceipt(status, trouble) {
    if (!status && status < 0) return this.toNull();
    // console.log(trouble)
    switch (status) {
      case 0:
        return <span className="text-color-success">Распечатан</span>;
      case 1:
        return (
          <span className="text-color-danger">
            Отказано: {trouble ? trouble?.text : "Произошла ошибка"}
          </span>
        );
      case 11:
        return <span className="text-color-warning">В ожидании</span>;
      case 10:
        return <span className="text-color-warning">Отправлено на печать</span>;
      case 100:
        return (
          <span className="text-color-danger">Произошла ошибка на сервере</span>
        );
      case 101:
        return <span className="text-color-danger">Нет ответа от Worker</span>;
      case 111:
        return (
          <span className="text-color-danger">
            Произошла ошибка на сервере. Чек распечатан.
          </span>
        );
      case 211:
        return <span className="text-color-warning">Ожидание оплаты</span>;
      case 202:
        return <span className="text-color-danger">Время оплаты истекло</span>;
      case 201:
        return (
          <span className="text-color-warning">
            Отказано в оплате квитанции
          </span>
        );
      default:
        return <span className="text-color-danger">Статус не определен</span>;
    }
  }
  toStatusPayments(status, trouble) {
    if (!status && status < 0) return this.toNull();
    // console.log(trouble)
    switch (status) {
      case 0:
        return <span className="text-color-success">Успешно оплачен</span>;
      case 1:
        return (
          <span className="text-color-danger">
            Ошибка: {trouble ? trouble?.text : "Произошла ошибка"}
          </span>
        );
      case 11:
        return <span className="text-color-warning">Новый</span>;
      case 211:
        return <span className="text-color-warning">Ожидание оплаты</span>;
      case 202:
        return <span className="text-color-danger">Время оплаты истекло</span>;
      case 203:
        return <span className="text-color-danger">Отменен</span>;
      case 201:
        return <span className="text-color-warning">Ошибка при оплате</span>;
      default:
        return <span className="text-color-danger">Статус не определен</span>;
    }
  }
  toStatusPaid(status) {
    if (!status && status < 0) return this.toNull();

    switch (status) {
      case 0:
        return <span className="text-color-success">Успешная оплата</span>;
      case 1:
        return <span className="text-color-danger">Оплата не удалась</span>;
      case 211:
        return <span className="text-color-warning">Ожидание оплаты</span>;
      case 202:
        return <span className="text-color-danger">Время оплаты истекло</span>;
      case 201:
        return (
          <span className="text-color-warning">
            Отказано в оплате квитанции
          </span>
        );
      default:
        return <span className="">-</span>;
    }
  }
  toStatusClass(status) {
    switch (status) {
      case 0:
        return "text-color-success";
      case 1:
        return "text-color-danger";
      case 11:
        return "text-color-warning";
      case 10:
        return "text-color-warning";
      case 100:
        return "text-color-danger";
      case 101:
        return "text-color-danger";
      case 111:
        return "text-color-danger";
      case 211:
        return "text-color-warning";
      case 202:
        return "text-color-danger";
      case 201:
        return "text-color-warning";
      default:
        return "text-color-danger";
    }
  }
  toFreeze(isFreeze) {
    const result = (
      <i
        className="pi pi-circle-on"
        style={{ fontSize: ".75rem", color: "#c1c1c1" }}
      ></i>
    );

    return <div className="p-d-flex p-jc-center">{result}</div>;
  }
  toStatusEquipment(status) {
    const color =
      status === 2 ? "#c1c1c1" : status === 1 ? "#fb8d05" : "#4CAF50";

    const result = (
      <i
        className="pi pi-circle-on"
        style={{ fontSize: ".75rem", color: color }}
      ></i>
    );

    return <div className="p-d-flex p-jc-center">{result}</div>;
  }

  toActive(status) {
    const color = status ? "#4CAF50" : "#c1c1c1";

    const result = (
      <i
        className="pi pi-circle-on"
        style={{ fontSize: ".75rem", color: color }}
      ></i>
    );

    return <div className="p-d-flex p-jc-center">{result}</div>;
  }

  toActiveConnect(status, bindParams) {
    const color = status ? "#4CAF50" : "#c1c1c1";

    const result = (
      <i
        className="pi pi-circle-on"
        style={{ fontSize: ".75rem", color: color }}
      ></i>
    );

    const connect =
      bindParams === null ? (
        <span></span>
      ) : bindParams?.clientId === undefined ? (
        <span className="p-ml-2">(клиент не подвязан)</span>
      ) : (
        <span></span>
      );

    return (
      <div className="p-d-flex p-jc-center p-flex-column">
        {result} {connect}
      </div>
    );
  }

  toStatusBid(status) {
    if (!status && status < 0) return this.toNull();

    switch (status) {
      case 0:
        return <span className="text-color-success">Исполнена</span>;
      case 1:
        return <span className="text-color-danger">Отказано</span>;
      case 11:
        return <span className="text-color-warning">Новая</span>;
      case 10:
        return <span className="text-color-warning">Принята</span>;
      case 8:
        return <span className="text-color-warning">Выполняется</span>;
      case 9:
        return <span className="text-color-warning">Ожидает оплаты</span>;
      default:
        return <span className="text-color-danger">Статус не определен</span>;
    }
  }
  toLvlRoot(arrayRole) {
    const role = arrayRole.length > 0 ? arrayRole[0] : null;
    if (!role) return this.toNull();

    switch (role.roleKey) {
      case "ACCOUNT_OWNER":
        return <span>Владелец</span>;
      case "ACCOUNT_ADMIN":
        return <span>Администратор</span>;
      case "ACCOUNT_MEMBER":
        return <span>Пользователь</span>;
      default:
        return <span>Не указан</span>;
    }
  }

  toNull(value) {
    return value ? value : " - ";
  }
}

export default ColumnTypeToString;
