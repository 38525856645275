import React, { useEffect, useRef, useCallback } from "react";
import { connect } from "react-redux";

import { clearSuccess } from "../domain/actions/error.actions";

import { Toast } from "primereact/toast";

function ToastSuccess({ show, message, clearSuccess }) {
  const toast = useRef(null);

  const showToast = useCallback(() => {
    toast.current.show({
      severity: "success",
      summary: "Сообщение об успехе",
      content: (
        <div className="p-col-12 p-d-flex p-flex-column">
          <b>Сообщение об успехе</b>
          {message}
        </div>
      ),
      life: 3000,
    });

    setTimeout(clearSuccess, 1000);
  }, [toast, message, clearSuccess]);

  useEffect(() => {
    if (show) return showToast();
  }, [show, showToast]);

  return <Toast ref={toast}></Toast>;
}

const mapStateToProps = (state) => ({
  show: state.errors.success,
  message: state.errors.messageSuccess,
});

export default connect(mapStateToProps, { clearSuccess })(ToastSuccess);
