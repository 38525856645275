import {
  SET_USERS,
  POST_USERS,
  SELECT_USERS,
  BREAK_USERS,
  REFRESH_USERS,
  SET_CURRENT_USERS,
  SHOW_WINDOW_USERS,
  SET_ROLES_USER,
  SET_RIGHTS,
  RESPONSE_ERROR_USER,
} from "domain/types/users";

const initialState = {
  users: [],
  select: null,
  current: null,
  refresh: false,
  break: false,
  show: false,
  responseError: null,
  roles: [],
  rights: [],
};

export default function usersReducer(state = initialState, action) {
  switch (action.type) {
    case SET_USERS:
      return {
        ...state,
        users: action.payload,
        break: true,
      };
    case POST_USERS:
      let { user } = action.payload;
      return {
        ...state,
        users: [...state.users, user],
      };
    case SET_CURRENT_USERS:
      return {
        ...state,
        current: action.payload,
      };
    case SELECT_USERS:
      return {
        ...state,
        select: action.payload.user,
      };
    case BREAK_USERS:
      return {
        ...state,
        break: action.payload,
      };
    case REFRESH_USERS:
      return {
        ...state,
        refresh: action.payload,
      };
    case SHOW_WINDOW_USERS:
      return {
        ...state,
        show: action.payload,
      };
    case SET_ROLES_USER:
      return {
        ...state,
        roles: action.payload,
      };
    case SET_RIGHTS:
      return {
        ...state,
        rights: action.payload,
      };
    case RESPONSE_ERROR_USER:
      return {
        ...state,
        responseError: action.payload,
      };
    default:
      return state;
  }
}
