import React from "react";
import { connect } from "react-redux";
import Cashbox from "./item";

function Cashboxes(props) {
  let renderItems = props.cashbox.map((item, index) => {
    return (
      <Cashbox
        key={index}
        item={item}
        className="p-col-12 p-p-0 p-mt-2 p-mb-3 p-d-flex p-flex-wrap c-box-shadow"
      />
    );
  });

  return <div className={props.className}>{renderItems}</div>;
}

const mapStateToProps = (state) => ({
  isLocal: state.bidEquipments.isLocal,
  cashbox: state.bidEquipmentsCashbox.list,
});
export default connect(mapStateToProps)(Cashboxes);
