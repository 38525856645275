import React from "react";

import { Button } from "primereact/button";
import { connect } from "react-redux";

function FreezeBtn(props) {
  const isFreeze = +props.item.status === 2;

  return (
    <div className={props.className}>
      <Button
        label={isFreeze ? "Разблокировать кассу" : "Заблокировать кассу"}
        onClick={props.onClick}
        className="c-btns--gradient c-btns"
      />
    </div>
  );
}
const mapStateToProps = (state) => ({
  item: state.equipments.select,
});
export default connect(mapStateToProps)(FreezeBtn);
