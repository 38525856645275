import React from "react";
import { EmailFieldControl } from "components/forms/fields";

function EmailField(props) {
  return (
    <EmailFieldControl
      className="p-p-2"
      name="email"
      control={props.formHook.control}
      errors={props.formHook.errors}
      label="E-mail:"
      required={true}
    />
  );
}

export default EmailField;
