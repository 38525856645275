import React from "react";
import { Label } from "components/based";

import styles from "../../../../style.module.css";

function ResultSum(props) {
  const result = (
    <div
      className={`${styles.resultSum} c-color-green p-col-6 p-p-0 p-text-right`}
    >
      {" "}
      ={props.sumDoc}
    </div>
  );

  return (
    <div className="p-col-12">
      <div className="p-d-flex p-jc-between p-col-12 p-p-0">
        <div className="p-col-6 p-p-0">
          <Label value="ИТОГ" className={styles.resultSumBorder} />
        </div>

        {result}
      </div>
    </div>
  );
}

export default ResultSum;
