import React, { useState } from "react";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import InfiniteLoader from "react-window-infinite-loader";

const mergeObjects = (object, key, value) => {
  let obj = {};
  obj[key] = value;
  return Object.assign(object, obj);
};

function LargeListWrapper(props) {
  const [itemCount, setItemCount] = useState(12);
  let [items] = useState({});
  const [pageCash, setPageCash] = useState({});
  const [requestCash, setRequestCash] = useState({});
  const [itemCountCash] = useState({});
  const [emptyData, setEmptyData] = useState(true);
  const {
    getDataFromServer,
    emptyText = null,
    setDataFromServer,
    // newItem,
  } = props;

  const isItemLoaded = (index) => !!items[index];

  const isEmpty = (data) => {
    setEmptyData(!data.length);
  };

  const loadMoreItems = async (visibleStartIndex, visibleStopIndex) => {
    const key = [visibleStartIndex, visibleStopIndex].join(":");

    if (requestCash[key]) return;
    setRequestCash(mergeObjects(requestCash, key, key));

    let limit = props.limit ? props.limit : 10;
    let page = +(visibleStartIndex / limit + 1).toFixed(0);

    if (pageCash[page]) return;
    setPageCash(mergeObjects(pageCash, page, page));

    const response = await getDataFromServer(page, limit).catch((e) =>
      console.error(e)
    );

    if (!response) return;

    if (itemCountCash[1]) {
      setItemCount(itemCountCash[1]);
    } else {
      setItemCount(response.data.length + 1);
    }

    mergeObjects(itemCountCash, 1, response.total);

    isEmpty(response.data);

    response.data.forEach((item, index) => {
      mergeObjects(items, index + visibleStartIndex, item);
    });

    if (setDataFromServer) setDataFromServer(items);
  };

  // useEffect( () => {
  //   if(!newItem) return;
  //   items = { 0: newItem, ...items}
  //   console.log(items)
  // }, [newItem] )

  const Row = ({ index, style }) => {
    const item = items[index];
    return (
      <div style={style} key={index} className="large-list-row--cashbox">
        {item ? props.renderRow(item, index) : ""}
      </div>
    );
  };

  return (
    <div className={props.className} style={props.style}>
      {emptyData ? (emptyText ? emptyText : "Нет данных") : ""}
      <AutoSizer>
        {({ height, width }) => (
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            loadMoreItems={loadMoreItems}
            itemCount={itemCount}
          >
            {({ onItemsRendered, ref }) => (
              <List
                height={height}
                itemCount={itemCount}
                itemSize={props.itemSize}
                width={width}
                ref={ref}
                onItemsRendered={onItemsRendered}
              >
                {Row}
              </List>
            )}
          </InfiniteLoader>
        )}
      </AutoSizer>
    </div>
  );
}

export default LargeListWrapper;
