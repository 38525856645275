import React from "react";
import SortsPanels from "./sorts";
import Like from "./like";

function SettingsPanel(props) {
  return (
    <div className={props.className}>
      <SortsPanels className="p-flex-wrap p-col-12 p-d-flex p-jc-start p-jc-md-end" />
      <Like className="p-col-12" />
    </div>
  );
}

export default SettingsPanel;
