import React from "react";

import { Heading } from "components/static";
import DateFilter from "./dateFilter";

function Header(props) {
  return (
    <div className="p-col-12">
      <Heading className="p-col-12 p-pl-3 p-pt-3" title="Аналитика" />
      <DateFilter className="p-col-12 p-d-flex p-flex-wrap p-jc-end" />
    </div>
  );
}

export default Header;
