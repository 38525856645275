import React from "react";
import { connect } from "react-redux";
import { Menu } from "components/static";

import { getTemplates } from "domain/actions/receiptTemplates.actions";

import RefreshIcon from "@material-ui/icons/Refresh";

function MenuComponent(props) {
  const listMenu = [
    {
      id: "2",
      name: "Обновить страницу",
      icon: RefreshIcon,
      callback: () => props.getTemplates(props.pageIndex),
    },
  ];

  return <Menu list={listMenu} className={props.className} />;
}

const mapStateToProps = (state) => ({
  pageIndex: state.receiptTemplates.pagination.page,
});

export default connect(mapStateToProps, { getTemplates })(MenuComponent);
