import React from "react";
import { connect } from "react-redux";
import { handleShowWindow } from "domain/actions/organizations.actions";

import Window from "components/windows";
import Content from "./layouts";

import styles from "../../style.module.css";

function WindowOrg(props) {
  const close = props.handleShowWindow;
  let title = props.select
    ? "Просмотр / редактирование организации"
    : "Добавление организации";

  return (
    <Window
      className={`${props.className} ${styles.window} ${
        props.activeSidebar
          ? "window-sidebar-active"
          : "window-sidebar-inActive"
      }`}
      header={title}
      visible={props.show}
      onHide={close}
      position="right"
      modal={false}
      draggable={false}
    >
      <Content className="p-col-12 c-body-full" />
    </Window>
  );
}

const mapStateToProps = (state) => ({
  show: state.organizations.show,
  activeSidebar: state.sidebar.show,
  select: state.orgCreate.select,
});
export default connect(mapStateToProps, { handleShowWindow })(WindowOrg);
