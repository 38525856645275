import React from "react";
import { InputText } from "primereact/inputtext";
import { Label } from "components/based";

function TextFieldComponent(props) {
  return (
    <div className={props.className}>
      <Label className="p-d-block p-d-xl-none" value={props.label} />
      <InputText
        value={props.value}
        className="c-border-bottom"
        onChange={(e) => props.onChange(props.name, e.target.value)}
        disabled={props.disabled}
        tooltip={props.value}
        tooltipOptions={{ position: "bottom" }}
      />
    </div>
  );
}

export default TextFieldComponent;
