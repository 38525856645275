import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { setSortsOrgs } from "domain/actions/organizations/filters";
import { Sorts } from "components/static";
import SortComponent from "components/static/sorts/components/Sort.component";

function SortsPanels({ setSortsOrgs, className }) {
  const [date, toggleDate] = useState(null);
  const [name, toggleName] = useState(true);

  useEffect(() => {
    let params = {
      createdAt: date ? "ASC" : "DESC",
      name: name ? "ASC" : "DESC",
    };
    if (date === null) delete params.createdAt;
    if (name === null) delete params.name;

    setSortsOrgs(params);
  }, [date, name, setSortsOrgs]);

  return (
    <Sorts className={className}>
      <div className="p-d-flex">
        <SortComponent
          label="Дата"
          value={date}
          onChange={(value) => {
            toggleName(null);
            toggleDate(value);
          }}
        />
        <SortComponent
          label="Наименование"
          value={name}
          onChange={(value) => {
            toggleDate(null);
            toggleName(value);
          }}
        />
      </div>
    </Sorts>
  );
}

export default connect(null, { setSortsOrgs })(SortsPanels);
