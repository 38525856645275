import React, { useState } from "react";
import { connect } from "react-redux";

import Label from "components/based/label";
import { TextField } from "components/forms/fields";
import { setProp } from "domain/actions/equipment/bid";

import styles from "../../style.module.css";

function PlaceTrade(props) {
  const [value, setValue] = useState(props.item.tradePlace || "");
  const [updateKey, forceUpdate] = useState(1);
  const isActive = props.selected ? props.selected.status !== 11 : false;

  return (
    <div className={props.className}>
      <Label
        value="Укажите, торговую площадку"
        className={`${styles.hLabel} p-col-12 p-pr-2 p-pl-0 p-d-none`}
      />
      <TextField
        label="Торговая площадка"
        className="p-col-12 p-p-0"
        value={value}
        onChange={(e) => setValue(e)}
        onBlur={(e) => {
          props.setProp("tradePlace", e.target.value, props.item.id);
          forceUpdate(updateKey + 1);
        }}
        placeholder="https://mySite.ru ..."
        disabled={isActive}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  selected: state.bidList.select,
});
export default connect(mapStateToProps, { setProp })(PlaceTrade);
