export const SET_PAYMENTS = "SET_PAYMENTS";
export const SELECT_PAYMENTS = "SELECT_PAYMENTS";
export const REFRESH_PAYMENTS = "REFRESH_PAYMENTS";

export const SET_SORTS_PAYMENTS = "SET_SORTS_PAYMENTS";
export const SET_RANGE_PAYMENTS = "SET_RANGE_PAYMENTS";
export const SET_FILTER_PAYMENTS = "SET_FILTER_PAYMENTS";

export const SHOW_PAYMENT = "SHOW_PAYMENT";

export const SHOW_FILTERS_PAYMENTS = "SHOW_FILTERS_PAYMENTS";

export const SET_PAGINATION_PAYMENTS_TOTAL = "SET_PAGINATION_PAYMENTS_TOTAL";
export const SET_PAYMENTS_TOTAL_AMOUNT = "SET_PAYMENTS_TOTAL_AMOUNT";
