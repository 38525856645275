import React from "react";
import { Button } from "primereact/button";

import styles from "../../../../index.module.css";

function AppPayboxView({ className }) {
  const handleClick = () => console.log("click");

  return (
    <div
      className={`${className} p-d-flex p-jc-center p-flex-column p-pl-2 p-pr-2 p-pl-md-5 p-pr-md-5`}
    >
      <p className="p-mb-3">
        <strong>Скачайте версию приложения на Android.</strong>
        <br />
        Установка по кнопке ниже — безопасна.
      </p>

      <div className="p-col-12 p-p-0" style={{ maxWidth: "250px" }}>
        <Button
          label="Скачать на Android"
          className={`${styles.buttonSuccess}`}
          onClick={handleClick}
          style={{ borderRadius: "5px !important" }}
        />
      </div>
    </div>
  );
}

export default AppPayboxView;
