import React from "react";
import { HTable } from "components/based";

function HeadTable(props) {
  return (
    <div className={props.className}>
      <HTable className="p-col-3 p-pl-0 p-text-start" value="Серийный номер" />

      <HTable className="p-col-5 p-text-start" value="Торговая площадка" />

      <HTable className="p-col-4 p-text-start" value="Комментарий" />
    </div>
  );
}

export default HeadTable;
