import React from "react-redux";

const style = {
  wordBreak: "break-all",
};

function LabelToUpperCase(props) {
  return (
    <div className={`p-d-flex p-col-12 p-p-0 ${props.className}`}>
      <span className="p-text-uppercase p-pr-2 p-col-6 p-p-0">
        {props.label}
      </span>
      <span
        style={style}
        className="p-text-uppercase p-col-6 p-p-0 p-text-right"
      >
        {props.value}
      </span>
    </div>
  );
}

export default LabelToUpperCase;
