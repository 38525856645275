import React from "react";
import InfoIcon from "@material-ui/icons/Info";
import GetAppIcon from "@material-ui/icons/GetApp";

import styles from "../../../../../index.module.css";

function CardCms(props) {
  return (
    <div
      className="p-col-12 p-p-3 p-d-flex p-flex-column p-jc-end c-shadow-2"
      style={{ height: "100%" }}
    >
      <div className="p-col-12 p-p-0 p-d-flex p-jc-center">
        <picture>
          <img
            src={process.env.PUBLIC_URL + props.cms.img}
            alt={props.cms.name}
          />
        </picture>
      </div>

      <div className="p-col-12 p-p-0 p-mt-3 p-d-flex p-flex-wrap">
        <a
          href={props.cms.urlManual}
          className="p-col-12 p-md-6 p-d-flex p-flex-md-row-reverse p-jc-md-end"
          download
        >
          <span className={styles.cmsLinks}>Инструкция</span>
          <InfoIcon style={{ color: "#656A6D" }} />
        </a>
        <a
          href={props.cms.urlLoadingModule}
          className="p-col-12 p-md-6 p-d-none p-jc-end"
        >
          <span className={styles.cmsLinks}>Скачать модуль</span>
          <GetAppIcon style={{ color: "#656A6D" }} />
        </a>
      </div>
    </div>
  );
}

export default CardCms;
