import {
  SET_SORTS_PAYMENTS,
  SET_RANGE_PAYMENTS,
  SET_FILTER_PAYMENTS,
  SHOW_FILTERS_PAYMENTS,
  SET_PAGINATION_PAYMENTS_TOTAL,
} from "../../types/payments";

const initialState = {
  sort: null,
  range: null,
  filter: null,
  count: 120,
  show: false,
};

export default function paymentsFilters(state = initialState, action) {
  switch (action.type) {
    case SET_SORTS_PAYMENTS:
      return {
        ...state,
        sort: action.payload,
      };
    case SET_RANGE_PAYMENTS:
      return {
        ...state,
        range: action.payload,
      };
    case SET_FILTER_PAYMENTS:
      return {
        ...state,
        filter: action.payload,
      };
    case SHOW_FILTERS_PAYMENTS:
      return {
        ...state,
        show: action.payload,
      };
    case SET_PAGINATION_PAYMENTS_TOTAL:
      return {
        ...state,
        count: action.payload.total,
      };
    default:
      return state;
  }
}
