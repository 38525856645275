import React from "react";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import styles from "./style.module.css";

function ExpansionIcon({ active, setActive, className }) {
  const onClick = () => {
    setActive(!active);
  };

  return (
    <div
      className={`${styles.icon} ${
        active ? styles.iconActive : ""
      } ${className}`}
      onClick={onClick}
    >
      <ArrowForwardIosIcon />
    </div>
  );
}

function ExpansionItem(props) {
  return (
    <div
      className={`${props.className} ${styles.item} ${
        props.active ? styles.itemActive : ""
      }`}
    >
      {props.withLine && <div className={styles.line}></div>}

      {props.children}
    </div>
  );
}

export { ExpansionIcon, ExpansionItem };
