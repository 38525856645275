import React from "react";

import { TriStateCheckbox } from "primereact/tristatecheckbox";

function isActiveComponent(props) {
  let text =
    props.value === false ? "Неактивное оборудование" : "Активное оборудование";

  return (
    <div className={props.className}>
      <div className="p-d-flex">
        <label
          htmlFor="isActive"
          className="p-mr-2"
          style={{ userSelect: "none" }}
        >
          {text}
        </label>

        <TriStateCheckbox
          inputId="isActive"
          value={props.value}
          onChange={(e) => props.setValue(e.value)}
        />
      </div>
    </div>
  );
}

export default isActiveComponent;
