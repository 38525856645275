import React from "react";
import { connect } from "react-redux";
import { toggleShow, selectedItem } from "domain/actions/createReceipt";

import Window from "components/windows";

import { Form } from "./components";

function ItemWindow(props) {
  const onHide = () => {
    props.selectedItem(null);
    props.toggleShow(false);
  };

  return (
    <Window
      header="Дополнительные параметры товара"
      visible={props.show}
      className="p-col-12 p-md-11 p-p-0 c-dialog"
      onHide={onHide}
      draggable={false}
    >
      <Form className="p-d-flex p-flex-column p-jc-between"></Form>
    </Window>
  );
}

const mapStateToProps = (state) => ({
  show: state.createReceiptItems.showWindow,
});
export default connect(mapStateToProps, { toggleShow, selectedItem })(
  ItemWindow
);
