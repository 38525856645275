import React from "react";
import { TextFieldControl } from "components/forms/fields";

function IdField(props) {
  return (
    <TextFieldControl
      className="p-d-none"
      name="id"
      control={props.formHook.control}
      errors={props.formHook.errors}
      label="id:"
    />
  );
}

export default IdField;
