import React from "react";

import { Button } from "primereact/button";

function PayoutBtn(props) {
  return (
    <div className={props.className}>
      <Button
        label="Внесение/выплата"
        onClick={props.onClick}
        className="c-btns--gradient c-btns"
      />
    </div>
  );
}

export default PayoutBtn;
