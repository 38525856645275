import React from "react";
import { Controller } from "react-hook-form";
import { getDefaultValue } from "../../../../controllers/getDefaultValue";

import { InputText } from "primereact/inputtext";
import InputVatin from "../../../../components/forms/Input.vatin";

import styles from "../styles.module.css";

function AgentTranslateComponent({
  errors,
  control,
  className,
  defaultValue = "",
}) {
  return (
    <div className={`${styles.form} ${className}`}>
      <div className="p-p-0">
        <div className="p-col-12 p-d-flex p-flex-column">
          <label className={`${styles.font} p-pb-1`}>Наименование</label>

          <Controller
            name="agentInfo.moneyTransferOperator.name"
            control={control}
            render={(props) => (
              <InputText
                onChange={(e) => props.onChange(e)}
                className={`${styles.borderBottom}`}
                defaultValue={getDefaultValue(defaultValue, "name")}
              />
            )}
            defaultValue={getDefaultValue(defaultValue, "name")}
          />
        </div>

        <div className="p-col-12 p-d-flex p-flex-column">
          <label className={`${styles.font} p-pb-1`}>Адрес</label>

          <Controller
            name="agentInfo.moneyTransferOperator.address"
            control={control}
            render={(props) => (
              <InputText
                onChange={(e) => props.onChange(e)}
                className={`${styles.borderBottom}`}
                defaultValue={getDefaultValue(defaultValue, "address")}
              />
            )}
            defaultValue={getDefaultValue(defaultValue, "address")}
          />
        </div>

        <div className="p-col-12 p-d-flex p-flex-column">
          <label className={`${styles.font} p-pb-1`}>Телефоны</label>

          <Controller
            name="agentInfo.moneyTransferOperator.phones"
            control={control}
            render={(props) => (
              <InputText
                onChange={(e) => props.onChange(e)}
                className={`${styles.borderBottom}`}
                keyfilter={/^[^a-zA-Zа-яА-Я<>*!]+$/}
                defaultValue={getDefaultValue(defaultValue, "phones")}
              />
            )}
            defaultValue={getDefaultValue(defaultValue, "phones")}
          />
        </div>

        <InputVatin
          className="p-col-12 p-d-flex p-flex-column"
          control={control}
          errors={errors}
          name="agentInfo.moneyTransferOperator.vatin"
          defaultValue={getDefaultValue(defaultValue, "vatin")}
        />
      </div>
    </div>
  );
}

export default AgentTranslateComponent;
