import React from "react";
import { connect } from "react-redux";
import {
  postAcquiring,
  putAcquiring,
} from "domain/actions/organizations/acquiring";
import { Button } from "primereact/button";

function ConfirmButton(props) {
  const confirm = () => {
    if (props.select) return props.putAcquiring();
    props.postAcquiring();
  };

  const label = props.select ? "Применить" : "Создать";

  return (
    <div className={props.className}>
      <Button label={label} className="с-button-success" onClick={confirm} />
    </div>
  );
}

const mapStateToProps = (state) => ({
  select: state.orgAcquiring.select,
});
export default connect(mapStateToProps, { postAcquiring, putAcquiring })(
  ConfirmButton
);
