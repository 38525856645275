import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";

import Supplier from "../components/Supplier.item";

import Window from "components/windows";
import { Button } from "primereact/button";

import styles from "../styles.module.css";
import { isArray } from "lodash";

function SupplierWindow({ show, close, supplier, onConfirms }) {
  const { register, control, errors, handleSubmit } = useForm();

  const [selectedSupplier, setSelectedSupplier] = useState(null);

  useEffect(() => {
    setSelectedSupplier(supplier);
  }, [supplier]);

  const onConfirm = (data) => {
    let result = Object.assign({}, data.supplierInfo);

    if (!isArray(result.phones)) result.phones = result.phones.split(/[\s,]+/);

    onConfirms(result);
    close();
  };

  const footer = (
    <div className="p-col-12 p-d-flex p-jc-end p-mt-3">
      <div className={`p-col-12 ${styles.buttons}`}>
        <Button label="Добавить" className="p-button-success" type="confirm" />
      </div>
    </div>
  );

  return (
    <Window
      header="Поставщик"
      visible={show}
      className={`${styles.dialog} p-col-12 p-md-5 p-xl-4 p-p-0 dialog-header`}
      onHide={close}
      draggable={false}
    >
      <form
        onSubmit={handleSubmit(onConfirm)}
        className="p-d-flex p-flex-column p-jc-between"
      >
        <Supplier
          register={register}
          errors={errors}
          control={control}
          title={false}
          isColumn={true}
          defaultValue={selectedSupplier}
          className="p-col-12 off-shadow"
        />

        {footer}
      </form>
    </Window>
  );
}

export default connect(null, null)(SupplierWindow);
