import React from "react";
import { TextField } from "components/forms/fields";

function SumDoc(props) {
  return (
    <TextField
      label="Сумма операции"
      className={props.className}
      value={props.value}
      onChange={(e) => props.setValue(e)}
      placeholder="0"
      keyfilter="int"
    />
  );
}

export default SumDoc;
