import { SET_BALANCE_BILLING } from "domain/types/billing";

const initialState = {
  balance: 20000000,
  invoice: [
    {
      id: 1,
      invoiceId: 1,
      sum: "-400.00",
      comment: "Прошивка касс, 2 шт",
      from: "2021-06-25T08:19:49.420Z",
      to: "2021-07-07T08:19:49.420Z",
      createdAt: "2021-06-25T08:19:49.420Z",
      updatedAt: "2021-06-25T08:19:49.420Z",
      invoice: {
        id: 1,
        accountId: 1,
        userId: 1,
        serviceId: 2,
        receiptId: 2,
        count: 2,
        price: "-200.00",
        comment: "Прошивка кассы",
        createdAt: "2021-06-25T08:19:49.409Z",
        updatedAt: "2021-06-25T08:19:49.409Z",
        service: {
          id: 2,
          name: "Прошивка кассы",
          enabled: true,
          comment: "Прошивка кассы",
          price: "100.00",
          createdAt: "2021-06-25T08:19:49.398Z",
          updatedAt: "2021-06-25T08:19:49.398Z",
        },
      },
    },
  ],
};

export default function analytics(state = initialState, action) {
  switch (action.type) {
    case SET_BALANCE_BILLING:
      return {
        ...state,
        balance: action.payload,
      };
    default:
      return state;
  }
}
