import React from "react";
import { connect } from "react-redux";
import { postOrg, changeValidate } from "domain/actions/organizations/created";
import { Button } from "primereact/button";

function ConfirmButton(props) {
  const confirm = () => {
    props.changeValidate(true);

    return props.postOrg();
  };

  return (
    <div className={props.className}>
      <Button label="Создать" className="с-button-success" onClick={confirm} />
    </div>
  );
}

const mapStateToProps = (state) => ({
  isValid: state.orgCreate.isValid,
});
export default connect(mapStateToProps, { postOrg, changeValidate })(
  ConfirmButton
);
