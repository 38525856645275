import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { TextField } from "components/forms/fields/text";
import { setAccountName } from "domain/actions/settings";

function NameAccount(props) {
  const [value, setValue] = useState("");

  useEffect(() => setValue(props.name), [props.name]);

  return (
    <div className={props.className}>
      <TextField
        label="Наименование аккаунта"
        value={value}
        onChange={props.setAccountName}
        isValid={true}
        className="label-color-green"
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  name: state.settings.name,
  isValid: true,
});
export default connect(mapStateToProps, { setAccountName })(NameAccount);
