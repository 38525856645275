import React from "react";

import styles from "../style.module.css";

function Menu({ list = [], className = "" }) {
  const renderedListItems = list.map((i, index) => {
    return (
      <i.icon
        key={index}
        onClick={i.callback}
        alt={i.name}
        className={styles.icon}
      />
    );
  });
  return <div className={className}>{renderedListItems}</div>;
}

export default Menu;
