import React from "react";
import { connect } from "react-redux";

import { toDate } from "controllers/typeToString";
import { TextField, TextAreaField } from "components/forms/fields";

function CashboxData(props) {
  const item = props.item;
  const ffd = props.requisites.ffd.filter((i) => i.id === item.ffdId);
  const sno = props.requisites.sno.filter((i) => i.id === item.defaultSnoId);

  return (
    <div className={props.className}>
      <span className="block-label-title p-pl-2">Данные по кассе</span>

      <div className="p-col-12 p-p-0 p-flex-wrap p-d-flex">
        <TextField
          label="Заводской номер ККТ"
          className="p-col-12 p-md-4"
          value={item.factoryNumber ? item.factoryNumber : "-"}
          disabled={true}
        />

        <TextField
          label="Регист. номер ККТ"
          className="p-col-12 p-md-4"
          value={item.regNumber ? item.regNumber : "-"}
          disabled={true}
        />

        <TextField
          label="Место расчета"
          className="p-col-12 p-md-4"
          value={item.tradePlace ? item.tradePlace : "-"}
          disabled={true}
        />

        <TextField
          label="Заводской номер ФН"
          className="p-col-12 p-md-4"
          value={item.fn ? item.fn.number : "-"}
          disabled={true}
        />

        <TextField
          label="Срок действий ФН"
          className="p-col-12 p-md-4"
          value={item.fnType ? item.fnType.name : "-"}
          disabled={true}
        />

        <TextField
          label="Дата замены ФН"
          className="p-col-12 p-md-4"
          value={item.fn ? toDate(item.fn.expinTime) : "-"}
          disabled={true}
        />

        <TextField
          label="Версия ФФД"
          className="p-col-12 p-md-4"
          value={ffd.length ? ffd[0].name : "-"}
          disabled={true}
        />

        <TextField
          label="Адрес ОФД"
          className="p-col-12 p-md-4"
          value={item.ofd ? item.ofd.url : "-"}
          disabled={true}
        />

        <TextField
          label="СНО(по умолчанию)"
          className="p-col-12 p-md-4"
          value={sno.length ? sno[0].name : "-"}
          disabled={true}
        />

        <TextAreaField
          label="Фактическое расположение"
          className="p-col-12"
          value={item.location ? item.location : "-"}
          disabled={true}
          autoResize={true}
        />
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  item: state.equipments.select,
  requisites: state.requisites,
});
export default connect(mapStateToProps)(CashboxData);
