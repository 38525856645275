import {
  SET_RECEIPTS,
  REFRESH_RECEIPTS,
  SELECT_RECEIPTS,
  SHOW_RECEIPTS,
  SHOW_SEND_PAY_LINK,
  SHOW_SEND_LINK_RECEIPT,
  SET_RECEIPTS_TOTAL_AMOUNT,
} from "../types/receipts";

const initialState = {
  receipts: [],
  totalAmount: {
    totalBuy: 0,
    totalReturnBuy: 0,
    totalReturnSell: 0,
    totalSell: 40,
  },
  select: null,
  show: false,
  showSendPayLink: false,
  showSendLinkReceipt: false,
  refresh: false,
};

export default function receiptReducer(state = initialState, action) {
  switch (action.type) {
    case SET_RECEIPTS:
      return {
        ...state,
        receipts: action.payload,
      };
    case SET_RECEIPTS_TOTAL_AMOUNT:
      return {
        ...state,
        totalAmount: action.payload,
      };
    case SELECT_RECEIPTS:
      return {
        ...state,
        select: action.payload,
      };
    case REFRESH_RECEIPTS:
      return {
        ...state,
        refresh: action.payload,
      };
    case SHOW_RECEIPTS:
      return {
        ...state,
        show: action.payload,
      };
    case SHOW_SEND_PAY_LINK:
      return {
        ...state,
        showSendPayLink: action.payload,
      };
    case SHOW_SEND_LINK_RECEIPT:
      return {
        ...state,
        showSendLinkReceipt: action.payload,
      };
    default:
      return state;
  }
}
