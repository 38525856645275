export const SET_BID_EQUIPMENTS = "SET_BID_EQUIPMENTS";
export const REFRESH_BID_EQUIPMENTS = "REFRESH_BID_EQUIPMENTS";
export const SELECT_BID_EQUIPMENTS = "SELECT_BID_EQUIPMENTS";
export const POST_LOCAL_BID_EQUIPMENTS = "POST_LOCAL_BID_EQUIPMENTS";

export const SHOW_WINDOW_BID_EQUIPMENTS = "SHOW_WINDOW_BID_EQUIPMENTS";
export const SET_LOCAL_BID_EQUIPMENTS = "SET_LOCAL_BID_EQUIPMENTS";
export const SET_ORG_BID_EQUIPMENTS = "SET_ORG_BID_EQUIPMENTS";
export const SET_URL_BID_EQUIPMENTS = "SET_URL_BID_EQUIPMENTS";
export const ADD_CASHBOX_BID_EQUIPMENTS = "ADD_CASHBOX_BID_EQUIPMENTS";
export const UPDATE_CASHBOX_BID_EQUIPMENTS = "UPDATE_CASHBOX_BID_EQUIPMENTS";
export const DELETE_CASHBOX_BID_EQUIPMENTS = "DELETE_CASHBOX_BID_EQUIPMENTS";
export const SET_TARIFF_BID_EQUIPMENTS = "SET_TARIFF_BID_EQUIPMENTS";

export const CLEAR_CASHBOX_BID_EQUIPMENTS = "CLEAR_CASHBOX_BID_EQUIPMENTS";
export const CLEAR_BID_EQUIPMENTS = "CLEAR_BID_EQUIPMENTS";

export const EDIT_BID_EQUIPMENTS = "EDIT_BID_EQUIPMENTS";

export const FILTER_BID_EQUIPMENTS = "FILTER_BID_EQUIPMENTS";
export const RANGE_BID_EQUIPMENTS = "RANGE_BID_EQUIPMENTS";
export const SORT_BID_EQUIPMENTS = "SORT_BID_EQUIPMENTS";

export const SORT_EQUIPMENTS = "SORT_EQUIPMENTS";
export const FILTER_EQUIPMENTS = "FILTER_EQUIPMENTS";
export const RANGE_EQUIPMENTS = "RANGE_EQUIPMENTS";
export const LIKE_EQUIPMENTS = "LIKE_EQUIPMENTS";
export const SHOW_FILTERS_EQUIPMENTS = "SHOW_FILTERS_EQUIPMENTS";
export const REFRESH_EQUIPMENTS = "REFRESH_EQUIPMENTS";

export const SET_PAGINATION_BID_EQUIPMENT = "SET_PAGINATION_BID_EQUIPMENT";
export const SET_PAGINATION_EQUIPMENT = "SET_PAGINATION_EQUIPMENT";

export const LOADING_CASHBOX_BID_EQUIPMENTS = "LOADING_CASHBOX_BID_EQUIPMENTS";
