import React from "react";
import { connect } from "react-redux";
import { getDistribution } from "domain/actions/equipment/bid";
import Window from "components/windows";
import { Button } from "primereact/button";
import CodeWorker from "./code";
import { Divider } from "primereact/divider";

export const distributions = [
  { id: 1, name: "Скачать", eng: "windows-x32" },
  // { id: 2, name: "Win64", eng: "windows-x64" },
  // { id: 3, name: "Linux", eng: "linux" },
];

function InstallWorkerWindow(props) {
  const { show, setShow, code, getDistribution } = props;

  return (
    <Window
      className={`${props.className} ${
        props.activeSidebar
          ? "window-sidebar-active"
          : "window-sidebar-inActive"
      }`}
      style={{ maxWidth: "648px" }}
      header='Установка "Рабочего места"'
      visible={show}
      onHide={() => setShow(false)}
      modal={false}
      draggable={false}
    >
      <div className="p-col-12 p-d-flex p-flex-wrap">
        <div className="p-col-12 ">
          <h4>
            {" "}
            Для подключения "Рабочего места" скачайте и установите программу{" "}
          </h4>

          {distributions.map((i) => {
            return (
              <div className="p-col-12 p-as-center">
                <Button
                  key={i.id}
                  className="p-col-12 p-text-center c-button"
                  label={i.name}
                  onClick={() => {
                    getDistribution(i.eng);
                  }}
                />
              </div>
            );
          })}

          <p>
            {" "}
            Скопируйте код подключения и вставьте в соответствующее поле во
            время установки программы{" "}
          </p>
        </div>

        <CodeWorker code={code} />

        <div className="p-px-2">
          <h4> Скачать драйвер на кассу можно по ссылкам </h4>
          <div className="p-col-12 p-d-flex p-flex-column p-p-0">
            <a
              href="https://www.atol.ru/company/service-support/download-center/"
              rel="noreferrer"
              target="_blank"
            >
              Центр загрузок Атол
            </a>
            <a
              href="https://www.shtrih-m.ru/support/download/?section_id=all&product_id=all&type_id=all&searchDownloads=%D0%94%D1%80%D0%B0%D0%B9%D0%B2%D0%B5%D1%80+%D0%9A%D0%9A%D0%A2"
              rel="noreferrer"
              target="_blank"
            >
              Драйвер на Штрих-М
            </a>
          </div>
        </div>

        <Divider />

        <p> После завершения установки, можно закрыть окно </p>
      </div>
    </Window>
  );
}

export default connect(null, { getDistribution })(InstallWorkerWindow);
