import React, { useState } from "react";
import TextArea from "./textArea";
import NameField from "./name";
import MobileField from "./mobile";
import EmailField from "./email";
import { Agreement } from "components/static";
import YourContact from "./yourContact";

import Confirm from "./confirmBtn";

function Layout(props) {
  const [response, setResponse] = useState("phone");
  const [access, setAccess] = useState(false);

  return (
    <form className={props.className}>
      <NameField formHook={props.formHook} />

      <YourContact response={response} setResponse={setResponse} />

      {response === "phone" && <MobileField formHook={props.formHook} />}

      {response === "email" && <EmailField formHook={props.formHook} />}

      <TextArea formHook={props.formHook} />

      <Agreement onChange={setAccess} checked={access} />

      <Confirm
        handleSubmit={props.formHook.handleSubmit}
        confirm={props.confirm}
        access={access}
      />
    </form>
  );
}

export default Layout;
