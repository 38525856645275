import React from "react";

import styles from "../style.module.css";

function Icon(props) {
  return (
    <div className={props.className}>
      <props.icon
        onClick={props.callback}
        alt={props.name}
        className={styles.icon}
      />
    </div>
  );
}

export default Icon;
