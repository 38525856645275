import React from "react";
import { connect } from "react-redux";
import { getData } from "domain/actions/analytics";
import DatePanel from "components/static/datePanel";

function DateFilter(props) {
  return (
    <DatePanel
      className={props.className}
      setRange={props.getData}
      defaultRange="day"
    />
  );
}

export default connect(null, { getData })(DateFilter);
