import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getWorkers } from "domain/actions/organizations/index";
import { TextField, CheckboxField, Select } from "components/forms/fields";

import styles from "../../../../../../style.module.css";

function GeneralData(props) {
  const license = props.item.license;
  const { getWorkers } = props;
  const [worker, setWorker] = useState(null);

  useEffect(() => {
    getWorkers();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const _filter = props.workers.filter(
      (r) => r.id === props.item?.worker?.id
    );

    if (_filter.length > 0) setWorker(_filter[0]);
  }, [props.item, props.workers]);

  return (
    <div className={props.className}>
      <span className="block-label-title p-pl-2">Общие данные</span>

      <div className="p-col-12 p-p-0 p-flex-wrap p-d-flex">
        <TextField
          label="Идентификатор"
          className={`${styles.colorLabel} p-col-12 p-md-4`}
          value={props.item.id}
          disabled={true}
        />

        <TextField
          label="Организация"
          className={`${styles.colorLabel} p-col-12 p-md-4`}
          value={props.item.organization.name}
          disabled={true}
        />

        <TextField
          label="Номер заявления"
          className={`${styles.colorLabel} p-col-12 p-md-4`}
          value={props.item.bidId}
          disabled={true}
        />

        <TextField
          label="Тариф"
          className={`${styles.colorLabel} p-col-12 p-md-4`}
          value={
            license
              ? license.tariff.description + " " + license.tariff.cost
              : "Отсутствует"
          }
          disabled={true}
        />

        <Select
          label="Рабочее место"
          options={props.workers}
          className={`${styles.colorLabel} p-col-12 p-md-4`}
          value={worker}
          disabled={true}
        />

        <CheckboxField
          label="Печать документов на бумаге"
          className={`${styles.colorLabel} ${styles.printOnPaper} p-col-12 p-md-4 p-d-flex`}
          checked={props.item.needPrintOnPaper}
        />
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  item: state.equipments.select,
  workers: state.organizations.workers,
});
export default connect(mapStateToProps, { getWorkers })(GeneralData);
