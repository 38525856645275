import {
  SHOW_ERROR,
  CLEAR_ERROR,
  CLEAR_SUCCESS,
  SHOW_SUCCESS,
} from "../actionTypes";

const initialState = {
  show: false,
  message: "",
  success: false,
  messageSuccess: "",
};

export default function errorReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_ERROR:
      return {
        show: true,
        message: action.payload,
      };
    case CLEAR_ERROR:
      return {
        show: false,
        message: "",
      };
    case SHOW_SUCCESS:
      return {
        success: true,
        messageSuccess: action.payload,
      };
    case CLEAR_SUCCESS:
      return {
        success: false,
        messageSuccess: "",
      };
    default:
      return state;
  }
}
