import React from "react";
import styles from "../style.module.css";

function Column(props) {
  return (
    <div className={`${props.className} ${styles.column}`}>
      <span className={props.classTitle}>{props.title}</span>
      {!!props.body ? (
        props.body(props.value)
      ) : (
        <span className={props.classBody}>{props.value}</span>
      )}
    </div>
  );
}

export default Column;
