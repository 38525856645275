import React from "react";

import Phone from "../contacts/phone.contacts";
import Email from "../contacts/email.contacts";
import Address from "../contacts/address.contacts";

function Contacts({ className }) {
  return (
    <div className={className}>
      <Phone phone="8 (4712) 400-900" />
      <Email email="info@vti-service.ru" />
      <Address address="г. Курск, ул. Ватутина, д. 25" />
    </div>
  );
}

export default Contacts;
