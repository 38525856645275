import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getEquipmentsByFilter } from "domain/actions/equipment";

import Head from "./header";
import Body from "./body";

import Pagination from "components/static/pagination";
import { SpinnerWrapper } from "components/wrappers";

function DateView(props) {
  const [page, setPage] = useState(1);
  const getEquipmentsByFilter = props.getEquipmentsByFilter;

  useEffect(() => getEquipmentsByFilter(page), [
    getEquipmentsByFilter,
    page,
    props.refresh,
  ]);

  return (
    <div className={props.className}>
      <Pagination
        callback={(page) => setPage(page)}
        pagination={props.pagination}
      />
      <Head className="p-col-12 p-pl-0 p-pt-0 p-pr-0 p-d-none p-d-md-flex c-theader-shadow" />

      <SpinnerWrapper className="c-pagination-table">
        <Body className="p-col-12 p-p-1" />
      </SpinnerWrapper>

      <Pagination
        callback={(page) => setPage(page)}
        pagination={props.pagination}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  refresh: state.equipments.refresh,
  pagination: state.equipmentPagination,
});

export default connect(mapStateToProps, { getEquipmentsByFilter })(DateView);
