import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Status } from "routes/pages/receipts/windows/receipt/components";
import {
  UrlPayment,
  ErrorComponent,
  Cashier,
  Products,
} from "routes/pages/receipts/windows/receipt/layouts/components";
import { LabelInfo } from "components/static";
import CancelButton from "components/btns/cancelBtn";
import { closeWinPayment } from "domain/actions/payments";
import { Chip } from "primereact/chip";
import { Button } from "primereact/button";
import { toDate } from "controllers/typeToString";
import { showSendPayLink } from "domain/actions/receipt";
import { Label } from "components/based";
import ColumnTypeToString from "controllers/ColumnTypeToString";

function PaymentContent(props) {
  const [acquiring, setAcquiring] = useState(null);
  const TypeToString = new ColumnTypeToString();
  const { item, closeWinPayment, showSendPayLink } = props;
  const error = false;

  const {
    status,
    amount,
    currency,
    // description,
    emailOrPhone,
    onlinePaymentId,
    // orgAcqId,
    // paymentCallback,
    paymentMethodData,
    orgAcquiring,
    receiptId,
    receipt,
    transaction,
    urlPayment,
    createdAt,
  } = item;

  const redirectLinkReceipt = (uuid) => {
    const host =
      window.location.host === "gate.stage.vdpaybox.ru"
        ? "stage.vdpaybox.ru"
        : "vdpaybox.ru";
    const protocol = window.location.protocol;
    window.open(`${protocol}//rt.${host}/info/${uuid}`);
  };

  if (!item) return <></>;

  return (
    <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap">
      <div className="p-col-12 p-d-flex p-flex-wrap">
        <div className="p-col-12 p-md-6 p-xl-4">
          <div className="p-col-12 p-p-0">
            {/* <Status status={status} className="p-col-12 p-p-0" /> */}
            <div className="p-col-12 p-p-0">
              <div className="p-d-flex p-col-12 p-p-0 p-mb-3">
                <Label value="Статус платежа" className="p-mr-4" />
                <div className="line-height-14">
                  {TypeToString.toStatusPayments(status)}
                </div>
              </div>
            </div>
            {error && (
              <ErrorComponent className="p-col-12 p-p-0 p-mb-2" item={item} />
            )}

            {receipt ? (
              <div className="p-col-12 p-p-0 p-mb-2 p-mt-1">
                <Button
                  label="Просмотр чека"
                  className="p-button-success p-col-12"
                  style={{ height: "50px" }}
                  onClick={() => redirectLinkReceipt(receipt.uuid)}
                />
              </div>
            ) : (
              <></>
            )}

            {/* <Cashier className="p-col-12 p-p-0 p-mb-2" item={item} /> */}
          </div>
        </div>
        {urlPayment && (
          <div className="p-col-12 p-md-6 p-xl-4">
            <UrlPayment urlPayment={urlPayment} className="p-col-12 p-p-0" />
            <div className="p-col-12 p-p-0 p-mb-2 p-mt-1">
              <Button
                label="Отправить ссылку оплаты"
                className="p-button-success p-col-12"
                style={{ height: "50px" }}
                onClick={() => showSendPayLink(true)}
              />
            </div>
          </div>
        )}
      </div>

      <div className="p-col-12 p-d-flex p-flex-wrap">
        <div
          className="p-col-12 p-md-6 p-d-flex p-flex-wrap p-p-3 p-mb-3"
          style={{ height: "fit-content" }}
        >
          <LabelInfo
            label="Сумма"
            info={amount ? amount : "-"}
            className=" p-col-6 p-md-4"
          />

          <LabelInfo
            label="Валюта"
            info={currency ? currency : "-"}
            className=" p-col-6 p-md-4"
          />

          <LabelInfo
            label="E-mail"
            info={emailOrPhone ? emailOrPhone : "-"}
            className="-col-6 p-md-4"
          />

          <LabelInfo
            label="ID онлайн платежа"
            info={onlinePaymentId ? onlinePaymentId : "-"}
            className="p-col-6 p-md-4"
          />

          <LabelInfo
            label="Метод оплаты"
            info={paymentMethodData ? paymentMethodData : "-"}
            className="p-col-6 p-md-4"
          />

          <LabelInfo
            label="Тип платежной системы"
            info={
              orgAcquiring && orgAcquiring?.acquiring
                ? orgAcquiring?.acquiring.name
                : "-"
            }
            className="p-col-6 p-md-4"
          />

          <LabelInfo
            label="Транзакции"
            info={transaction ? transaction.toString() : "-"}
            className="p-col-6 p-md-4 p-d-none"
          />

          <LabelInfo
            label="Дата создания"
            info={createdAt ? toDate(createdAt) : "-"}
            className="p-col-6 p-md-4"
          />
        </div>

        {item.taskJson && (
          <Products
            items={item.taskJson.parameters.items}
            className="p-col-12 p-md-6 p-p-0 p-mb-2"
          />
        )}
      </div>

      <div className="p-col-12 p-d-flex p-p-0">
        <CancelButton
          className="p-col-12 p-md-6 p-xl-4"
          onShow={() => closeWinPayment(false)}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  item: state.payments.select,
});
export default connect(mapStateToProps, { closeWinPayment, showSendPayLink })(
  PaymentContent
);
