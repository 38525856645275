import React from "react";
import Card from "../../components/cardCMS";

function ListCMS(props) {
  const renderItems = props.data.map((i) => {
    return (
      <div key={i.id} className="p-col-12 p-md-6 p-p-2">
        <Card cms={i} />
      </div>
    );
  });

  return <div className={props.className}>{renderItems}</div>;
}

export default ListCMS;
