import React from "react";
import { CardComponent } from "components/static";

function ErrorComponent(props) {
  const error = props.item.trouble ? props.item.trouble : false;

  const label = (
    <span className="block-label-title title-bold p-pl-2 p-as-center">
      Сообщение об ошибке
    </span>
  );

  const content = (
    <span className="p-col-12 p-d-flex p-pl-3 p-pb-3 p-pt-1 p-text-uppercase text-color-danger">
      {!!error && error.text}
    </span>
  );
  return (
    <CardComponent label={label} className={props.className}>
      {content}
    </CardComponent>
  );
}

export default ErrorComponent;
