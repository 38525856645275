import {
  LOGIN_AUTH,
  LOGOUT_AUTH,
  SET_VISIBLE_UNAUTHORIZED,
} from "../actionTypes";
import { setAccountData } from "domain/actions/account.actions";
import { SET_SETTINGS } from "domain/types/settings";
import { SET_CURRENT_USERS, SET_RIGHTS } from "domain/types/users";
import { AMSController, AuthController } from "domain/controllers";

const AMS = new AMSController();
const Auth = new AuthController();

export function logout() {
  return async function thunk(dispatch, getState) {
    dispatch({ type: LOGOUT_AUTH });
  };
}

function setCurrentUser(dispatch, data) {
  let currentUser = data.user;
  currentUser.accountName = data.account.name;
  data.account.staticToken = !!currentUser && currentUser.staticToken;

  dispatch({
    type: SET_CURRENT_USERS,
    payload: currentUser,
  });
  dispatch({
    type: SET_RIGHTS,
    payload: data.rights,
  });
  dispatch({
    type: SET_SETTINGS,
    payload: data.account,
  });

  setAccountData(dispatch, data);
}

export function isAuth() {
  return async function thunk(dispatch, getState) {
    const isToken = await Auth.getTokenSSO();

    if (isToken === null) {
      dispatch({ type: LOGOUT_AUTH });
      return false;
    }

    return await AMS.get("/accounts/me")
      .then((r) => {
        dispatch({ type: LOGIN_AUTH });
        setCurrentUser(dispatch, r.data);
      })
      .catch((e) => {
        console.log(e, "e");
        dispatch({ type: LOGOUT_AUTH });
        throw e;
      });
  };
}

export function reauthorization() {
  localStorage.setItem("reauthorization", true);
  Auth.logout();
}

export function isReAuth() {
  return async function thunk(dispatch, getState) {
    const reauthorization = localStorage.getItem("reauthorization");

    localStorage.removeItem("reauthorization");
    if (reauthorization) Auth.login();

    return;
  };
}

export function setVisible(show) {
  return async function thunk(dispatch, getState) {
    dispatch({ type: SET_VISIBLE_UNAUTHORIZED, payload: show });
  };
}
