import React from "react";
import { connect } from "react-redux";
import Legal from "./legal";
import Individual from "./individual";

function DataOrg(props) {
  const isLegalEntity =
    props.isLegalEntity && props.isLegalEntity.isLegalEntity;
  return (
    <div className={props.className}>
      {isLegalEntity ? (
        <Legal className="p-col-12 p-p-0 p-d-flex p-flex-wrap" />
      ) : (
        <Individual className="p-col-12 p-p-0 p-d-flex p-flex-wrap" />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLegalEntity: state.orgCreate.item.legalEntity,
});
export default connect(mapStateToProps)(DataOrg);
