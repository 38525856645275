import React from "react";
import { connect } from "react-redux";
import { Menu } from "components/static";

import { getOrganizations } from "domain/actions/organizations";
import { handleShowWindow } from "domain/actions/organizations.actions";

import AddIcon from "@material-ui/icons/Add";
import RefreshIcon from "@material-ui/icons/Refresh";

function MenuComponent(props) {
  const listMenu = [
    {
      id: "1",
      name: "Добавить организацию",
      icon: AddIcon,
      callback: () => props.handleShowWindow(true),
    },
    {
      id: "2",
      name: "Обновить страницу",
      icon: RefreshIcon,
      callback: () => props.getOrganizations(props.pageIndex),
    },
  ];

  if (!props?.rights.includes("ORGANIZATIONS_CREATE")) listMenu.shift();

  return <Menu list={listMenu} className={props.className} />;
}

const mapStateToProps = (state) => ({
  pageIndex: state.orgPagination.page,
  refresh: state.organizations.refresh,
  rights: state.users.rights,
});

export default connect(mapStateToProps, { getOrganizations, handleShowWindow })(
  MenuComponent
);
