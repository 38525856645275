import React, { useEffect, useState } from "react";

import { isINN } from "../../../../utils/rus.tax.codes.util";

import { TextFieldControl } from "components/forms/fields";
import { ErrorMessage } from "@hookform/error-message";

import styles from "../styles.module.css";

function SupplierComponent({
  register,
  control,
  errors,
  className,
  title = true,
  defaultValue = null,
  isColumn = false,
}) {
  const [inn, setInn] = useState("");

  useEffect(() => {
    if (defaultValue) {
      setInn(defaultValue.vatin);
    }
  }, [defaultValue]);
  return (
    <div className={`${styles.form} ${className}`}>
      <div className="p-p-2">
        {title && (
          <span className={`${styles.blocktitle}`}>Данные поставщика</span>
        )}
        <div
          className={`p-col-12 p-p-0 p-d-flex p-flex-wrap p-flex-${
            isColumn ? "column" : "row"
          }`}
        >
          <div
            className={`p-col-12 p-md-${
              isColumn ? "12" : "4"
            } p-d-flex p-flex-column`}
          >
            <TextFieldControl
              name="supplierInfo.phones"
              label="Телефоны"
              control={control}
              errors={errors}
              keyfilter={/^[^a-zA-Zа-яА-Я<>*!]+$/}
              defaultValue={defaultValue ? defaultValue.phones : ""}
            />
          </div>
          <div
            className={`p-col-12 p-md-${
              isColumn ? "12" : "4"
            } p-d-flex p-flex-column`}
          >
            <label htmlFor="type-receipt" className={`${styles.font} p-pb-1`}>
              Наименование
            </label>
            <input
              ref={register()}
              name="supplierInfo.name"
              type="text"
              className={`${styles.borderBottom} ${styles.inputOpacity1} form-control p-inputtext p-component`}
              defaultValue={defaultValue ? defaultValue.name : ""}
            />
          </div>
          <div
            className={`p-col-12 p-md-${
              isColumn ? "12" : "4"
            } p-d-flex p-flex-column`}
          >
            <label htmlFor="type-receipt" className={`${styles.font} p-pb-1`}>
              ИНН
            </label>
            <input
              ref={register({
                validate: {
                  validate: (value) => {
                    if (!value.length) {
                      setInn(value);
                      return true;
                    } else {
                      setInn(value);
                    }

                    if (!isINN(value)) {
                      return "Введенный ИНН не прошел проверку";
                    }

                    return true;
                  },
                },
              })}
              name="supplierInfo.vatin"
              type="text"
              value={inn}
              onChange={(e) => {
                let number = e.target.value.replace(/[\D]+/g, "");
                if (number.length <= 12) setInn(number);
              }}
              className={`${styles.borderBottom} ${styles.inputOpacity1} form-control p-inputtext p-component`}
            />
            <ErrorMessage
              errors={errors}
              name="supplierInfo.vatin"
              as={<p className="errorText">message</p>}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SupplierComponent;
