import React from "react";

const style = {
  wordBreak: "break-all",
};

function LabelInfo(props) {
  return (
    <div className={`${props.className} p-col-12`}>
      <div className="block-label-title title-bold p-as-center">
        {!!props.label ? props.label : "-"}
      </div>

      <div className="p-as-center label-description" style={style}>
        {props.info}
      </div>
    </div>
  );
}

export default LabelInfo;
