import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  setProp,
  setPropEdit,
  setIsValidate,
} from "domain/actions/organizations/created";
import { TextField } from "components/forms/fields";
import { isKPP } from "utils/rus.tax.codes.util";

function Kpp(props) {
  const [value, setValue] = useState("");
  const [updateKey, forceUpdate] = useState(1);

  const { disabled = false } = props;

  const setProp = !props.select ? props.setProp : props.setPropEdit;

  useEffect(() => {
    if (props.select) return setValue(props.select.kpp);
    setValue(props.value);
  }, [props.value, props.select]);

  return (
    <TextField
      className={props.className}
      key={updateKey}
      label="КПП"
      value={value}
      onChange={(e) => {
        let number = e.replace(/[\D]+/g, "");
        if (number.length <= 9) setValue(number);
      }}
      onBlur={(e) => {
        setProp("kpp", e.target.value);
        forceUpdate(updateKey + 1);
      }}
      keyfilter="int"
      required={props.required}
      onValidate={props.setIsValidate}
      validate={{
        onValid: (val) => isKPP(val),
        message: "КПП не прошел проверку",
      }}
      disabled={disabled}
    />
  );
}

const mapStateToProps = (state) => ({
  value: state.orgCreate.item.kpp,
  required: state.orgCreate.required,
  select: state.orgCreate.select,
});
export default connect(mapStateToProps, {
  setProp,
  setIsValidate,
  setPropEdit,
})(Kpp);
