import React from "react";

import { Button } from "primereact/button";

function ReportBtn(props) {
  return (
    <div className={props.className}>
      <Button
        label="Печать отчетов"
        onClick={props.onClick}
        className="c-btns--gradient c-btns"
      />
    </div>
  );
}

export default ReportBtn;
