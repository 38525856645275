import React from "react";
import Title from "../titleBlock";

import Picture from "./components/Pictures";
import Info from "./components/Information";

import styles from "../../../../index.module.css";

function WhoAreWeTryingFor(state) {
  return (
    <div className={state.className}>
      <Title name={state.params.name} id={state.params.id} />

      <div className="p-d-flex p-col-12 p-pl-4">
        <Info className="p-col-12 p-p-0 p-md-5 p-as-center" />

        <div className="p-col-12 p-pt-0 p-pb-0 p-md-7 p-d-none p-d-md-block">
          <Picture className={styles.picture} />
        </div>
      </div>
    </div>
  );
}

export default WhoAreWeTryingFor;
