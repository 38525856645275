import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Layout from "./layouts";
import { NoAccess } from "./components";
import { getSetting } from "domain/actions/settings";

function Settings(props) {
  const [permission, setPermission] = useState(false);
  const { getSetting } = props;

  useEffect(() => {
    props.accountRoles.forEach((role) => {
      if (role === "ACCOUNT_OWNER") {
        setPermission(true);
        getSetting();
      }
    });
  }, [props.accountRoles, setPermission, getSetting]);

  return <>{permission ? <Layout history={props.history} /> : <NoAccess />}</>;
}

const mapStateToProps = (state) => ({
  accountRoles: state.account.data?.roles,
});
export default connect(mapStateToProps, { getSetting })(Settings);
