import React from "react";
import { CardOpenScreen } from "components/static";
import AgentData from "./agentTable";

function AgentComponent(props) {
  const agentHeader = (
    <span className="block-label-title p-pl-2 p-as-center">Данные агента</span>
  );
  const agentInfo = props.item.taskJson.parameters.agentInfo;
  const supplierInfo = props.item.taskJson.parameters.supplierInfo;

  return (
    <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap">
      <CardOpenScreen label={agentHeader} className="p-col-12 p-p-0 p-mb-2">
        <AgentData
          agentInfo={agentInfo}
          supplierInfo={supplierInfo}
          className="p-d-flex p-flex-wrap p-p-3 p-mb-3"
        />
      </CardOpenScreen>
    </div>
  );
}

export default AgentComponent;
