import React from "react";
import { CardOpenScreen, LabelInfo } from "components/static";
import AgentComponent from "../../../agent/agentTable";
import Header from "../header";

function ProductComponent(props) {
  const isMark = !!props.item.markingCode;
  const agentInfo = props.item.agentInfo;
  const supplierInfo = props.item.supplierInfo;

  return (
    <CardOpenScreen
      label={<Header item={props.item} />}
      className="p-col-12 p-p-0 p-mb-2"
    >
      <div className="p-col-12 p-pl-2 p-pr-6 p-pt-0">
        <span className="block-label-title p-pl-2">Дополнительные данные</span>

        {!isMark && !agentInfo && (
          <span className="p-col-12 p-p-text-center p-d-block"> - </span>
        )}

        {isMark && (
          <LabelInfo
            label="Код маркировки"
            info={props.item.markingCode.mark}
            className="p-pt-1 p-pb-1"
          />
        )}

        {agentInfo && (
          <AgentComponent
            agentInfo={agentInfo}
            supplierInfo={supplierInfo}
            className="p-d-flex p-flex-wrap"
          />
        )}
      </div>
    </CardOpenScreen>
  );
}

export default ProductComponent;
