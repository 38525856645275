import React from "react";
import Header from "./header";
import Body from "./body";

import { ScrollPanel } from "primereact/scrollpanel";

function ClientCashbox(props) {
  return (
    <div className={props.className}>
      <span className="block-label-title p-pl-2 p-mb-2">
        На стороне клиента
      </span>
      <Header className="p-col-12 p-pt-1 p-pb-1 p-d-none p-d-md-flex " />
      <Body
        list={props.item.cashboxes}
        className="p-col-12 p-pt-1 p-pb-1  p-d-block p-d-md-none"
      />

      <ScrollPanel
        style={{ width: "100%", maxHeight: "320px" }}
        className="c-scrollPanel scrollDataView p-d-none p-d-md-block"
      >
        <Body list={props.item.cashboxes} className="p-col-12 p-pt-1 p-pb-1 " />
      </ScrollPanel>
    </div>
  );
}

export default ClientCashbox;
