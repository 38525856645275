import React from "react";

function SettingUpAndMonitoringActivities({ className }) {
  return (
    <div className={className}>
      <div>
        <strong>Настройка и контроль деятельности</strong>
      </div>

      <p>точек продажи, сотрудников</p>
    </div>
  );
}

export default SettingUpAndMonitoringActivities;
