import React from "react";
import { connect } from "react-redux";
import { onShowFiltersReceipts } from "domain/actions/receipt/filters";

import { Heading } from "components/static";

import Settings from "./components/settingsPanel";
import DataView from "./components/dataView";
import Menu from "./components/menu";
import { FiltersIcon } from "components/static";
import { ReceiptWindow, PayLinkWindow, LinkReceiptWindow } from "../windows";

function Layouts(props) {
  return (
    <div>
      <div className="p-d-flex p-flex-wrap p-col-12 p-p-0">
        <div className="p-col-12 p-md-2 p-d-flex p-flex-wrap">
          <div className="p-col-6 p-md-12 p-p-0 ">
            <Heading className="p-col-12 p-pl-3 p-pt-3" title="Чеки" />
            <Menu
              history={props.history}
              className="p-col-12 p-pl-3 p-pt-0 p-pb-0 p-pr-0"
            />
          </div>

          <div className="p-col-6 p-d-md-none p-d-flex p-jc-end">
            <FiltersIcon
              setActive={props.onShowFiltersReceipts}
              active={props.showFilters}
            />
          </div>
        </div>

        <div className="p-col-12 p-md-10">
          <Settings className="p-p-1 p-p-md-3 p-d-flex p-flex-wrap" />
        </div>
      </div>

      <DataView history={props.history} />

      <ReceiptWindow />
      <PayLinkWindow />
      <LinkReceiptWindow />
    </div>
  );
}

const mapStateToProps = (state) => ({
  showFilters: state.receiptsFilter.show,
});
export default connect(mapStateToProps, { onShowFiltersReceipts })(Layouts);
