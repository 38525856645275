import React from "react";
import Layouts from "./layouts";
// import RightsPage from "components/rightsControl/RightsPage";

function CreateReceipt(props) {
  return (
    // <RightsPage right="CHECK_CREATE">
    <Layouts history={props.history} />
    // </RightsPage>
  );
}

export default CreateReceipt;
