import React from "react";
import { connect } from "react-redux";
import { setShowWindowBilling } from "domain/actions/createReceipt.actions";

import Window from "components/windows";

function BillingWindow(props) {
  const { show, error, setShowWindowBilling } = props;
  const host =
    window.location.host === "gate.stage.vdpaybox.ru"
      ? "stage.vdpaybox.ru"
      : "vdpaybox.ru";
  const protocol = window.location.protocol;

  return (
    <Window
      header="Предупреждение!"
      visible={show}
      modal
      style={{ width: "350px" }}
      onHide={() => setShowWindowBilling(false)}
    >
      <div className="confirmation-content">
        <div className="p-d-flex">
          <i
            className="pi pi-exclamation-triangle p-mr-3"
            style={{ fontSize: "2rem" }}
          />
          <span className="p-d-block">{error}</span>
        </div>

        <p>Вы можете перейти в сервис Биллинга и оплатить активацию лицензии</p>

        <a
          href={`${protocol}//billing.${host}/api/auth/login`}
          target="_blank"
          rel="noreferrer"
          className="text-color-success line-height-14"
        >
          {" "}
          Перейти в Биллинг
        </a>
      </div>
    </Window>
  );
}

const mapStateToProps = (state) => ({
  show: state.createReceipt.windows.billing,
  error: state.createReceipt.error,
});
export default connect(mapStateToProps, { setShowWindowBilling })(
  BillingWindow
);
