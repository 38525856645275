import { SET_RESPONSE_FEEDBACK, ERROR_FEEDBACK } from "../actionTypes";

const initialState = {
  response: null,
  isError: false,
};

export default function forms(state = initialState, action) {
  switch (action.type) {
    case SET_RESPONSE_FEEDBACK:
      return {
        response: true,
        isError: false,
      };
    case ERROR_FEEDBACK:
      return {
        response: null,
        isError: true,
      };
    default:
      return state;
  }
}
