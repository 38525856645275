import React from "react";
import { connect } from "react-redux";
import { selectBid } from "domain/actions/equipment/bid";
import { Card } from "./components";
import { EmptyData } from "components/static";

function Body(props) {
  const onEdit = (item) => props.selectBid(item);

  const renderedListItems = props.bids.map((i, index) => {
    return <Card key={index} item={i} onPreview={() => onEdit(i)} />;
  });

  return (
    <div className={props.className}>
      {renderedListItems.length ? renderedListItems : <EmptyData />}
    </div>
  );
}

const mapStateToProps = (state) => ({
  bids: state.bidList.equipments,
});
export default connect(mapStateToProps, { selectBid })(Body);
