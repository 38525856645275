import React from "react";
import { TextFieldControl } from "components/forms/fields";

function NameField(props) {
  return (
    <TextFieldControl
      className="p-p-2"
      name="name"
      control={props.formHook.control}
      errors={props.formHook.errors}
      label="Ваше имя:"
    />
  );
}

export default NameField;
